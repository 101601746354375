import clsx from 'clsx';
import React from 'react';
import Search from './Search';
import Account from './Account';
import PropTypes from 'prop-types';
import Languages from './Languages';
import { Icon } from '@iconify/react';
import Notifications from './Notifications';
import menu2Fill from '@iconify-icons/eva/menu-2-fill';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Box, AppBar, Hidden, Toolbar, IconButton } from '@material-ui/core';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { useHistory } from 'react-router-dom';
import { PATH_APP } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import { isAuthorized, resources, actions } from 'src/utils/isAuthorized';
import { MopedRounded, TvRounded } from '@material-ui/icons';
import DriversOnlineCouriers from 'src/views/driversOnlineCouriers';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backdropFilter: 'blur(8px)',
    backgroundColor: '#01645C'
    // backgroundColor: alpha(theme.palette.background.default, 0.72)
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: DRAWER_WIDTH
    // }
  },
  toolbar: {
    minHeight: APPBAR_MOBILE
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(0, 5)
    // },
    // [theme.breakpoints.up('lg')]: {
    //   minHeight: APPBAR_DESKTOP
    // }
  },
  rotateIcon: {
    '&:hover': {
      animation: `$myEffectExit 1000ms`,
      animationIterationCount: '2'
    },
    mr: 1,
    color: '#FCED11'
  },
  '@keyframes myEffectExit': {
    '0%': {
      transform: 'rotateZ(-30deg)'
    },
    '10%': {
      transform: 'rotateZ(30deg)'
    },
    '20%': {
      transform: 'rotateZ(-30deg)'
    },
    '30%': {
      transform: 'rotateZ(30deg)'
    },
    '40%': {
      transform: 'scale(1)'
    },
    '50%': {
      transform: 'scale(1.5)'
    },
    '60%': {
      transform: 'scale(1)'
    },
    '70%': {
      transform: 'scale(1)'
    },
    '80%': {
      transform: 'scale(1.5)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

TopBar.propTypes = {
  onOpenNav: PropTypes.func,
  className: PropTypes.string
};

function TopBar({ onOpenNav, className }) {
  const classes = useStyles();
  const history = useHistory();
  const { permissionList } = useAuth();

  const [openOnlineCouriers, setOpenOnlineCouriers] = React.useState(false);

  const navigatToOnlineDrivers = () => {
    history.push({
      pathname: `${PATH_APP.onlineDrivers.root}`
    });
  };

  const navigatToDriversTv = () => {
    history.push({
      pathname: `${PATH_APP.driversTv.root}`
    });
  };

  const handleOpenOnlineCouriers = () => {
    setOpenOnlineCouriers(true);
  };

  const handleCloseOnlineCouriers = () => {
    setOpenOnlineCouriers(false);
  };

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        {/* <Hidden lgUp> */}
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: '#FCED11'
          }}
        >
          <Icon icon={menu2Fill} />
        </IconButton>
        {/* </Hidden> */}

        {/* <Search /> */}
        {isAuthorized(permissionList, resources.drivers, actions.view) && (
          <IconButton
            className={classes.rotateIcon}
            onClick={navigatToOnlineDrivers}
          >
            <DirectionsCarIcon />
          </IconButton>
        )}

        {isAuthorized(permissionList, resources.driversTv, actions.view) && (
          <IconButton onClick={navigatToDriversTv}>
            <TvRounded color="secondary" />
          </IconButton>
        )}

        {isAuthorized(permissionList, resources.drivers, actions.view) && (
          <IconButton onClick={handleOpenOnlineCouriers}>
            <MopedRounded color="secondary" />
          </IconButton>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: {
                xs: 0.5,
                sm: 2,
                lg: 3
              }
            }
          }}
        >
          {/* <Languages /> */}
          {/* <Notifications /> */}
          <Account />
        </Box>
      </Toolbar>

      <DriversOnlineCouriers
        open={openOnlineCouriers}
        onClose={handleCloseOnlineCouriers}
      />
    </AppBar>
  );
}

export default TopBar;
