import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { Events, eventsManager } from 'src/utils/eventManager';

const initialState = {
  isLoading: false,
  loadingTracking: false,
  loadingOrderLogs: false,
  sendRemindersLoading: false,
  error: false,
  orderLogs: [],
  ordersList: [],
  trackingLocations: [],
  count: 0,
  pagesCount: 0,
  order: {},
  orderChat: {},
  isOrderDetailsLoading: false,
  isDriverOrdersLoading: false,
  isUpdateDriverLoading: false,
  driverOrders: [],
  driverOrdersCount: 0,
  driverOrdersError: false,
  isCustomerOrdersLoading: false,
  customerOrders: [],
  customerOrdersCount: 0,
  customerOrdersError: false,
  orderSuccessMessage: '',
  isAssignDriverLoading: false,
  isDeleteMessageLoading: false
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setLoadingTracking(state, action) {
      state.loadingTracking = action.payload;
    },
    setLoadingLogs(state, action) {
      state.loadingOrderLogs = action.payload;
    },
    setSendingReminders(state, action) {
      state.sendRemindersLoading = action.payload;
    },

    startOrderDetailsLoading(state) {
      state.isOrderDetailsLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.loadingOrderLogs = false;
      state.error = action.payload;
    },

    getOrdersListSuccess(state, action) {
      state.isLoading = false;
      state.ordersList = action.payload.orders;
      state.count = action.payload.count;
      state.pagesCount = action.payload.pages;
      state.meta = action.payload.meta;
    },

    getOrdersDetailsSuccess(state, action) {
      state.isOrderDetailsLoading = false;
      state.order = action.payload;
    },
    getOrderLogsSuccess(state, action) {
      state.orderLogs = action.payload;
    },
    getOrdersChatsSuccess(state, action) {
      // state.isLoading = false;
      state.orderChat = action.payload;
    },
    startDriverOrdersLoading(state) {
      state.isDriverOrdersLoading = true;
      state.driverOrders = [];
    },

    driverOrdersSuccess(state, action) {
      state.isDriverOrdersLoading = false;
      state.driverOrders = action.payload.orders;
      state.driverOrdersCount = action.payload.count;
    },

    driverOrdersHasError(state, action) {
      state.isDriverOrdersLoading = false;
      state.driverOrdersError = action.payload;
    },
    startCustomerOrdersLoading(state) {
      state.isCustomerOrdersLoading = true;
      state.customerOrders = [];
    },

    customerOrdersSuccess(state, action) {
      state.isCustomerOrdersLoading = false;
      state.customerOrders = action.payload.orders;
      state.customerOrdersCount = action.payload.count;
    },

    customerOrdersHasError(state, action) {
      state.isCustomerOrdersLoading = false;
      state.customerOrdersError = action.payload;
    },
    orderSuccess(state, action) {
      state.orderSuccessMessage = action.payload.message;
    },
    setTrackingLocations(state, action) {
      state.trackingLocations = action.payload.locations;
    },
    setUpdateDriverLoading(state, action) {
      state.isUpdateDriverLoading = action.payload;
    },
    updateDriverSuccess(state, action) {
      state.isUpdateDriverLoading = action.payload;
    },
    setAssignDriverLoading(state, action) {
      state.isAssignDriverLoading = action.payload;
    },
    assignDriverSuccess(state, action) {
      state.isAssignDriverLoading = false;
    },
    startDeleteMessageLoading(state) {
      state.isDeleteMessageLoading = true;
    },
    stopDeleteMessageLoading(state) {
      state.isDeleteMessageLoading = false;
    }
  }
});

export default slice.reducer;

export function getOrdersList(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post('/orders', body);
      dispatch(slice.actions.getOrdersListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendScheduledReminder() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setSendingReminders(true));
      await apiInstance.get('/orders/send-scheduled-reminders', {
        toastMessage: 'Reminders sent successfully'
      });
      dispatch(slice.actions.setSendingReminders(false));
    } catch (error) {
      dispatch(slice.actions.setSendingReminders(false));
    }
  };
}

export function getUnhandledOrdersList(params) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get('/orders/unhandled-orders', {
        params
      });
      dispatch(slice.actions.getOrdersListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderDetails(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startOrderDetailsLoading());

      const response = await apiInstance.get(`/orders/${id}`);

      setTimeout(() => {
        dispatch(
          slice.actions.getOrdersDetailsSuccess(response.data.data.order)
        );
      }, 1000);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderLogs(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setLoadingLogs(true));
      const response = await apiInstance.get(`/activity/order/logs/${id}`, {
        params: { perPage: 1000 }
      });
      dispatch(slice.actions.getOrderLogsSuccess(response.data.data.logs));
      dispatch(slice.actions.setLoadingLogs(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderChat(id) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/orders/chat/${id}`);
      dispatch(slice.actions.getOrdersChatsSuccess(response.data.data.chat));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDriverOrders(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startDriverOrdersLoading());
    try {
      const response = await apiInstance.post(`/orders/driver`, body);
      dispatch(slice.actions.driverOrdersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.driverOrdersHasError(error));
    }
  };
}

export function getCustomerOrders(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startCustomerOrdersLoading());
    try {
      const response = await apiInstance.post(`/orders/customer`, body);
      dispatch(slice.actions.customerOrdersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.customerOrdersHasError(error));
    }
  };
}

export function editOrder(id, body, options) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/orders/${id}`, body);
      dispatch(slice.actions.orderSuccess(response.data.data));
      if (options?.onSuccess) {
        options.onSuccess();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (options?.onFail) {
        options.onFail(error);
      }
    }
  };
}

/**
 *
 * @param {string} id
 * @param {{ status: string; cancelReasonId?: string; other?: string; }} payload
 * @returns
 */
export function updateOrderStatus(id, payload, options) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post(`/orders/${id}/status`, payload, {
        toastMessage: 'Order status is updated successfully'
      });
      dispatch(slice.actions.orderSuccess(response.data));
      eventsManager.emit(Events.Orders.OrderStatusUpdated);
      if (options?.onSuccess) {
        options.onSuccess();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (options?.onFail) {
        options.onFail(error);
      }
    }
  };
}

export function getOrderTracking(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setLoadingTracking(true));
      const response = await apiInstance.get(`/orders/${id}/track`);
      dispatch(slice.actions.setTrackingLocations(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setLoadingTracking(false));
    }
  };
}

export function cleanupOrderTracking() {
  return async (dispatch) => {
    dispatch(slice.actions.setTrackingLocations({ locations: [] }));
  };
}

/**
 *
 * @param {string} id
 * @param {{ dropOff: { coordinates: [number,number]; type: "Point", googleAddress: { en: string; ar: string; } }; }} body
 * @param {{ onSuccess: () => void; onFail: () => void; }} options
 * @returns
 */
export function updateOrderDeliveryAddress(id, body, options) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/orders/${id}`, body);
      dispatch(slice.actions.orderSuccess({ message: response.data.message }));
      if (options?.onSuccess) {
        options.onSuccess();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (options?.onFail) {
        options.onFail(error);
      }
    }
  };
}

/**
 *
 * @param {string} id
 * @param {{ driverId: string; dropOff: any; }} payload
 * @param {{ onSuccess: () => void; }} options
 * @returns
 */
export function updateOrderDriver(id, payload, options) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setUpdateDriverLoading(true));
      const response = await apiInstance.put(`/orders/${id}`, payload, {
        toastMessage: 'Order driver is updated successfully'
      });
      dispatch(slice.actions.updateDriverSuccess(response.data.message));
      eventsManager.emit(Events.Orders.OrderDriverUpdated);
      if (options?.onSuccess) {
        options.onSuccess();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (options?.onFail) {
        options.onFail(error);
      }
    } finally {
      dispatch(slice.actions.setUpdateDriverLoading(false));
    }
  };
}

/**
 *
 * @param {string} id
 * @param {{ driverId: string; price: number; }} payload
 * @param {{ onSuccess: () => void;, onFail: () => void; }} options
 * @returns
 */
export function assignDriverToOrder(id, payload, options) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setAssignDriverLoading(true));
      const response = await apiInstance.post(
        `/orders/${id}/assign-driver`,
        payload,
        {
          toastMessage: 'Driver is assigned successfully'
        }
      );
      dispatch(slice.actions.assignDriverSuccess(response.data.data));
      eventsManager.emit(Events.Orders.OrderDriverUpdated);
      if (options?.onSuccess) {
        options.onSuccess();
      }
      dispatch(slice.actions.setAssignDriverLoading(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (options?.onFail) {
        options.onFail(error);
      }
    }
  };
}

/**
 *
 * @param {string} driverId
 * @param {string[]} orderIds
 * @returns
 */
export function assignManyOrdersToDriver(driverId, orderIds, options) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setAssignDriverLoading(true));
      const response = await apiInstance.post(
        `/drivers/${driverId}/assign`,
        { orderIds },
        {
          toastMessage: 'Driver is assigned successfully'
        }
      );
      if (options?.onSuccess) {
        options.onSuccess();
      }
      dispatch(slice.actions.assignDriverSuccess(response.data.data));
      dispatch(slice.actions.setAssignDriverLoading(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 *
 * @param {{_id:string}} message
 * @param {{order:string}} chat
 * @returns
 */
export function deleteMessage(message, chat) {
  return async (dispatch) => {
    dispatch(slice.actions.startDeleteMessageLoading());

    try {
      await apiInstance.delete(
        `orders/chat/${chat.order}/message/${message._id}`,
        {
          toastMessage: 'Message is deleted successfully'
        }
      );
      const newChat = {
        ...chat,
        messages: chat.messages.filter((m) => m._id !== message._id)
      };
      dispatch(slice.actions.getOrdersChatsSuccess(newChat));
    } catch (error) {}
    dispatch(slice.actions.stopDeleteMessageLoading());
  };
}

/**
 *
 * @param {{_id:string}} invoice
 * @param {{_id:string}} orderDetails
 * @returns
 */
export function deleteInvoice(invoice, order, onSuccess) {
  return async (dispatch) => {
    try {
      await apiInstance.delete(`orders/${order._id}/invoices/${invoice._id}`, {
        toastMessage: 'Invoice is deleted successfully'
      });
      onSuccess();
      return true;
    } catch (error) {
      return false;
    }
  };
}
