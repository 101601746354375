import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { store } from '../store';
import { paginationUtils } from './utils/pagination';

const initialState = {
  isLoading: false,
  isLoadingOrders: false,

  driver: null,
  drivers: [],
  driverOrders: [],

  ordersPage: 0,
  ordersPerPage: 10,
  ordersCount: 0,

  ...paginationUtils.initialState
};

const slice = createSlice({
  name: 'driverSalary',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    setLoadingOrders(state, action) {
      state.isLoadingOrders = action.payload;
      state.driverOrders = [];
    },

    getDriverSalarySuccess(state, action) {
      state.drivers = action.payload.drivers;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    getDriverSalaryOrdersSuccess(state, action) {
      state.driverOrders = action.payload.drivers;
      state.ordersCount = action.payload.count;
      state.isLoadingOrders = false;
    },

    setSelectedDriver(state, action) {
      state.driver = action.payload;
    },

    setOrdersPage(state, action) {
      state.ordersPage = action.payload;
    },

    setOrdersPerPage(state, action) {
      state.ordersPerPage = action.payload;
    },

    ...paginationUtils.reducers
  }
});

export default slice.reducer;

export function getDriverSalaryList(filters) {
  return async (dispatch) => {
    try {
      const state = store.getState().driverSalary;
      const { page, perPage } = state;

      dispatch(slice.actions.setLoading(true));
      const response = await apiInstance.post(
        `/drivers-salary/${page}/${perPage}`,
        filters
      );
      dispatch(slice.actions.getDriverSalarySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function getDriverSalaryOrdersList() {
  return async (dispatch) => {
    try {
      const state = store.getState().driverSalary;
      const { ordersPage, ordersPerPage, driver } = state;

      dispatch(slice.actions.setLoadingOrders(true));
      const response = await apiInstance.get(
        `/drivers-salary/${driver._id}/orders/${ordersPage}/${ordersPerPage}`
      );
      dispatch(slice.actions.getDriverSalaryOrdersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.setLoadingOrders(false));
    }
  };
}

export function setSelectedDriver(driver) {
  return (dispatch) => {
    dispatch(slice.actions.setSelectedDriver(driver));
    if (driver == null) {
      return;
    }

    dispatch(getDriverSalaryOrdersList());
  };
}

export function setDriverSalaryPage(page, filters) {
  return (dispatch) => {
    dispatch(slice.actions.setPage(page - 1));
    dispatch(getDriverSalaryList(filters));
  };
}
export function setDriverSalaryPerPage(perPage, filters) {
  return (dispatch) => {
    dispatch(slice.actions.setPerPage(perPage));
    dispatch(getDriverSalaryList(filters));
  };
}
export function setDriverOrdersPage(page) {
  return (dispatch) => {
    dispatch(slice.actions.setOrdersPage(page - 1));
    dispatch(getDriverSalaryOrdersList());
  };
}
export function setDriverOrderPerPage(perPage) {
  return (dispatch) => {
    dispatch(slice.actions.setOrdersPerPage(perPage));
    dispatch(getDriverSalaryOrdersList());
  };
}
