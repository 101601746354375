import React from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Lock } from '@material-ui/icons';
import { Actions } from 'src/constants';

const lockedStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: 'blur(2px)',
  scale: '1.1px'
};
const lockIconStyle = {
  color: 'silver'
};
const lockedTooltip = "You don't have permission to do this action";

function canUser(action, page, permissionList) {
  const permission = permissionList[page] || [];
  return permission.includes(Actions.All) || permission.includes(action);
}

export function usePermissionGuard() {
  const permissionList = useSelector((state) => state.auth.permissionList);

  return {
    canUser: (page, action) => canUser(action, page, permissionList),
    canView: (page) => canUser(Actions.View, page, permissionList)
  };
}

export function PermissionGuard({ page, action, allowIf, children }) {
  const permissionList = useSelector((state) => state.auth.permissionList);

  if (
    (typeof allowIf === 'boolean' && allowIf === true) ||
    canUser(action, page, permissionList)
  ) {
    return children;
  }

  const nchildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        children: [
          child.props.children,
          <Box style={lockedStyle}>
            <Tooltip title={lockedTooltip}>
              <IconButton>
                <Lock sx={lockIconStyle} />
              </IconButton>
            </Tooltip>
          </Box>
        ],
        sx: {
          ...child.props.sx
        },
        onClick: () => {}
      });
    }
  });

  return nchildren;
}
