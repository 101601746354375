import { useMemo } from 'react';
import {
  Box,
  InputAdornment,
  makeStyles,
  OutlinedInput
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import searchFill from '@iconify-icons/eva/search-fill';
import _ from 'lodash';

const useStyle = makeStyles((theme) => {
  return {
    search: {
      width: '100%',
      // transition: theme.transitions.create(['box-shadow', 'width'], {
      //   easing: theme.transitions.easing.easeInOut,
      //   duration: theme.transitions.duration.shorter
      // }),
      // '&.Mui-focused': { boxShadow: theme.shadows[25].z8 }, //width: 300
      '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
      }
    }
  };
});

export function SearchTextFilter({ onChange, inputRef }) {
  const classes = useStyle();
  const debouncedCallback = useMemo(() => {
    return _.debounce(onChange, 500);
  }, []);

  return (
    <OutlinedInput
      inputRef={(ref) => {
        if (inputRef) {
          inputRef.current = ref;
        }
      }}
      onChange={(e) => debouncedCallback(e.target.value)}
      placeholder="Search ..."
      startAdornment={
        <InputAdornment position="start">
          <Box
            component={Icon}
            icon={searchFill}
            sx={{ color: 'text.disabled' }}
          />
        </InputAdornment>
      }
      className={classes.search}
    />
  );
}
