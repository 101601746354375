import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  categoriesList: [],
  count: 0,
  categorySuccessMessage: '',
  categoriesListAutoComplete: [],
  isCategoriesAutoCompleteLoading: false
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startCategoriesAutoCompleteLoading(state) {
      state.isCategoriesAutoCompleteLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCategoriesSuccess(state, action) {
      state.categoriesList = action.payload.categoriesList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    categorySuccess(state, action) {
      state.categorySuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
      // state.isLoading = false;
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status !== undefined) {
        state.categoriesList = state.categoriesList.map((t) => {
          if (t._id === action.payload?.id) {
            t.active = action.payload?.status;
          }
          return t;
        });
      }
    },
    startDeleteLoading(state, action) {
      state.categoriesList = state.categoriesList.filter(
        (t) => t._id !== action.payload
      );
      state.count -= 1;
    },
    getCategoriesAutoCompleteSuccess(state, action) {
      state.categoriesListAutoComplete = action.payload.categories;
      state.isCategoriesAutoCompleteLoading = false;
    },
    resetCategoriesAutoComplete(state) {
      state.categoriesListAutoComplete = [];
    }
  }
});

export default slice.reducer;

export function getCategoriesList(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/categories/${pageNumber}/${pageSize}`
      );
      console.log(
        '[[[Categories List]]] - Fetch API Response:',
        response.data.data
      );
      dispatch(slice.actions.getCategoriesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addCategory(body) {
  return async (dispatch) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.post('/categories', body, {
        toastMessage: 'Category is added successfully'
      });
      dispatch(slice.actions.categorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editCategory(id, body) {
  return async (dispatch) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.put(`/categories/${id}`, body, {
        toastMessage: 'Category is updated successfuly'
      });
      dispatch(slice.actions.categorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editCategoryStatus(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(
        `/categories/${id}`,
        {
          active: status
        },
        {
          toastMessage: 'Category is updated successfuly'
        }
      );
      dispatch(slice.actions.categorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteCategory(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/categories/${id}`, {
        toastMessage: 'Category is deleted successfuly'
      });
      dispatch(slice.actions.categorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategoriesListAutoComplete(filter = { name: '' }) {
  return async (dispatch) => {
    try {
      dispatch(resetCategoriesAutoComplete());
      dispatch(slice.actions.startCategoriesAutoCompleteLoading());
      const response = await apiInstance.post(
        `/categories/autoComplete`,
        filter
      );
      console.log(
        '[[[Catgeories APIs]]] - Fetch categories autocomplete list:',
        response.data.data
      );
      dispatch(
        slice.actions.getCategoriesAutoCompleteSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetCategoriesAutoComplete() {
  return async (dispatch) => {
    dispatch(slice.actions.resetCategoriesAutoComplete());
  };
}
