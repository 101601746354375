import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  count: 0,
  offersLimitsList: [],
  offerLimitSuccessMessage: ''
};

const slice = createSlice({
  name: 'offerLimit',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getoffersLimitsSuccess(state, action) {
      state.offersLimitsList = action.payload.offersLimits;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    offerLimitSuccess(state, action) {
      state.offerLimitSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
    },

    startEditStatusLoading(state, action) {
      state.offersLimitsList = state.offersLimitsList.map((t) => {
        if (t._id === action.payload?.id) {
          t.active = action.payload?.body?.active;
        }
        return t;
      });
    },

    startDeleteLoading(state, action) {
      state.offersLimitsList = state.offersLimitsList.filter(
        (t) => t._id !== action.payload
      );
      state.count -= 1;
    }
  }
});

export default slice.reducer;

export function getOffersLimitsList(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/offersLimits/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getoffersLimitsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addOffersLimit(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post('/offersLimits', body, {
        toastMessage: 'Offer limit is added successfully'
      });
      dispatch(slice.actions.offerLimitSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editOffersLimit(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, body }));
      const response = await apiInstance.put(`/offersLimits/${id}`, body, {
        toastMessage: 'Offer limit is updated successfully'
      });
      dispatch(slice.actions.offerLimitSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteOffersLimit(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/offersLimits/${id}`);
      dispatch(slice.actions.offerLimitSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
