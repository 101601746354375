const { toast } = require('react-toastify');

export class ToastifyManager {
  static containerRefs = {};

  /**
   *
   * @param {string} id
   * @param {import("react-toastify").ToastContent} content
   * @param {import("react-toastify").ToastOptions} options
   */
  static toast(id, content, options) {
    this.containerRefs[id] = toast(content, options);
  }

  static dismiss(id) {
    if (this.containerRefs[id] != null) {
      toast.dismiss(this.containerRefs[id]);
      delete this.containerRefs[id];
    }
  }
}
