import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  loading: {
    vendor: false,
    vendorsByBrand: false
  },
  isFormLoading: false,
  error: false,
  count: 0,
  vendorsList: [],
  vendorsByBrandList: [],
  vendorSuccessMessage: '',
  vendor: {},
  vendorsListAutoComplete: []
};

const slice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    startLoading(state, { payload }) {
      let loader = 'isLoading';

      if (payload?.loader) {
        loader = `loading.${payload.loader}`;
      }

      set(state, loader, true);
    },
    setFormLoading(state, action) {
      state.isFormLoading = action.payload;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.loading.vendor = false;
      state.loading.vendorsByBrand = false;
      state.error = action.payload;
    },

    getVendorsSuccess(state, action) {
      state.vendorsList = action.payload.vendors;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    getVendorsByBrandSuccess(state, action) {
      state.vendorsByBrandList = action.payload.vendors;
      state.count = action.payload.count;
      state.loading.vendorsByBrand = false;
    },

    vendorSuccess(state, action) {
      state.vendorSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
      state.loading.vendor = false;
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status !== undefined) {
        state.vendorsList = state.vendorsList.map((t) => {
          if (t.user?._id === action.payload?.id) {
            t.user.status = action.payload?.status;
          }
          return t;
        });
      }
    },

    startEditLoading(state, action) {
      if (action.payload?.status !== undefined) {
        if (action.payload?.type === 'active') {
          state.vendorsByBrandList = state.vendorsByBrandList.map((t) => {
            if (t._id === action.payload?.id) {
              t.active = action.payload?.status;
            }
            return t;
          });
        }

        if (action.payload?.type === 'isCallCenter') {
          state.vendorsByBrandList = state.vendorsByBrandList.map((t) => {
            if (t._id === action.payload?.id) {
              t.isCallCenter = action.payload?.status;
            }
            return t;
          });
        }
      }
    },

    getVendorSuccess(state, action) {
      state.vendor = action.payload.vendor;
      state.isLoading = false;
    },
    getVendorsAutoCompleteSuccess(state, action) {
      state.vendorsListAutoComplete = action.payload.vendors;
      state.isLoading = false;
    },
    startDeleteLoading(state, action) {
      state.vendorsList = state.vendorsList.filter(
        (t) => t._id !== action.payload
      );
      state.count -= 1;
    },
    resetVendorsAutoComplete(state) {
      state.vendorsListAutoComplete = [];
    }
  }
});

export default slice.reducer;

export function getvendorsList(pageNumber, pageSize, searchText) {
  return async (dispatch) => {
    try {
      const params = {};
      if (searchText) {
        params.searchText = searchText;
      }
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/vendors/${pageNumber}/${pageSize}`,
        { params }
      );
      dispatch(slice.actions.getVendorsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//[todo] get vendors by brandId
export function getVendorsByBrandId(brandId, pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading({ loader: 'vendorsByBrand' }));
      const response = await apiInstance.get(
        `/vendors/${brandId}/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getVendorsByBrandSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addVendor(body, events) {
  return async (dispatch) => {
    try {
      console.log('[[[Vendors Store]]] - new user payload:', body);
      dispatch(slice.actions.setFormLoading(true));
      await apiInstance.post(`/vendors`, body, {
        toastMessage: 'Vendor is added successfully'
      });
      if (events?.onSuccess) {
        events.onSuccess();
      }
      // dispatch(slice.actions.vendorSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError);
      if (events?.onFail) {
        events.onFail(error);
      }
    } finally {
      dispatch(slice.actions.setFormLoading(false));
    }
  };
}

export function updateVendor(id, body, events) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setFormLoading(true));
      console.log('[[[Vendors Store]]] - update user payload:', id, body);
      await apiInstance.put(`/vendors/${id}`, body, {
        toastMessage: 'Vendor is updated successfully'
      });
      if (events?.onSuccess) {
        console.log('[[[Vendors Store]]] - update, check evets');
        events.onSuccess();
      }
      // dispatch(slice.actions.vendorSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (events?.onFail) {
        events.onFail(error);
      }
    } finally {
      dispatch(slice.actions.setFormLoading(false));
    }
  };
}

export function blockUnBlockVendor(id, status, blockReason) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(`/users/status/${id}`, {
        status,
        blockReason
      });
      dispatch(slice.actions.vendorSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVendorById(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading({ loader: 'vendor' }));
      console.log('[[[Vendor Store]]] - get vendor by id:', id);
      const response = await apiInstance.get(`/vendors/${id}`);
      dispatch(slice.actions.getVendorSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteVendor(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/vendors/${id}`, {
        toastMessage: 'Vendor deleted successfully'
      });
      dispatch(slice.actions.vendorSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVendorsListAutoComplete(filter = { name: '' }) {
  return async (dispatch) => {
    try {
      dispatch(resetVendorsAutoComplete());
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(`/vendors/autoComplete`, filter);
      console.log(
        '[[[Vendors APIs]]] - Fetch vendors list:',
        response.data.data
      );
      dispatch(slice.actions.getVendorsAutoCompleteSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetVendorsAutoComplete() {
  return async (dispatch) => {
    dispatch(slice.actions.resetVendorsAutoComplete());
  };
}

export function editVendorStatus(id, status, type, options) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditLoading({ id, status, type }));
      let response;
      type === 'active'
        ? (response = await apiInstance.put(`/vendors/${id}`, {
            active: status
          }))
        : (response = await apiInstance.put(`/vendors/${id}`, {
            isCallCenter: status
          }));
      console.log(
        '[[[Vendors Tab]]] - edit status result:',
        response.data.data
      );
      options?.onSuccess?.();
      dispatch(slice.actions.vendorSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
