import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  itemsCategoriesList: [],
  count: 0,
  itemCategorySuccessMessage: ''
};

const slice = createSlice({
  name: 'itemCategory',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getItemCategoriesSuccess(state, action) {
      state.itemsCategoriesList = action.payload.itemsCategoriesList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    itemCategorySuccess(state, action) {
      state.itemCategorySuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
      // state.isAddLoading = false;
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status != undefined) {
        state.itemsCategoriesList = state.itemsCategoriesList.map((t) => {
          if (t._id == action.payload?.id) {
            t.active = action.payload?.status;
          }
          return t;
        });
      }
    },
    startDeleteLoading(state, action) {
      state.itemsCategoriesList = state.itemsCategoriesList.filter(
        (t) => t._id != action.payload
      );
      state.count -= 1;
    }
  }
});

export default slice.reducer;

export function getItemCategoriesList(brandId, pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/itemCategories/${brandId}/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getItemCategoriesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItemCategory(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post('/itemCategories', body, {
        toastMessage: 'Item category is added successfuly'
      });
      dispatch(slice.actions.itemCategorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editItemCategory(id, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/itemCategories/${id}`, body, {
        toastMessage: 'Item category is updated successfuly'
      });
      dispatch(slice.actions.itemCategorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editItemCategoryStatus(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(`/itemCategories/${id}`, {
        active: status
      });
      dispatch(slice.actions.itemCategorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteItemCategory(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/itemCategories/${id}`);
      dispatch(slice.actions.itemCategorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
