import Page from 'src/components/Page';
import React, { useState, useEffect } from 'react';
import { Card, CircularProgress, Typography } from '@material-ui/core';
import { visuallyHidden } from '@material-ui/utils';
import { HeaderDashboard } from 'src/layouts/Common';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import HeadTable from './HeadTable';
import { localize } from 'src/utils/localize';
import { useTranslation } from 'react-i18next';
import { formatDateTimeAMPM } from 'src/utils/formatTime';
import { OrderStatusKeyName } from 'src/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderLogs } from 'src/store/slices/order';
import { StyledTableCell } from 'src/components/common';

const useStyles = makeStyles((theme) => ({
  root: {},
  sortSpan: visuallyHidden,
  drawer: {
    zIndex: '1999 !important'
  },
  drawerPaper: {
    width: 800,
    padding: 30
  },
  table: {
    // minWidth: 700
  }
}));
const TABLE_HEAD = [
  { id: 'causer', label: 'causer', alignRight: false },
  { id: 'causerName', label: 'causerName', alignRight: false },
  { id: 'changeTime', label: 'changeTime', alignRight: false },
  { id: 'action', label: 'action', alignRight: false },
  { id: 'description', label: 'description', alignRight: false }
];
function ActivitiesView({ order }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { orderLogs, loadingOrderLogs } = useSelector((state) => state.order);

  useEffect(() => {
    if (order) {
      dispatch(getOrderLogs(order._id));
    }
  }, [order]);

  return (
    <Page sx={{ width: '100%' }} title="Order" className={classes.root}>
      <Card className={classes.card}>
        <TableContainer component={Paper} sx={{ mb: 5 }}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <HeadTable classes={classes} headLabel={TABLE_HEAD} rowCount={20} />
            <TableBody>
              {loadingOrderLogs ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : null}

              {!loadingOrderLogs
                ? orderLogs.map((row) => (
                    <TableRow key={row._id}>
                      <StyledTableCell align="left">
                        <Typography
                          variant="body2"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {row.actorType?.toLowerCase?.() || ''}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.actorName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {formatDateTimeAMPM(row.time)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography
                          variant="body2"
                          sx={{ textTransform: 'capitalize' }}
                        >
                          {row.action?.toLowerCase?.() || ''}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Typography variant="body2">
                          {row.description || null}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Page>
  );
}

export default ActivitiesView;
