import { PATH_APP } from 'src/routes/paths';

export function CustomerPhoneLink({ customer }) {
  if (customer?.phone == null || customer?.phone === '') {
    return null;
  }

  return (
    <a
      href={`${PATH_APP.customers.root}/${customer?._id}`}
      target="_blank"
      rel="noreferrer"
    >
      {customer?.phone}
    </a>
  );
}
