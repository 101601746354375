export const PATTERNS = {
  PHONE: /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/,
  LOCATION: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
  OBJECT_ID: /^[0-9a-fA-F]{24}$/,
  CONTAINS_ARABIC: /[\u0621-\u064A\u0660-\u0669 ]+/,
  INTEGER: /^[0-9\b]+$/g
};

export const OrderStatusKeys = {
  PENDING: 'PENDING',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  ACCEPTED: 'ACCEPTED',
  SCHEDULED: 'SCHEDULED',
  IN_PROGRESS: 'IN_PROGRESS',
  READY: 'READY',
  ON_DELIVERY: 'ON_DELIVERY',
  DELIVERED: 'DELIVERED',
  FINISHED: 'FINISHED',
  EXPIRED: 'EXPIRED',
  REJECTED_BY_BRANCH: 'REJECTED_BY_BRANCH',
  CANCELLED_BY_CUSTOMER: 'CANCELLED_BY_CUSTOMER',
  CANCELLED_BY_OPERATOR: 'CANCELLED_BY_OPERATOR'
};

export const OrderStatusKeyName = {
  [OrderStatusKeys.PENDING]: 'Pending',
  [OrderStatusKeys.PENDING_PAYMENT]: 'Waiting for Payment',
  [OrderStatusKeys.ACCEPTED]: 'Offer accepted',
  [OrderStatusKeys.SCHEDULED]: 'Iftar',
  [OrderStatusKeys.IN_PROGRESS]: 'Order is preparing',
  [OrderStatusKeys.READY]: 'Order is ready',
  [OrderStatusKeys.ON_DELIVERY]: 'On way',
  [OrderStatusKeys.DELIVERED]: 'Arrived at customer',
  [OrderStatusKeys.FINISHED]: 'Done',
  [OrderStatusKeys.EXPIRED]: 'Expired',
  [OrderStatusKeys.REJECTED_BY_BRANCH]: 'Cancelled by shop',
  [OrderStatusKeys.CANCELLED_BY_CUSTOMER]: 'Cancelled by customer',
  [OrderStatusKeys.CANCELLED_BY_OPERATOR]: 'Cancelled by haader'
};

export const Actions = {
  All: 'All',
  Add: 'Add',
  View: 'View',
  Update: 'Update',
  Delete: 'Delete',
  Export: 'Export',
  FullUpdate: 'full-update'
};

export const OptionActionMap = {
  all: Actions.All,
  add: Actions.Add,
  view: Actions.View,
  edit: Actions.Update,
  delete: Actions.Delete,
  export: Actions.Export,
  viewBrands: Actions.View
};

export const Pages = {
  Admins: 'admins',
  Activity: 'activity',
  Brands: 'brands',
  Bills: 'bills',
  PremiumBrands: 'premiumBrands',
  Cities: 'cities',
  Categories: 'categories',
  ContactUs: 'contact us',
  Areas: 'areas',
  Customers: 'customers',
  Branches: 'branches',
  AppSettings: 'app settings',
  OfferLimits: 'offers limits',
  OrderCancellationReason: 'order cancellation reasons',
  UnhandeledOrders: 'unhandeled orders',
  Notifications: 'notifications',
  Deeplinks: 'deep-link-urls',
  Drivers: 'drivers',
  DriversTypes: 'drivers types',
  Promocodes: 'promocodes',
  DriverRequests: 'driver requests',
  Orders: 'orders',
  Problems: 'problems',
  ProblemTypes: 'problem types',
  Profile: 'profile',
  Resources: 'resources',
  Salaries: 'salaries',
  Roles: 'roles',
  SmsHistory: 'sms history',
  Tags: 'tags',
  Transactions: 'transactions',
  UserComments: 'user comments',
  VehicleTypes: 'vehicle types',
  OutOfCoverage: 'out of coverage',
  Zones: 'zones',
  Vendors: 'vendors',
  SmsTemplates: 'sms templates',
  Advertisements: 'advertisements',
  OrderRejectionReasons: 'order-rejection-reasons'
};

export const STATUS_COLOR_MAP = {
  COMPLETED: '#16a085',
  'IN PROGRESS': '#2980b9'
};
