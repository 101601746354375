import {
  IconButton,
  TableHead as TH,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ON_GOING_ORDERS_TABLE_HEAD, StyledTableCell } from '../constants';
import { Refresh } from '@material-ui/icons';

export function TableHead({
  order,
  classes,
  orderBy,
  headLabel,
  onRequestSort,
  refresh
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();

  return (
    <TH>
      <TableRow>
        {ON_GOING_ORDERS_TABLE_HEAD.map((headCell) => (
          <StyledTableCell
            sx={{ fontSize: '12px' }}
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
            >
              {headCell.id === 'actions' ? (
                <IconButton onClick={refresh}>
                  <Refresh sx={{ color: 'white' }} />
                </IconButton>
              ) : null}
              {headCell.label ? headCell.label : null}
              {orderBy === headCell.id ? (
                <span className={classes.sortSpan}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
          // <StyledTableCell
          //   key={headCell.id}
          //   align={headCell.alignRight ? 'right' : 'left'}
          //   sortDirection={orderBy === headCell.id ? order : false}
          // >
          //   <TableSortLabel
          //     hideSortIcon
          //     active={orderBy === headCell.id}
          //     direction={orderBy === headCell.id ? order : 'asc'}
          //     onClick={createSortHandler(headCell.id)}
          //   >
          //     {headCell.label && t(`orders.tablesHead.${headCell.label}`)}
          //     {orderBy === headCell.id ? (
          //       <span className={classes.sortSpan}>
          //         {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          //       </span>
          //     ) : null}
          //   </TableSortLabel>
          // </StyledTableCell>
        ))}
      </TableRow>
    </TH>
  );
}
