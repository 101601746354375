import { Box, IconButton } from '@material-ui/core';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ToastifyManager } from 'src/utils/toastifyManager';
import { memory } from '../SocketListeners';
import { PermissionGuard } from '../Auth/PermissionGuard';
import { Actions } from 'src/constants';
import { PATH_APP } from 'src/routes/paths';

export function DownloadExcelButton({ id, onClick, page, filters = {} }) {
  const [loading, setLoading] = useState(false);
  const downloadExcel = () => {
    setLoading(true);
    ToastifyManager.toast(
      id,
      'Click to navigate to download it from export jobs',
      {
        type: 'info',
        // isLoading: true,
        onClick: () => window.open(PATH_APP.exportJobs.root, '_blank'),
        onClose: () => setLoading(false)
      }
    );

    onClick()
      .then((exportId) => {
        memory.set(id, exportId);
        setTimeout(() => {
          ToastifyManager.dismiss(id);
          setLoading(false);
        }, 120000);
      })
      .catch((e) => {
        const errorMessage =
          e.response?.data?.message || e.message || 'Something went wrong';

        ToastifyManager.dismiss(id);
        setLoading(false);
        toast(errorMessage, { type: 'error' });
      });
  };

  const buttonStyle = {
    width: '50px',
    height: '50px'
  };
  const imageStyle = {
    width: '80%'
  };

  if (loading) {
    imageStyle.filter = 'grayscale(100%)';
  }

  return (
    <PermissionGuard page={page} action={Actions.Export}>
      <IconButton
        size="small"
        style={buttonStyle}
        disabled={loading}
        onClick={downloadExcel}
      >
        <img
          src="/static/icons/excel.ico"
          alt="download excel"
          style={imageStyle}
        />
      </IconButton>
    </PermissionGuard>
  );
}
