import { useReducer } from 'react';

export const usePagination = (pagesCount = 0, initialPage = 1) => {
  const [state, setState] = useReducer((prev, next) => ({ ...prev, ...next }), {
    page: initialPage,
    goTo: initialPage
  });

  const handleChangePage = (event, newPage) => {
    setState({ page: newPage });
  };

  const handleGoTo = () => {
    let safeGoTo = Math.max(initialPage, Math.min(state.goTo, pagesCount));

    if (safeGoTo !== state.goTo) {
      setState({ goTo: safeGoTo });
    }
    setState({ page: safeGoTo });
  };

  const handleGoToChange = (e) => {
    setState({ goTo: e.target.value });
  };

  const handleEnterGoTo = (e) => {
    if (e.key === 'Enter') {
      handleGoTo();
    }
  };

  return {
    page: state.page,
    goTo: state.goTo,
    handleChangePage,
    handleGoTo,
    handleGoToChange,
    handleEnterGoTo
  };
};
