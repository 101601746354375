import {
  Box,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField
} from '@material-ui/core';
import { addDays, addHours, startOfDay } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Send } from '@material-ui/icons';
import { DateFilter } from 'src/components/Filters/DateFilter';
import { OrderStatusFilter } from 'src/components/Filters/OrderStatusFilter';
import { PromoCodeFilter } from 'src/components/Filters/PromoCodeFilter';
import { SearchTextFilter } from 'src/components/Filters/SearchTextFilter';
import { ZonesFilter } from 'src/components/Filters/ZonesFilter';
import { usePagination } from 'src/hooks';
import {
  getOnGoingOrders,
  selectOnGoingOrdersState
} from 'src/store/slices/vendorsOrders';
import { MCircularProgress } from 'src/theme';
import { DownloadExcelButton } from 'src/components/Buttons/DownloadExcelButton';
import apiInstance, { v2ApiInstance } from 'src/utils/api';
import { ShopTypeOptions } from 'src/components/Filters/ShopTypeFilter';
import { Pages } from 'src/constants';
import { ON_GOING_ORDERS_TABLE_HEAD } from '../constants';
import { OrderRow } from './OrderRow';
import { TableHead } from './TableHead';
import { CustomerFilter } from 'src/components/Filters/CustomerFilter';
import { DriverFilter } from 'src/components/Filters/DriverFilter';
import { BrandsFilter } from 'src/components/Filters/BrandsFilter';
import { GenericFilter } from 'src/components/Filters/GenericFilter';

export default function OnGoingOrdersTable({ onInfoClick, onCommentClick }) {
  const [searchText, setSearchText] = useState('');
  const [customer, setCustomer] = useState(null);
  const [driver, setDriver] = useState(null);
  const [brand, setBrand] = useState(null);
  const [zones, setZones] = useState([]);
  const [fromDate, setFromDate] = useState(addHours(startOfDay(new Date()), 8));
  const [toDate, setToDate] = useState(
    addHours(startOfDay(addDays(new Date(), 1)), 3)
  );
  const [promoCode, setPromoCode] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [paymentMethod, setPaymehtMethod] = useState(undefined);
  const dispatch = useDispatch();
  const { orders, pages, count, loading } = useSelector(
    selectOnGoingOrdersState
  );
  const {
    page,
    goTo,
    handleChangePage,
    handleEnterGoTo,
    handleGoTo,
    handleGoToChange
  } = usePagination(pages);

  useEffect(() => {
    getOrders();
  }, [
    searchText,
    customer,
    driver,
    brand,
    zones,
    fromDate,
    toDate,
    promoCode,
    statuses,
    paymentMethod,
    page
  ]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [dispatch]);

  const onSearchChange = useCallback((v) => setSearchText(v), [dispatch]);
  const onCustomerChange = useCallback((v) => setCustomer(v), [dispatch]);
  const onDriverChange = useCallback((v) => setDriver(v), [dispatch]);
  const onBrandChange = useCallback((v) => setBrand(v), [dispatch]);
  const onPromoCodeChange = useCallback((v) => setPromoCode(v), [dispatch]);
  const onStatusChange = useCallback((v) => setStatuses(v), [dispatch]);
  const onPaymentMethodChange = useCallback((v) => setPaymehtMethod(v), []);
  const onZonesChange = useCallback((v) => setZones(v), [dispatch]);
  const onDateChange = useCallback((dates) => {
    const startDate = get(dates, '0', null);
    const endDate = get(dates, '1', null);
    setFromDate(startDate);
    setToDate(endDate);
  }, []);

  const getOrders = () => {
    dispatch(getOnGoingOrders(getOrderFilters()));
  };

  const getOrderFilters = () => {
    return {
      searchText,
      customerId: customer?._id || undefined,
      driverId: driver?._id || undefined,
      brandId: brand?._id || undefined,
      promoCode,
      filteredZones: zones.map((z) => z._id),
      filteredStatus: statuses.map((s) => s.key),
      fromDate,
      toDate,
      paymentMethod,
      pageNumber: page,
      pageSize: 25
    };
  };

  const onVisibilityChange = (e) => {
    // if (document.visibilityState === 'visible') {
    //   getOrders();
    // }
  };

  return (
    <Box p="25px" display="flex" flexDirection="column" gap="25px">
      <Box display="flex" flexDirection="column" gap="20px">
        {/* <Box display="flex" gap="10px" flexWrap="wrap"> */}
        <Grid container spacing={1}>
          <Grid item xs={6} md={2}>
            <SearchTextFilter onChange={onSearchChange} />
          </Grid>

          <Grid item xs={6} md={2}>
            <ZonesFilter
              preload={false}
              label="Areas"
              onChange={onZonesChange}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <CustomerFilter
              singleValue
              preload={false}
              label="Customer"
              onChange={onCustomerChange}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <DriverFilter
              singleValue
              preload={false}
              label="Driver"
              onChange={onDriverChange}
              ordersType="vendor"
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <BrandsFilter
              singleValue
              preload={false}
              label="Brand"
              onChange={onBrandChange}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <OrderStatusFilter
              multiple
              vendorOrders
              onChange={onStatusChange}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <div style={{ marginTop: '8px', width: '100%' }}>
              <PromoCodeFilter
                singleValue
                preload={false}
                onChange={onPromoCodeChange}
              />
            </div>
          </Grid>

          <Grid item xs={6} md={2}>
            <div style={{ marginTop: '8px', width: '100%' }}>
              <GenericFilter
                options={[
                  { name: 'All', value: undefined },
                  { name: 'Cash', value: 'CASH' },
                  { name: 'Visa', value: 'VISA' }
                ]}
                defaultValue={{ name: 'All', value: 'all' }}
                onChange={onPaymentMethodChange}
                label="Payment Method"
              />
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <DateFilter
              picker="mui"
              startDate={fromDate}
              endDate={toDate}
              onChange={onDateChange}
            />
          </Grid>

          <Box mx={4} mt={1}>
            <DownloadExcelButton
              id="export-orders"
              page={Pages.Orders}
              onClick={() => {
                const filters = getOrderFilters();
                const { promoCode, filteredStatus, filteredZones } = filters;

                filters.orderTypes = [ShopTypeOptions.Vendor.value];

                if (promoCode) {
                  filters.promocodes = [promoCode];
                }
                if (filteredStatus) {
                  filters.status = filteredStatus;
                }
                if (filteredZones) {
                  filters.areaIds = filteredZones;
                }

                delete filters.orderType;
                delete filters.promoCode;
                delete filters.filteredStatus;
                delete filters.filteredZones;

                return v2ApiInstance
                  .get(`/v1/admin/orders/export`, { params: filters })
                  .then((r) => r.data.exportId);

                // apiInstance
                //   .post(`/orders/export`, {
                //     ...getOrderFilters(),
                //     orderType: ShopTypeOptions.Vendor.value
                //   })
                //   .then((r) => r.data.exportId)
              }}
            />
          </Box>
        </Grid>
        {/* </Box> */}
      </Box>
      <TableContainer
        sx={{
          whiteSpace: 'nowrap'
        }}
        component={Paper}
      >
        <Table aria-label="sticky table" stickyHeader>
          <TableHead refresh={getOrders} />
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={ON_GOING_ORDERS_TABLE_HEAD.length}
                >
                  <Box
                    sx={{
                      py: 3
                    }}
                  >
                    <MCircularProgress color="info" />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {orders.map((order) => (
                <AnimatePresence exitBeforeEnter>
                  <OrderRow
                    key={order._id}
                    order={order}
                    onInfoClick={onInfoClick}
                    onCommentClick={onCommentClick}
                  />
                </AnimatePresence>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="20px"
      >
        <span>{count} Orders</span>
        <Pagination
          count={pages}
          color="primary"
          page={page || 1}
          onChange={handleChangePage}
        />
        <TextField
          id="goto"
          label="Go to"
          value={goTo}
          onChange={handleGoToChange}
          onKeyDown={handleEnterGoTo}
          InputProps={{
            sx: {
              width: '125px'
            },
            endAdornment: (
              <IconButton onClick={handleGoTo}>
                <Send />
              </IconButton>
            )
          }}
        />
      </Box>
    </Box>
  );
}
