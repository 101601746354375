import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isAddLoading: false,
  addError: false,
  addSuccessMessage: '',
  isEditLoading: false,
  editError: false,
  editSuccessMessage: '',
  isLoading: false,
  zonesError: false,
  zonesList: [],
  isAutoCompleteLoading: false,
  zonesListAutoComplete: [],
  autoCompleteError: false,
  zoneStatusLoadingMap: {}
};

const slice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    startAddLoading(state) {
      state.isAddLoading = true;
      state.addSuccessMessage = '';
      state.addError = false;
    },
    setZoneStatusLoading(state, action) {
      state.zoneStatusLoadingMap[action.payload._id] = action.payload.status;
    },

    addHasError(state, action) {
      state.isAddLoading = false;
      state.addError = action.payload;
    },
    addZoneSuccess(state, action) {
      state.addSuccessMessage = action.payload;
      state.isAddLoading = false;
      state.addError = false;
    },

    startEditLoading(state, action) {
      state.editSuccessMessage = '';
      state.editError = false;
      state.isEditLoading = true;
    },

    editHasError(state, action) {
      state.isEditLoading = false;
      state.editError = action.payload;
    },
    editZoneSuccess(state, action) {
      state.editSuccessMessage = action.payload;
      state.isEditLoading = false;
      state.editError = false;
    },
    startLoading(state) {
      state.zonesList = [];
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.zonesError = action.payload;
    },
    getZonesListSuccess(state, action) {
      state.zonesList = action.payload.zonesList;
      state.isLoading = false;
    },
    startAutoCompleteZonesLoading(state) {
      state.isAutoCompleteLoading = true;
    },

    autoCompleteHasError(state, action) {
      state.isAutoCompleteLoading = false;
      state.autoCompleteError = action.payload;
    },
    getAutoCompleteZonesListSuccess(state, action) {
      state.zonesListAutoComplete = action.payload.zones;
      state.isAutoCompleteLoading = false;
    },
    clearEditStatusSuccess(state) {
      state.editSuccessMessage = '';
      state.editError = false;
    },
    clearAddStatusSuccess(state) {
      state.addSuccessMessage = '';
      state.addError = false;
    },
    updateZoneStatus(state, action) {
      if (action.payload?.status != undefined) {
        state.zonesList = state.zonesList.map((t) => {
          if (t._id == action.payload?.id) {
            t.active = action.payload?.status;
          }
          return t;
        });
      }
    },
    resetAreaZonesAutoComplete(state, action) {
      state.zonesListAutoComplete = [];
    }
  }
});

export default slice.reducer;

export function addZone(areaId, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startAddLoading());
      const response = await apiInstance.post(`/zones/${areaId}`, body, {
        toastMessage: 'Zone is added successfully'
      });
      dispatch(slice.actions.addZoneSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.addHasError(error));
    }
  };
}

export function editZone(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditLoading());
      const response = await apiInstance.put(`/zones/${id}`, body, {
        toastMessage: 'Zone is updated successfully'
      });
      dispatch(slice.actions.editZoneSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.editHasError(error));
    }
  };
}

export function getAreaZonesList(areaId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/zones/${areaId}`);
      dispatch(slice.actions.getZonesListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editZonesStatus(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setZoneStatusLoading({ _id: id, status: true }));
      const response = await apiInstance.put(`/zones/${id}`, {
        active: status
      });
      if (response.status === 200) {
        dispatch(slice.actions.updateZoneStatus({ id, status }));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setZoneStatusLoading({ _id: id, status: false }));
    }
  };
}

export function getAreaZonesAutoComplete({ name = null, active = null } = {}) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startAutoCompleteZonesLoading());
      const response = await apiInstance.get(`/zones`, {
        params: { name, active }
      });
      dispatch(
        slice.actions.getAutoCompleteZonesListSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.autoCompleteHasError(error));
    }
  };
}

export function clearEditState() {
  return async (dispatch) => {
    dispatch(slice.actions.clearEditStatusSuccess());
  };
}

export function clearAddState() {
  return async (dispatch) => {
    dispatch(slice.actions.clearAddStatusSuccess());
  };
}

export function resetAreaZonesAutoComplete() {
  return async (dispatch) => {
    dispatch(slice.actions.resetAreaZonesAutoComplete());
  };
}
