import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  reasonsList: [],
  count: 0,
  reasonSuccessMessage: ''
};

const slice = createSlice({
  name: 'orderCancellationReasons',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getReasonsSuccess(state, action) {
      state.reasonsList = action.payload.reasons;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    reasonSuccess(state, action) {
      state.reasonSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
    },

    startEditStatusLoading(state, action) {
      state.reasonsList = state.reasonsList.map((t) => {
        if (t._id == action.payload?.id) {
          t.status = action.payload?.body?.status;
        }
        return t;
      });
    },

    startDeleteLoading(state, action) {
      state.reasonsList = state.reasonsList.filter(
        (t) => t._id != action.payload
      );
      state.count -= 1;
    }
  }
});

export default slice.reducer;

export function getReasonsList(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/orderCancellationReasons/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getReasonsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addReason(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post(
        '/orderCancellationReasons',
        body,
        {
          toastMessage: 'Reason is added successfully'
        }
      );
      dispatch(slice.actions.reasonSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editReasonStatus(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, body }));
      const response = await apiInstance.put(
        `/orderCancellationReasons/${id}`,
        body
      );
      dispatch(slice.actions.reasonSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteReason(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(
        `/orderCancellationReasons/${id}`
      );
      dispatch(slice.actions.reasonSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editReason(id, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(
        `/orderCancellationReasons/${id}`,
        body,
        {
          toastMessage: 'Reason is updated successfully'
        }
      );
      dispatch(slice.actions.reasonSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
