import DateFnsUtils from '@date-io/date-fns';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import _ from 'lodash';
import { forwardRef, useCallback, useReducer, useState } from 'react';
import DatePicker from 'react-datepicker';

const pickers = {
  'react-datepicker': ReactDatePicker,
  mui: MuiDatePicker
};

/**
 *
 * @param {{picker: 'react-datepicker' | 'mui'}} param0
 * @returns
 */
export function DateFilter({ picker, ...rest }) {
  const Picker = pickers[picker];
  return <Picker {...rest} />;
}

export function MuiDatePicker({
  startDate = new Date(),
  endDate = new Date(),
  onChange,
  onChangeFrom,
  onChangeTo,
  gridContainerProps
}) {
  const [fromTime, setFromTime] = useState({ hour: '00', minute: '00' });
  const [toTime, setToTime] = useState({ hour: '00', minute: '00' });
  const PureFromTimeSelect = useCallback(
    () => (
      <TimeSelect initial={startDate} value={fromTime} onChange={setFromTime} />
    ),
    [fromTime, startDate]
  );
  const PureToTimeSelect = useCallback(
    () => <TimeSelect initial={endDate} value={toTime} onChange={setToTime} />,
    [endDate, toTime]
  );
  const onFromDateChange = useCallback(
    (date) => {
      const hour = parseInt(fromTime.hour || '00');
      const minute = parseInt(fromTime.minute || '00');

      date.setHours(hour);
      date.setMinutes(minute);

      onChange([date, endDate]);
      onChangeFrom?.(date);
    },
    [fromTime, endDate, onChangeFrom]
  );
  const onToDateChange = useCallback(
    (date) => {
      const hour = parseInt(toTime.hour || '00');
      const minute = parseInt(toTime.minute || '00');

      date.setHours(hour);
      date.setMinutes(minute);

      onChange([startDate, date]);
      onChangeTo?.(date);
    },
    [toTime, startDate, onChangeTo]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={3} {...gridContainerProps}>
        <Grid item xs={6} md={6} padding={0}>
          <DateTimePicker
            // margin="normal"
            id="date-picker-dialog"
            label="From"
            value={startDate}
            onChange={onFromDateChange}
            ToolbarComponent={PureFromTimeSelect}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            // className={classes.datePicker}
            views={['month', 'year', 'date']}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <DateTimePicker
            // margin="normal"
            id="date-picker-dialog"
            label="To"
            value={endDate}
            minDate={startDate}
            onChange={onToDateChange}
            ToolbarComponent={PureToTimeSelect}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            // className={classes.datePicker}
            views={['month', 'year', 'date']}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export function ReactDatePicker({ startDate, endDate, onChange }) {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      ref={ref}
      label={value}
      placeholder="Date Range"
      InputProps={{
        endAdornment:
          startDate && endDate ? (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  onChange([]);
                }}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ) : null
      }}
    >
      {value}
    </TextField>
  ));

  return (
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        onChange(update);
      }}
      customInput={<CustomInput />}
    />
  );
}

function TimeSelect({ value, onChange }) {
  const setHour = useCallback(
    (_, hour) => {
      onChange({ ...value, hour });
    },
    [onChange, value]
  );

  const setMinute = useCallback(
    (_, minute) => {
      onChange({ ...value, minute });
    },
    [onChange, value]
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap="15px"
      p="15px"
    >
      <Autocomplete
        sx={{ maxWidth: 'fit-content' }}
        options={_.times(25, (n) => _.padStart(n, 2, 0))}
        getOptionLabel={(o) => o}
        renderInput={(params) => <TextField {...params} />}
        disableClearable
        defaultValue={value.hour}
        onChange={setHour}
      />
      <Typography component="span" variant="h3">
        :
      </Typography>
      <Autocomplete
        sx={{ maxWidth: 'fit-content' }}
        options={_.times(60, (n) => _.padStart(n, 2, 0))}
        getOptionLabel={(o) => o}
        renderInput={(params) => <TextField {...params} />}
        disableClearable
        defaultValue={value.minute}
        onChange={setMinute}
      />
    </Box>
  );
}
