import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import apiInstance from 'src/utils/api';

const initialState = {
  status: 'idle', //'idle' | 'loading' |'succeeded' | 'failed'
  error: null,
  driverLocation: null
};

export const getDriverLocation = createAsyncThunk(
  'onlineDriver/driverLocation',
  async (initialLocation) => {
    try {
      const { id } = initialLocation;

      const response = await apiInstance.get(`/drivers/${id}/location`);

      return response.data;
    } catch (err) {
      return err.message;
    }
  }
);

const locationDriverSlice = createSlice({
  name: 'driverLocation',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDriverLocation.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getDriverLocation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.driverLocation = action.payload;
      })
      .addCase(getDriverLocation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export const getLocationDriverStatus = (state) => state.driverLocation.status;
export const getDriverLocationError = (state) => state.driverLocation.error;
export const driverLocationValue = (state) =>
  state.driverLocation.driverLocation;

export default locationDriverSlice.reducer;
