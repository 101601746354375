import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import settingsReducer from './slices/settings';
import { persistReducer } from 'redux-persist';
import auth from './slices/auth';
import notificationsReducer from './slices/notifications';
import officesReducer from './slices/settings/offices';
import departmentsReducer from './slices/settings/departments';
import servedCountriesReducer from './slices/settings/servedCountries';
import orderReducer from './slices/order';
import vehicleTypesReducer from './slices/vehicleTypes';
import problemTypesReducer from './slices/problemTypes';
import problemReducer from './slices/problems';
import contactUsReducer from './slices/contactUs';
import driverRequestsReducer from './slices/driverRequests';
import orderCancellationReasonsReducer from './slices/orderCancellationReasons';
import orderRejectionReasonsReducer from './slices/orderRejectionReasons';
import userTypesReducer from './slices/userTypes';
import categoryReducer from './slices/categories';
import brandReducer from './slices/brands';
import tagReducer from './slices/tags';
import itemCategoryReducer from './slices/itemCategories';
import branchReducer from './slices/branches';
import itemReducer from './slices/items';
import itemExtrasReducer from './slices/itemExtras';
import itemExtraChoiceReducer from './slices/itemExtras';
import coverageAreaReducer from './slices/CoverageAreas';
import cityReducer from './slices/cities';
import promoCodeReducer from './slices/promoCodes';
import offerLimitReducer from './slices/OffersLimits';
import driverReducer from './slices/drivers';
import transactionReducer from './slices/transactions';
import appSettingReducer from './slices/appSetting';
import zoneReducer from './slices/zones';
import driverWorkingHoursReducer from './slices/workingHours';
import userDevicesReducer from './slices/devices';
import userCommentReducer from './slices/userComments';
import orderCommentReducer from './slices/orderComments';
import customerReducer from './slices/customers';
import adminReducer from './slices/admin';
import vendorReducer from './slices/vendors';
import smsHistoryReducer from './slices/smsHistory';
import orderStatusReducer from './slices/orderStatus';
import outOfCoverageAreasReducer from './slices/outOfCoverageAreas';
import adsReducer from './slices/ads';
import driverTypesReducer from './slices/driverTypes';
import locationDriverReducer from './slices/driveLocation';
import driverSalaryReducer from './slices/driverSalary';
import deeplinksReducer from './slices/deeplinks';
import premiumBrandsReducer from './slices/premiumBrands';
import vendorsOrders from './slices/vendorsOrders';
import ramadanOrders from './slices/ramadanOrders';
import exportJobs from './slices/exportJobs';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'admin-',
  whitelist: ['settings']
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  keyPrefix: 'admin-',
  whitelist: [
    'user',
    'isAuthenticated',
    'currentRolePermissions',
    'permissionList',
    'isSuperAdmin'
  ]
};

const userPersistConfig = {
  key: 'user',
  storage: storage,
  keyPrefix: 'admin-',
  whitelist: ['user', 'userProfile']
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  offices: officesReducer,
  departments: departmentsReducer,
  servedCountries: servedCountriesReducer,
  settings: settingsReducer,
  notifications: notificationsReducer,
  auth: persistReducer(authPersistConfig, auth),
  order: orderReducer,
  vendorsOrders: vendorsOrders,
  ramadanOrders: ramadanOrders,
  vehicleTypes: vehicleTypesReducer,
  problemTypes: problemTypesReducer,
  problems: problemReducer,
  contactUs: contactUsReducer,
  driverRequests: driverRequestsReducer,
  driverSalary: driverSalaryReducer,
  orderCancellationReasons: orderCancellationReasonsReducer,
  orderRejectionReasons: orderRejectionReasonsReducer,
  userTypes: userTypesReducer,
  category: categoryReducer,
  brand: brandReducer,
  tag: tagReducer,
  itemCategory: itemCategoryReducer,
  branch: branchReducer,
  item: itemReducer,
  itemExtras: itemExtrasReducer,
  itemExtraChoice: itemExtraChoiceReducer,
  coverageArea: coverageAreaReducer,
  city: cityReducer,
  promoCode: promoCodeReducer,
  offerLimit: offerLimitReducer,
  driver: driverReducer,
  transaction: transactionReducer,
  appSetting: appSettingReducer,
  zone: zoneReducer,
  driverWorkingHours: driverWorkingHoursReducer,
  userDevices: userDevicesReducer,
  userComments: userCommentReducer,
  orderComments: orderCommentReducer,
  customer: customerReducer,
  admin: adminReducer,
  vendor: vendorReducer,
  smsHistory: smsHistoryReducer,
  orderStatus: orderStatusReducer,
  outOfCoverageAreas: outOfCoverageAreasReducer,
  ads: adsReducer,
  driverTypes: driverTypesReducer,
  driverLocation: locationDriverReducer,
  deeplinks: deeplinksReducer,
  premiumBrands: premiumBrandsReducer,
  exportJobs
});

export { rootPersistConfig, rootReducer };
