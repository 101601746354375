import { filter } from 'lodash';
import _ from 'lodash';

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function descendingComparatorByPath(a, b, orderBy) {
  let t1 = _.get(a, orderBy);
  let t2 = _.get(b, orderBy);
  if (t2 < t1) {
    return -1;
  }
  if (t2 > t1) {
    return 1;
  }
  return 0;
}
export function getComparatorByPath(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparatorByPath(a, b, orderBy)
    : (a, b) => -descendingComparatorByPath(a, b, orderBy);
}

export function applySortFilter(
  array,
  comparator,
  query,
  queryFields = ['name']
) {
  const stabilizedThis =
    typeof array === 'object' && array?.map((el, index) => [el, index]);
  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  if (query) {
    array = filter(array, (_user) => {
      return queryFields.some((q) => {
        const field = _.get(_user, q, '');
        let isValid = false;

        if (field.hasOwnProperty('en')) {
          isValid =
            field.en.toLowerCase().includes(query.toLowerCase()) || isValid;
        }

        if (field.hasOwnProperty('ar')) {
          isValid =
            field.ar.toLowerCase().includes(query.toLowerCase()) || isValid;
        }

        if (typeof field === 'string') {
          isValid =
            field.toLowerCase().includes(query.toLowerCase()) || isValid;
        }

        return isValid;
      });
    });
    return array;
  }
  return stabilizedThis.map((el) => el[0]);
}

export function applySortFilterWithSpecialKeys(
  array,
  comparator,
  query,
  queryFields = [
    {
      objKeyName: '',
      keyName: 'name'
    }
  ]
) {
  const stabilizedThis = array && array.map((el, index) => [el, index]);
  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  if (query) {
    array = filter(array, (_user) => {
      return queryFields.some((q) => {
        if (q.objKeyName) {
          return _user[q.objKeyName][q.keyName]
            .toLowerCase()
            .startsWith(query.toLowerCase());
        } else {
          return _user[q.keyName].toLowerCase().startsWith(query.toLowerCase());
        }
      });
    });
    return array;
  }
  return stabilizedThis.map((el) => el[0]);
}

export function applySortFilterByPath(
  array = [],
  comparator,
  query,
  queryFields = ['name']
) {
  const stabilizedThis = array && array.map((el, index) => [el, index]);
  stabilizedThis &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  if (query) {
    query = query.trim();
    array = filter(array, (_user) => {
      return queryFields.some((q) => {
        if (q.includes('firstName') || q.includes('lastName')) {
          let qa = q.split('.');
          qa.pop();
          qa = qa.join('.');
          const userObj = _.get(_user, qa);
          if (userObj) {
            const name = `${userObj['firstName']?.toLowerCase()} ${userObj[
              'lastName'
            ]?.toLowerCase()}`;
            if (name.includes(query.toLowerCase())) {
              return _user;
            }
          }
        }
        let t = _.get(_user, q);
        if (t) {
          if (typeof t !== 'string') {
            t = t.toString();
          }
          if (t?.toLowerCase().includes(query.toLowerCase())) {
            return _user;
          }
        }
      });
    });
    return array;
  }
  return stabilizedThis.map((el) => el[0]);
}
