import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { Events, eventsManager } from 'src/utils/eventManager';
import { store } from '../store';
import { get, last } from 'lodash';

export const SubState = {
  NewOrders: 'newOrders',
  OnGoingOrders: 'onGoingOrders'
};

const initialState = {
  selectedOrder: null,
  [SubState.NewOrders]: {
    loading: false,
    count: 0,
    pages: 0,
    orders: [],
    error: null
  },
  [SubState.OnGoingOrders]: {
    loading: false,
    count: 0,
    pages: 0,
    orders: []
  }
};

const slice = createSlice({
  name: 'vendorsOrders',
  initialState,
  reducers: {
    addNewOrder(state, { payload }) {
      const order = payload;
      let orders = state[SubState.NewOrders].orders;

      orders = orders.filter((o) => o._id !== order._id);
      orders.unshift({ ...order, __highlight: true });
      state[SubState.NewOrders].orders = orders;
    },
    newOrdersLoading(state, { payload }) {
      state[SubState.NewOrders].loading = payload;
    },
    onGoingOrdersLoading(state, { payload }) {
      state[SubState.OnGoingOrders].loading = payload;
    },
    getNewOrdersSuccess(state, { payload }) {
      state[SubState.NewOrders].orders = payload.orders;
      state[SubState.NewOrders].count = payload.count;
      state[SubState.NewOrders].pages = payload.pages;
      state[SubState.NewOrders].loading = false;
    },
    getOnGoingOrdersSuccess(state, { payload }) {
      state[SubState.OnGoingOrders].orders = payload.orders;
      state[SubState.OnGoingOrders].count = payload.count;
      state[SubState.OnGoingOrders].pages = payload.pages;
      state[SubState.OnGoingOrders].loading = false;
    },
    newOrdersHasError(state, { payload }) {
      console.error(payload);
      state[SubState.NewOrders].error = payload;
      state[SubState.NewOrders].loading = false;
    },
    onGoingOrdersHasError(state, { payload }) {
      console.error(payload);
      state[SubState.OnGoingOrders].error = payload;
      state[SubState.OnGoingOrders].loading = false;
    },
    selectVendorOrder(state, { payload }) {
      state.selectedOrder = payload;
    },
    handleOrderChanged(state, { payload }) {
      const order = payload.order;
      const newOrders = state[SubState.NewOrders].orders;
      const onGoingOrders = state[SubState.OnGoingOrders].orders;
      const suspendedStatuses = [
        'EXPIRED',
        'REJECTED_BY_BRANCH',
        'CANCELLED_BY_CUSTOMER',
        'CANCELLED_BY_OPERATOR',
        'FINISHED'
      ];

      if (!order) {
        return;
      }

      const newOrderIndex = newOrders.findIndex((o) => o._id === order?._id);
      const onGoingOrderIndex = onGoingOrders.findIndex(
        (o) => o._id === order._id
      );

      if (newOrderIndex !== -1) {
        state[SubState.NewOrders].orders.splice(newOrderIndex, 1);
        const status = get(last(order.status), 'key');

        order.__highlight = true;

        if (suspendedStatuses.includes(status)) {
          state[SubState.OnGoingOrders].orders.unshift(order);
          return;
        }

        state[
          order.driver ? SubState.OnGoingOrders : SubState.NewOrders
        ].orders.unshift(order);
      }

      if (onGoingOrderIndex !== -1) {
        state[SubState.OnGoingOrders].orders.splice(
          onGoingOrderIndex,
          1,
          order
        );
      }
    }
  }
});

eventsManager.on(Events.Orders.OrderChanged, (order) => {
  store.dispatch(slice.actions.handleOrderChanged({ order }));
});

export default slice.reducer;
export const selectNewOrdersState = (state) =>
  state.vendorsOrders[SubState.NewOrders];
export const selectOnGoingOrdersState = (state) =>
  state.vendorsOrders[SubState.OnGoingOrders];

export function addNewOrder(order) {
  return async (dispatch) => {
    dispatch(slice.actions.addNewOrder(order));
  };
}

export function getNewOrders(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.newOrdersLoading(true));
      const response = await apiInstance.post('/orders/vendor/pending', {
        ...body,
        orderType: 'VENDOR',
        scheduled: false
      });
      dispatch(slice.actions.getNewOrdersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.newOrdersHasError(error));
    }
  };
}

export function getOnGoingOrders(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.onGoingOrdersLoading(true));
      const response = await apiInstance.post('/orders/vendor', {
        ...body,
        orderType: 'VENDOR',
        scheduled: false
      });
      dispatch(slice.actions.getOnGoingOrdersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.onGoingOrdersHasError(error));
    }
  };
}

export function selectVendorOrder(order) {
  return async (dispatch) => {
    dispatch(slice.actions.selectVendorOrder(order));
  };
}
