import { createSlice } from '@reduxjs/toolkit';
import apiInstance, { v2ApiInstance } from 'src/utils/api';

const initialState = {
  isAutoCompleteLoading: false,
  error: false,
  driversListAutoComplete: [],
  count: 0,
  driversList: [],
  isLoading: false,
  driverSuccessMessage: '',
  driver: {},
  isAddDriverDocumentLoading: false,
  addDriverDocumentSuccessMessage: '',
  addDriverDocumentError: false,
  isDeleteDocLoading: false,
  deleteDocSuccessMessage: '',
  deleteDocError: false,
  areaDrivers: [],
  onlineDrivers: {},
  driversGroupedByZone: []
};

const slice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    startAutoCompleteLoading(state) {
      state.isAutoCompleteLoading = true;
    },

    autoCompleteHasError(state, action) {
      state.isAutoCompleteLoading = false;
      state.autoCompleteError = action.payload;
    },

    getDriversAutoCompleteSuccess(state, action) {
      state.driversListAutoComplete = action.payload.drivers;
      state.isAutoCompleteLoading = false;
    },
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDriversSuccess(state, action) {
      state.driversList = action.payload.drivers;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    getAreaDriversSuccess(state, action) {
      state.areaDrivers = action.payload.list;
      state.isLoading = false;
    },

    driverSuccess(state, action) {
      state.driverSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
        state.isLoading = false;
      }
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status != undefined) {
        state.driversList = state.driversList.map((t) => {
          if (t.user?._id == action.payload?.id) {
            t.user.status = action.payload?.status;
          }
          return t;
        });
      }
    },

    getDriverSuccess(state, action) {
      state.driver = action.payload.driver;
      state.isLoading = false;
    },
    getUserSuccess(state, action) {
      state.driver = action.payload.user;
      state.isLoading = false;
    },

    startDeleteZoneLoading(state, action) {
      if (action.payload.zone) {
        state.driver.zones = state.driver.zones.filter(
          (z) => z._id != action.payload.zone
        );
      }
    },

    startAddDocumentLoading(state, action) {
      // state.isAddDriverDocumentLoading = true;
      if (state.driver.documents?.length) {
        state.driver.documents.push(action.payload.document);
      } else {
        state.driver.documents = [action.payload.document];
      }
    },

    addDocumentHasError(state, action) {
      // state.isAddDriverDocumentLoading = false;
      state.addDriverDocumentError = action.payload;
    },

    addDocumentSuccess(state, action) {
      state.addDriverDocumentSuccessMessage = action.payload.message;
      // state.isAddDriverDocumentLoading = false;
    },
    startDeleteDocumentLoading(state, action) {
      if (action.payload.document) {
        state.driver.documents = state.driver.documents.filter(
          (d) => d != action.payload.document
        );
      }
      state.isDeleteDocLoading = true;
    },

    deleteDocumentHasError(state, action) {
      state.isDeleteDocLoading = false;
      state.deleteDocError = action.payload;
    },

    deleteDocumentSuccess(state, action) {
      state.deleteDocSuccessMessage = action.payload.message;
      state.isDeleteDocLoading = false;
    },
    getOnlineDriversSuccess(state, action) {
      state.onlineDrivers = action.payload.onlineDrivers;
      state.isLoading = false;
    },
    getDriversGroupByZoneSuccess(state, action) {
      state.driversGroupedByZone = action.payload;
      state.isLoading = false;
    },
    resetErrorSuccessMessage(state) {
      state.error = false;
      state.driverSuccessMessage = '';
    },
    resetDriversAutoComplete(state) {
      state.driversListAutoComplete = [];
    }
  }
});

export default slice.reducer;

export function getdriversListAutoComplete(userName) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startAutoCompleteLoading());
      const response = await apiInstance.post(`/drivers/autoComplete`, {
        name: userName
      });
      dispatch(slice.actions.getDriversAutoCompleteSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.autoCompleteHasError(error));
    }
  };
}

export function getdriversList(pageNumber, pageSize, filters) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(
        `/drivers/${pageNumber}/${pageSize}`,
        filters
      );
      dispatch(slice.actions.getDriversSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addDriver(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(`/drivers`, body, {
        toastMessage: 'Driver is added successfully'
      });
      dispatch(slice.actions.driverSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error?.response?.data?.message));
    }
  };
}

export function updateDriver(id, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/drivers/${id}`, body, {
        toastMessage: 'Driver is updated successfully'
      });
      dispatch(slice.actions.driverSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function blockUnBlockDriver(id, status, blockReason) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(
        `/users/status/${id}`,
        {
          status,
          blockReason
        },
        {
          toastMessage: `Driver ${
            status === true ? 'unblocked' : 'blocked'
          } successfully`
        }
      );
      dispatch(slice.actions.driverSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDriverById(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/drivers/${id}`);
      dispatch(slice.actions.getDriverSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDriverByUserId(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/users/driver/${id}`);
      dispatch(slice.actions.getUserSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addDriverDocument(id, document) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startAddDocumentLoading({ document }));
      const response = await apiInstance.post(
        `/drivers/documents/${id}`,
        {
          document
        },
        {
          toastMessage: 'Document is added successfully'
        }
      );
      dispatch(slice.actions.addDocumentSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.addDocumentHasError(error));
    }
  };
}

export function deleteDriverDocument(id, document) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteDocumentLoading({ document }));
      const response = await apiInstance.put(`/drivers/documents/${id}`, {
        document
      });
      dispatch(slice.actions.deleteDocumentSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.deleteDocumentHasError(error));
    }
  };
}

export function getDriversListByAreaId(areaId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/drivers/area/${areaId}`);
      dispatch(slice.actions.getAreaDriversSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOnlineDrivers(zones = []) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(`/drivers/online`, {
        zones
      });
      dispatch(slice.actions.getOnlineDriversSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDriversGroupByZone() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await v2ApiInstance.get(
        `/v1/admin/drivers/grouped-zone`
      );
      dispatch(slice.actions.getDriversGroupByZoneSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reset() {
  return (dispatch) => {
    dispatch(slice.actions.resetErrorSuccessMessage());
  };
}

export function resetDriversAutoComplete() {
  return async (dispatch) => {
    dispatch(slice.actions.resetDriversAutoComplete());
  };
}
