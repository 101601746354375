import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { DriverFilter } from 'src/components/Filters/DriverFilter';
import { assignDriverToOrder } from 'src/store/slices/order';
import * as Yup from 'yup';

// Dummy github!

export function AddOfferDialog({ toggle, open, order, getOrders, setOrder }) {
  const dispatch = useDispatch();

  const addOfferSchema = Yup.object().shape({
    driver: Yup.object().required('Driver is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      driver: null
    },

    validationSchema: addOfferSchema,
    onSubmit: async (values) => {
      const payload = {
        driverId: values.driver?._id
      };
      dispatch(
        assignDriverToOrder(order._id, payload, {
          onSuccess: () => {
            toggle();
            setOrder(null);
            getOrders();
          },
          onFail: (error) => {}
        })
      );
    }
  });

  const { handleSubmit, setFieldValue } = formik;

  return (
    <Dialog
      fullWidth="lg"
      open={open}
      onClose={toggle}
      style={{ zIndex: 99999 }}
    >
      <DialogTitle>Add Offer</DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container flexDirection={'column'} spacing={2}>
              <Grid item>
                <DriverFilter
                  singleValue
                  active={true}
                  order={order}
                  onChange={(driver) => setFieldValue('driver', driver)}
                  defaultValue={order?.driver}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
