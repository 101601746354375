import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { PATH_APP } from 'src/routes/paths';
import { isAuthorized, actions } from 'src/utils/isAuthorized';
import Page404View from 'src/views/errors/Page403View';

AuthorizedProtect.propTypes = {
  children: PropTypes.node
};

function AuthorizedProtect({ children, resource }) {
  const { permissionList } = useAuth();

  if (resource && !isAuthorized(permissionList, resource, actions.view)) {
    return <Page404View />;
  }
  return <>{children}</>;
}

export default AuthorizedProtect;
