import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  problemsList: [],
  isProblemLoading: false,
  problem: {},
  problemError: false,
  count: 0,
  isAddCommentLoading: false,
  addCommentError: false,
  addCommentSuccessMessage: '',
  isStatusListLoading: false,
  statusList: [],
  statusListError: false,
  isEditLoading: false,
  editSuccessMessage: '',
  editError: false
};

const slice = createSlice({
  name: 'problems',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProblemsSuccess(state, action) {
      state.problemsList = action.payload.problems;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    startProblemLoading(state) {
      state.isProblemLoading = true;
    },

    porblemHasError(state, action) {
      state.isProblemLoading = false;
      state.problemError = action.payload;
    },

    getProblemInfoSuccess(state, action) {
      state.problem = action.payload;
      state.isProblemLoading = false;
    },

    startAddCommentLoading(state, action) {
      state.isAddCommentLoading = true;
      state.problem.comments.unshift(action.payload);
    },

    addCommentSuccess(state, action) {
      state.addCommentSuccessMessage = action.payload.message;
      state.isAddCommentLoading = false;
    },

    addCommentHasError(state, action) {
      state.isAddCommentLoading = false;
      state.addCommentError = action.payload;
    },
    startStatusListLoading(state) {
      state.isStatusListLoading = true;
    },

    statusListHasError(state, action) {
      state.isStatusListLoading = false;
      state.error = action.payload;
    },

    getStatusListSuccess(state, action) {
      state.statusList = action.payload;
      state.isStatusListLoading = false;
    },
    startEditLoading(state, action) {
      state.isEditLoading = true;
      state.problem.status = state.statusList?.find(
        (s) => s._id == action.payload?.body?.status
      );
    },

    editSuccess(state, action) {
      state.editSuccessMessage = action.payload.message;
      state.isEditLoading = false;
    },

    editHasError(state, action) {
      state.isEditLoading = false;
      state.editError = action.payload;
    }
  }
});

export default slice.reducer;

export function getProblemsList(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post('/problems', body);
      dispatch(slice.actions.getProblemsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProblemById(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startProblemLoading());
      const response = await apiInstance.get(`/problems/${id}`);
      dispatch(slice.actions.getProblemInfoSuccess(response.data.data.problem));
    } catch (error) {
      dispatch(slice.actions.porblemHasError(error));
    }
  };
}

export function addProblemComment(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startAddCommentLoading(body));
      const response = await apiInstance.post(`problems/${id}/comment`, body);
      dispatch(slice.actions.addCommentSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.addCommentHasError(error));
    }
  };
}

export function getProblemStatusList() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startStatusListLoading());
      const response = await apiInstance.get(`/problemsStatus`);
      dispatch(
        slice.actions.getStatusListSuccess(response.data.data.statusList)
      );
    } catch (error) {
      dispatch(slice.actions.statusListHasError(error));
    }
  };
}

export function editProblem(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditLoading({ id, body }));
      const response = await apiInstance.put(`/problems/${id}`, body);
      dispatch(slice.actions.editSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.editHasError(error));
    }
  };
}
