import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  tagsList: [],
  count: 0,
  tagSuccessMessage: ''
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getTagsSuccess(state, action) {
      state.tagsList = action.payload.tagsList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    tagSuccess(state, action) {
      state.tagSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status !== undefined) {
        state.tagsList = state.tagsList.map((t) => {
          if (t._id === action.payload?.id) {
            t.status = action.payload?.status;
          }
          return t;
        });
      }
    },

    startDeleteLoading(state, action) {
      state.tagsList = state.tagsList.filter((t) => t._id !== action.payload);
      state.count -= 1;
    }
  }
});

export default slice.reducer;

export function getTagsList(pageNumber, pageSize, categoryId, filter) {
  return async (dispatch) => {
    try {
      const params = {
        category: categoryId,
        ...filter
      };
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/tags/${pageNumber}/${pageSize}`,
        { params }
      );
      console.log('[[[Tags - store]]] - tags list:', response.data.data);
      dispatch(slice.actions.getTagsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addTag(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post('/tags', body, {
        toastMessage: 'Tag is added successfully'
      });
      dispatch(slice.actions.tagSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editTag(id, body, options) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/tags/${id}`, body, {
        toastMessage: 'Tag is updated successfully'
      });
      dispatch(slice.actions.tagSuccess(response.data.data));
      options?.onSuccess?.();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function editTagStatus(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(
        `/tags/${id}`,
        { active: status },
        {
          toastMessage: 'Tag is updated successfully'
        }
      );
      dispatch(slice.actions.tagSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteTag(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/tags/${id}`, {
        toastMessage: 'Tag is deleted successfully'
      });
      dispatch(slice.actions.tagSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
