import { PATH_APP } from 'src/routes/paths';

export function DriverPhoneLink({ driver }) {
  if (driver?.phone == null || driver?.phone === '') {
    return null;
  }

  return (
    <a
      href={`${PATH_APP.drivers.root}/${driver?._id}`}
      target="_blank"
      rel="noreferrer"
    >
      {driver?.phone}
    </a>
  );
}
