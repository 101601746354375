import { Autocomplete, TextField } from '@material-ui/core';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAreaZonesAutoComplete,
  resetAreaZonesAutoComplete
} from 'src/store/slices/zones';

export function ZonesFilter({
  defaultValue,
  value,
  onChange,
  label = 'Zones',
  singleValue = false,
  overNetwork = true,
  preload = true
}) {
  const dispatch = useDispatch();
  const {
    zonesList,
    zonesListAutoComplete,
    isAutoCompleteLoading
  } = useSelector((state) => state.zone);

  useEffect(() => {
    if (preload) {
      dispatch(getAreaZonesAutoComplete());
    }
    return () => dispatch(resetAreaZonesAutoComplete());
  }, [dispatch]);

  const fetch = useCallback(
    (input) => {
      if (!input) {
        return;
      }

      dispatch(getAreaZonesAutoComplete({ name: input }));
    },
    [dispatch]
  );

  const debouncedFetch = useMemo(() => {
    return debounce(fetch, 500);
  }, [fetch]);

  let options = zonesList;

  if (zonesListAutoComplete.length) {
    options = zonesListAutoComplete;
  }

  return (
    <Autocomplete
      multiple={!singleValue}
      disablePortal
      autoComplete
      filterSelectedOptions
      name="zones"
      onChange={(_, v) => onChange(v)}
      onInputChange={(event, newInputValue) => {
        if (singleValue || !overNetwork) {
          return;
        }
        debouncedFetch(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      filterOptions={overNetwork ? (x) => x : undefined}
      getOptionLabel={(option) => option.name?.en}
      options={options}
      value={value}
      defaultValue={defaultValue}
      loading={isAutoCompleteLoading}
    />
  );
}
