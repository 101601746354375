import {
  Box,
  Chip,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs
} from '@material-ui/core';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNewOrders,
  selectNewOrdersState
} from 'src/store/slices/vendorsOrders';
import {
  getAreaZonesAutoComplete,
  resetAreaZonesAutoComplete
} from 'src/store/slices/zones';
import { MCircularProgress } from 'src/theme';
import { localize } from 'src/utils/localize';
import { NEW_ORDERS_TABLE_HEAD } from '../constants';
import { OrderRow } from './OrderRow';
import { TableHead } from './TableHead';

export default function NewOrdersTable({ onInfoClick, onCommentClick }) {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState();
  const [areaTabs, setAreaTabs] = useState([]);
  const [areasOrdersCount, setAreasOrdersCount] = useState({});

  const { zonesListAutoComplete, isAutoCompleteLoading } = useSelector(
    (state) => state.zone
  );
  const { orders, loading } = useSelector(selectNewOrdersState);

  useEffect(() => {
    const areasOrdersCount = {};

    zonesListAutoComplete.forEach((zone) => {
      areasOrdersCount[zone._id] = orders.filter(
        (order) => order.pickUpZone._id === zone._id
      ).length;
    });

    setAreasOrdersCount(areasOrdersCount);
  }, [orders, zonesListAutoComplete]);

  useEffect(() => {
    const tabs = [
      {
        index: 0,
        area: null,
        value: null
      }
    ];

    zonesListAutoComplete.forEach((area, index) => {
      tabs.push({
        index: index + 1,
        area,
        value: area._id
      });
    });

    if (!currentTab) {
      setCurrentTab(tabs[0]);
    }

    setAreaTabs(tabs);
  }, [currentTab, zonesListAutoComplete]);

  useEffect(() => {
    getOrders();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAreaZonesAutoComplete({ active: 1 }));
    return () => dispatch(resetAreaZonesAutoComplete());
  }, [dispatch]);

  const getOrders = () => {
    dispatch(getNewOrders());
  };

  // const onZonesChange = useCallback(
  //   (zones) => {
  //     dispatch(
  //       getNewOrders({
  //         filteredZones: zones.map((z) => z._id)
  //       })
  //     );
  //   },
  //   [dispatch]
  // );

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(areaTabs.find((t) => t.value === newValue));
  };

  return (
    <Box p="25px" display="flex" flexDirection="column" gap="25px">
      {/* <ZonesFilter preload={false} label="Areas" onChange={onZonesChange} /> */}
      <Tabs
        value={currentTab?.value}
        scrollButtons="auto"
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={handleChangeTab}
        // className={classes.tabBar}
      >
        {areaTabs.map((tab) => (
          <Tab
            // disableRipple
            key={tab.value}
            label={
              <Box
                display="flex"
                gap="8px"
                alignItems="center"
                key={areasOrdersCount[tab.value] || 'all' + tab.value}
                borderRadius="8px"
                component={motion.div}
                animate={{
                  backgroundColor: [
                    '#FFDF00',
                    '#ffffff',
                    '#FFDF00',
                    '#ffffff',
                    '#FFDF00',
                    '#ffffff'
                  ]
                }}
              >
                {/*  */}
                <span>
                  {tab.area
                    ? localize(tab.area.name.en, tab.area.name.ar)
                    : 'All'}
                </span>
                <Chip
                  label={
                    tab.area
                      ? areasOrdersCount[tab.area._id]
                      : Object.values(areasOrdersCount).reduce(
                          (acc, curr) => (acc += curr),
                          0
                        )
                  }
                />
              </Box>
            }
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
          // transition={{ ease: 'anticipate', duration: 0.6 }}
          key={currentTab?.value || 'all'}
        >
          <OrdersTable
            areaId={currentTab?.value}
            orders={orders}
            onInfoClick={onInfoClick}
            onCommentClick={onCommentClick}
            loading={loading}
          />
        </motion.div>
      </AnimatePresence>
    </Box>
  );
}

function OrdersTable({ areaId, orders, loading, onInfoClick, onCommentClick }) {
  const filteredOrders = useMemo(() => {
    if (!areaId) {
      return orders;
    }
    return orders.filter((order) => order.pickUpZone._id === areaId);
  }, [areaId, orders]);

  return (
    <TableContainer
      sx={{
        whiteSpace: 'nowrap'
      }}
      component={Paper}
    >
      <Table aria-label="sticky table" stickyHeader>
        <TableHead />
        {loading ? (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={NEW_ORDERS_TABLE_HEAD.length}>
                <Box
                  sx={{
                    py: 3
                  }}
                >
                  <MCircularProgress color="info" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {filteredOrders.map((order) => (
              <AnimatePresence exitBeforeEnter>
                <OrderRow
                  key={order._id}
                  order={order}
                  onInfoClick={onInfoClick}
                  onCommentClick={onCommentClick}
                />
              </AnimatePresence>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
