import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isTypesLoading: false,
  typesError: false,
  typesList: [],
  typesMap: {}
};

const slice = createSlice({
  name: 'userTypes',
  initialState,
  reducers: {
    startTypesLoading(state) {
      state.isTypesLoading = true;
    },

    typesHasError(state, action) {
      state.isTypesLoading = false;
      state.typesError = action.payload;
    },

    getTypesListSuccess(state, action) {
      state.typesList = action.payload;
      state.typesMap = action.payload.reduce((map, item) => {
        map[item.name.en] = item;
        return map;
      }, {});
      state.isTypesLoading = false;
    }
  }
});

export default slice.reducer;

export function getTypes() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startTypesLoading());
      const response = await apiInstance.get('/usersTypes');
      dispatch(slice.actions.getTypesListSuccess(response.data.data.types));
    } catch (error) {
      dispatch(slice.actions.typesHasError(error));
    }
  };
}
