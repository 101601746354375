export const resources = {
  admins: 'admins',
  activity: 'activity',
  appSettings: 'app settings',
  areas: 'areas',
  branches: 'branches',
  brands: 'brands',
  premiumBrands: 'premiumBrands',
  categories: 'categories',
  cities: 'cities',
  contactUs: 'contact us',
  customers: 'customers',
  drivers: 'drivers',
  driversTv: 'driverTv',
  driverRequests: 'driver requests',
  driverSalary: 'salaries',
  notifications: 'notifications',
  deeplinks: 'deep-link-urls',
  offersLimits: 'offers limits',
  orders: 'orders',
  unhandledOrders: 'unhandled orders',
  orderCancellationReasons: 'order cancellation reasons',
  orderRejectionReasons: 'order-rejection-reasons',
  problems: 'problems',
  problemTypes: 'problem types',
  promocodes: 'promocodes',
  profile: 'profile',
  resources: 'resources',
  roles: 'roles',
  smsHistory: 'sms history',
  tags: 'tags',
  transactions: 'transactions',
  userComments: 'user comments',
  vehicleTypes: 'vehicle types',
  vendors: 'vendors',
  zones: 'zones',
  outOfCoverageAreas: 'out of coverage',
  ads: 'advertisements',
  driverTypes: 'driver types',
  exportJobs: 'export-jobs'
};
export const actions = {
  view: 'View',
  add: 'Add',
  delete: 'Delete',
  update: 'Update'
};

export const menus = {
  users: [
    resources.drivers,
    resources.customers,
    resources.admins,
    resources.vendors
  ],
  settings: [
    resources.appSettings,
    resources.problemTypes,
    resources.vehicleTypes,
    resources.orderCancellationReasons,
    resources.offersLimits
  ],
  reports: [resources.orders]
};

export const isAuthorized = (permissionList, route, action) => {
  const rolePermissions = permissionList[route];

  // if (!rolePermissions) {
  //   return true;
  // }
  if (
    !rolePermissions?.includes('All') &&
    !rolePermissions?.includes(action) &&
    route != null
  ) {
    return false;
  }
  return true;
};

export const displayMenu = (permissionList, menuName = '') => {
  const menuList = menus[menuName] || [];
  let cond = false;
  for (let i = 0; i < menuList.length; i++) {
    const rolePermissions = permissionList[menuList[i]] || [];
    if (
      rolePermissions.includes('All') ||
      rolePermissions.includes(actions.view)
      // || !permissionList[menuList[i]]
    ) {
      cond = true;
      break;
    }
  }
  return cond;
};
