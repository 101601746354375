export const paginationUtils = {
  initialState: {
    count: 0,
    page: 0,
    perPage: 10
  },
  reducers: {
    setPage(state, action) {
      state.page = action.payload;
    },
    setPerPage(state, action) {
      state.perPage = action.payload;
    }
  },
  onPageChange(dispatch, action, ...restArgs) {
    return (_, page) => {
      dispatch(action(page, ...restArgs));
    };
  },
  onPerPageChange(dispatch, action, ...restArgs) {
    return (e) => {
      dispatch(action(e.target.value, ...restArgs));
    };
  }
};
