import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  contactUsList: [],
  count: 0,
  contactUs: {},
  contactUsSuccessMessage: '',
  isStatusLoading: false,
  statusError: false,
  statusList: []
};

const slice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getContactUsSuccess(state, action) {
      state.contactUsList = action.payload.contactUsList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    getContactUsInfoSuccess(state, action) {
      state.contactUs = action.payload;
      state.isLoading = false;
    },

    startAddCommentLoading(state, action) {
      state.contactUs.comments.unshift(action.payload);
    },

    contactUsSuccess(state, action) {
      state.contactUsSuccessMessage = action.payload.message;
      // state.isLoading = false;
    },

    startStatusLoading(state) {
      state.isStatusLoading = true;
    },

    statusSuccess(state, action) {
      state.statusList = action.payload;
      state.isStatusLoading = false;
    },

    statusHasError(state, action) {
      state.isStatusLoading = false;
      state.statusError = action.payload;
    },
    startEditLoading(state, action) {
      state.contactUs.status = state.statusList?.find(
        (s) => s._id == action.payload?.body?.status
      );
    }
  }
});

export default slice.reducer;

export function getContactUsList(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/contactUs/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getContactUsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getContactUsById(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/contactUs/${id}`);
      dispatch(
        slice.actions.getContactUsInfoSuccess(response.data.data.contactUs)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addContactUsComment(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startAddCommentLoading(body));
      const response = await apiInstance.post(`/contactUs/${id}/comment`, body);
      dispatch(slice.actions.contactUsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContactUsStatuses() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startStatusLoading());
      const response = await apiInstance.get(`/contactUsStatus`);
      dispatch(slice.actions.statusSuccess(response.data.data.statusList));
    } catch (error) {
      dispatch(slice.actions.statusHasError(error));
    }
  };
}

export function editContactUsById(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditLoading({ id, body }));
      const response = await apiInstance.put(`/contactUs/${id}`, body);
      dispatch(slice.actions.contactUsSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
