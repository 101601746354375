import { useCallback, useEffect } from 'react';
import { socket } from 'src/utils/socket';
import useAuth from './useAuth';
import { debug } from '../utils/debug';

export function useSocketListener({ room, event, listener }) {
  const { isAuthenticated } = useAuth();

  const onNetworkOnline = () => {
    socket.connect();
  };

  const onNetworkOffline = () => {
    socket.disconnect();
    socket.close();
  };

  useEffect(() => {
    window.addEventListener('online', onNetworkOnline);
    window.addEventListener('offline', onNetworkOffline);

    return () => {
      window.removeEventListener('online', onNetworkOnline);
      window.removeEventListener('offline', onNetworkOffline);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      socket.disconnect();
      return;
    }

    socket.initialize();

    socket.on('connect', () => {
      debug.log('Socket disconnected!');
      socket.emit('join-room', { room });
      socket.on(event, listener);
    });

    socket.on('disconnect', () => {
      socket.off(event, listener);
    });

    socket.connect();

    return () => {
      debug.warn('Socket disconnected');
      socket.off(event, listener);
      socket.disconnect();
    };
  }, [isAuthenticated]);
}
