import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  orderComments: [],
  isLoading: false,
  count: 0,
  isAddLoading: false
};

const slice = createSlice({
  name: 'orderComments',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.orderComments = [];
    },

    getHasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getOrderCommentsSuccess(state, action) {
      state.orderComments = action.payload.comments;
      state.count = action.payload.count;
      state.isLoading = false;
    },
    startAddCommentLoading(state, action) {
      state.isAddLoading = true;
      state.orderComments.unshift(action.payload);
    },

    addCommentHasError(state, action) {
      state.isAddLoading = false;
      state.addError = action.payload;
    }
  }
});

export default slice.reducer;

export function getOrderComments(orderId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/orders/${orderId}/comments`);
      dispatch(slice.actions.getOrderCommentsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.getHasError(error));
    }
  };
}

export function addOrderComments(orderId, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startAddCommentLoading(body));
      await apiInstance.post(`/orders/${orderId}/comments`, body);
      dispatch(getOrderComments(orderId));
    } catch (error) {
      dispatch(slice.actions.addCommentHasError(error));
    }
  };
}
