import { createSlice } from '@reduxjs/toolkit';
import apiInstance, { v2ApiInstance } from 'src/utils/api';

const initialState = {
  isLoading: false,
  isFormLoading: false,
  isDeletingAllMenu: false,
  error: false,
  brandsList: [],
  brandSuccessMessage: '',
  count: 0,
  brand: {},
  isBrandsAutoCompleteLoading: false,
  brandsListAutoComplete: [],

  brandBills: [],
  brandBillsCount: 0,
  brandBillsLoading: false,

  brandBillsDueAmount: 0,
  brandBillsDueAmountLoading: false
};

const slice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setFormLoading(state, action) {
      state.isFormLoading = action.payload;
    },

    startBrandAutoCompleteLoading(state) {
      state.isBrandsAutoCompleteLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getBrandsSuccess(state, action) {
      state.brandsList = action.payload.brandsList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    brandSuccess(state, action) {
      state.brandSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
      // state.isLoading = false;
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status !== undefined) {
        state.brandsList = state.brandsList.map((t) => {
          if (t._id === action.payload?.id) {
            t.active = action.payload?.status;
          }
          return t;
        });
      }
    },

    startDeleteLoading(state, action) {
      state.brandsList = state.brandsList.filter(
        (t) => t._id !== action.payload
      );
      state.count -= 1;
    },

    startDeleteTag(state, action) {
      state.brand.tags = state.brand.tags.filter(
        (t) => t._id !== action.payload.tagId
      );
    },

    getBrandSuccess(state, action) {
      state.brand = action.payload;
      state.isLoading = false;
    },
    startAddMenu(state, action) {
      // state.isLoading = true;
      if (state.brand.menus?.length) {
        state.brand.menus.push(action.payload.menu);
      } else {
        state.brand.menus = [action.payload.menu];
      }
    },
    getBrandsAutoCompleteSuccess(state, action) {
      state.brandsListAutoComplete = action.payload.brands;
      state.isBrandsAutoCompleteLoading = false;
    },
    startDeleteMenu(state, action) {
      state.brand.menus = state.brand.menus?.filter(
        (m) => m !== action.payload.menu
      );
    },
    setIsDeletingAllMenu(state, action) {
      state.isDeletingAllMenu = action.payload;
    },
    resetBrandsAutoComplete(state) {
      state.brandsListAutoComplete = [];
    },
    resetBrands(state) {
      state.brandsList = [];
    },

    getBrandBillsLoading(state, action) {
      state.brandBillsLoading = action.payload;
    },
    getBrandBillsSuccess(state, action) {
      state.brandBills = action.payload.data;
      state.brandBillsCount = action.payload.pagination.pagesCount;
      state.brandBillsLoading = false;
    },
    getBrandBillsDueAmountLoading(state, action) {
      state.brandBillsDueAmountLoading = action.payload;
    },
    getBrandBillsDueAmountSuccess(state, action) {
      state.brandBillsDueAmount = action.payload.amount;
      state.brandBillsDueAmountLoading = false;
    }
  }
});

export default slice.reducer;

export function getBrandsList({
  categories,
  pageNumber,
  pageSize,
  // isVendor = false,
  searchText = null,
  isActive,
  branchesType = undefined
}) {
  return async (dispatch) => {
    try {
      console.log(
        '[[[Brands - store]]] - filter values:',
        categories,
        searchText,
        isActive
      );
      dispatch(slice.actions.resetBrands());
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(
        `/brands/${pageNumber}/${pageSize}`,
        {
          searchText: searchText,
          active: typeof isActive === 'boolean' ? isActive : undefined,
          categories: categories,
          branchesType
        }
      );
      dispatch(slice.actions.getBrandsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addBrand(body, events) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setFormLoading(true));
      await apiInstance.post('/brands', body, {
        toastMessage: 'Brand is added successfuly'
      });
      if (events?.onSuccess) {
        console.log('[[[Brands Store]]] - update, check evets');
        events.onSuccess();
      }
      // dispatch(slice.actions.brandSuccess(response.data.data));
    } catch (error) {
      if (events?.onFail) {
        events.onFail(error);
      }
      dispatch(slice.actions.hasError(error));
    } finally {
      dispatch(slice.actions.setFormLoading(false));
    }
  };
}

export function editBrand(id, body, events) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setFormLoading(true));
      await apiInstance.put(`/brands/${id}`, body, {
        toastMessage: 'Brand is updated successfully'
      });
      if (events?.onSuccess) {
        events.onSuccess();
      }
      // dispatch(slice.actions.brandSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (events?.onFail) {
        events.onFail(error);
      }
    } finally {
      dispatch(slice.actions.setFormLoading(false));
    }
  };
}

export function editBrandStatus(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(`/brands/${id}`, {
        active: status
      });
      dispatch(slice.actions.brandSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteBrand(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/brands/${id}`);
      dispatch(slice.actions.brandSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteBrandTag(brandId, tagId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteTag({ tagId }));
      const response = await apiInstance.put(`/brands/tags/${brandId}`, {
        tagId: tagId
      });
      dispatch(slice.actions.brandSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrandById(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/brands/${id}`);
      console.log(
        '[[[Get Brand Info]]] - Response data:',
        response.data.data.brand
      );
      dispatch(slice.actions.getBrandSuccess(response.data.data.brand));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addBrandMenu(brandId, menu) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startAddMenu({ menu }));
      const response = await apiInstance.post(
        `/brands/menus/${brandId}`,
        {
          menu: menu
        },
        {
          toastMessage: 'Menu is added successfully'
        }
      );
      dispatch(slice.actions.brandSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteBrandMenu(brandId, menu) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteMenu({ menu }));
      const response = await apiInstance.put(`/brands/menus/${brandId}`, {
        menu: menu
      });
      dispatch(slice.actions.brandSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAllBrandMenu(brandId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setIsDeletingAllMenu(true));
      await apiInstance.delete(`/brands/menus/${brandId}/delete`);
      dispatch(slice.actions.setIsDeletingAllMenu(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrandsListAutoComplete(filter = { name: '' }) {
  return async (dispatch) => {
    try {
      dispatch(resetBrandsAutoComplete());
      dispatch(slice.actions.startBrandAutoCompleteLoading());
      const response = await apiInstance.post(`/brands/autoComplete`, filter);
      console.log(
        '[[[Brands APIs]]] - Fetch brands autocomplete list:',
        response.data.data
      );
      dispatch(slice.actions.getBrandsAutoCompleteSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetBrandsAutoComplete() {
  return async (dispatch) => {
    dispatch(slice.actions.resetBrandsAutoComplete());
  };
}

export function getBrandBills(brandId, params) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getBrandBillsLoading(true));
      const response = await v2ApiInstance.get(
        `/v1/admin/bills/brand/${brandId}`,
        {
          params
        }
      );
      dispatch(slice.actions.getBrandBillsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getBrandBillsLoading(false));
    }
  };
}

export function getBrandBillsDueAmount(brandId, params) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getBrandBillsDueAmountLoading(true));
      const response = await v2ApiInstance.get(
        `/v1/admin/bills/brand/${brandId}/due-amount`,
        {
          params
        }
      );
      dispatch(slice.actions.getBrandBillsDueAmountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.getBrandBillsDueLoading(false));
    }
  };
}
