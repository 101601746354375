import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  itemsList: [],
  item: {},
  count: 0,
  itemSuccessMessage: ''
};

const slice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getItemsSuccess(state, action) {
      state.itemsList = action.payload.itemsList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    itemSuccess(state, action) {
      state.itemSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status != undefined) {
        state.itemsList = state.itemsList.map((t) => {
          if (t._id == action.payload?.id) {
            t.active = action.payload?.status;
          }
          return t;
        });
      }
    },

    startDeleteLoading(state, action) {
      state.itemsList = state.itemsList.filter((t) => t._id != action.payload);
      state.count -= 1;
    },

    getItemInfoSuccess(state, action) {
      state.item = action.payload;
      state.isLoading = false;
    },
    startUpdateExtraLoading(state, action) {
      if (action.payload?.status != undefined) {
        state.item.extras = state.item.extras.map((t) => {
          if (t.extra._id == action.payload?.id) {
            t.extra.active = action.payload?.status;
          }
          return t;
        });
      }
    },

    startUpdateChoiceLoading(state, action) {
      if (action.payload?.status != undefined) {
        state.item.extras = state.item.extras.map((t) => {
          t.choices = t.choices.map((choice) => {
            if (choice._id == action.payload?.id) {
              choice.active = action.payload?.status;
            }
            return choice;
          });
          return t;
        });
      }
    },

    startDeleteExtraLoading(state, action) {
      state.item.extras = state.item.extras.filter(
        (t) => t.extra._id != action.payload?.extraId
      );
    },

    startDeleteChoiceLoading(state, action) {
      state.item.extras = state.item.extras.map((t) => {
        if (t.extra._id == action.payload?.extraId) {
          t.choices = t.choices.filter(
            (c) => c._id != action.payload?.choiceId
          );
        }
        return t;
      });
    }
  }
});

export default slice.reducer;

export function getItemsList(brandId, pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/items/${brandId}/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getItemsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function geItemById(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/items/${id}`);
      dispatch(slice.actions.getItemInfoSuccess(response.data.data.item));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addItem(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post('/items', body, {
        toastMessage: 'Item is added successfuly'
      });
      dispatch(slice.actions.itemSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editItem(id, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/items/${id}`, body, {
        toastMessage: 'Item is updated successfuly'
      });
      dispatch(slice.actions.itemSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editItemStatus(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(`/items/${id}`, {
        active: status
      });
      dispatch(slice.actions.itemSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteItem(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/items/${id}`);
      dispatch(slice.actions.itemSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateItemExtraStatus(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startUpdateExtraLoading({ id, status }));
      const response = await apiInstance.put(`/itemExtras/${id}`, {
        active: status
      });
      dispatch(slice.actions.itemSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteItemExtra(itemId, extraId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteExtraLoading({ extraId }));
      const response = await apiInstance.put(`/itemExtras/delete/${extraId}`, {
        itemId
      });
      dispatch(slice.actions.itemSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateItemChoiceStatus(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startUpdateChoiceLoading({ id, status }));
      const response = await apiInstance.put(`/itemExtraChoices/${id}`, {
        active: status
      });
      dispatch(slice.actions.itemSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteItemChoice(itemId, extraId, choiceId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteChoiceLoading({ choiceId, extraId }));
      const response = await apiInstance.put(
        `/itemExtraChoices/delete/${choiceId}`,
        {
          itemId,
          extraId
        }
      );
      dispatch(slice.actions.itemSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
