import { EventEmitter } from 'events';
import { useEffect } from 'react';

export const Events = {
  Orders: {
    OrderStatusUpdated: 'order_status_updated',
    OrderDriverUpdated: 'order_driver_updated',
    OrderChanged: 'order_changed',
    ScheduledOrderChanged: 'scheduled_order_changed'
  },
  Audio: {
    Play: 'audio:play',
    Pause: 'audio:pause'
  }
};

export const eventsManager = new EventEmitter();

export const useEvent = (eventName, handler) => {
  useEffect(() => {
    eventsManager.on(eventName, handler);
    return () => eventsManager.off(eventName, handler);
  }, []);
};
