import { createSlice } from '@reduxjs/toolkit';
import { get, last } from 'lodash';
import apiInstance from 'src/utils/api';
import { Events, eventsManager } from 'src/utils/eventManager';
import { store } from '../store';

export const SLICE_NAME = 'ramadanOrders';

export const SubState = {
  ScheduledOrders: 'scheduledOrders',
  OnGoingOrders: 'onGoingOrders'
};

const initialState = {
  selectedOrder: null,
  [SubState.ScheduledOrders]: {
    loading: false,
    page: 1,
    count: 0,
    pages: 0,
    orders: [],
    error: null
  },
  [SubState.OnGoingOrders]: {
    loading: false,
    count: 0,
    pages: 0,
    orders: []
  }
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    addScheduledOrder(state, { payload }) {
      const order = payload;
      let orders = state[SubState.ScheduledOrders].orders;

      orders = orders.filter((o) => o._id !== order._id);
      orders.unshift({ ...order, __highlight: true });
      state[SubState.ScheduledOrders].orders = orders;
    },
    scheduledOrdersLoading(state, { payload }) {
      state[SubState.ScheduledOrders].loading = payload;
    },
    onGoingOrdersLoading(state, { payload }) {
      state[SubState.OnGoingOrders].loading = payload;
    },
    getScheduledOrdersSuccess(state, { payload }) {
      state[SubState.ScheduledOrders].orders = payload.orders;
      state[SubState.ScheduledOrders].count = payload.count;
      state[SubState.ScheduledOrders].pages = payload.pages;
      state[SubState.ScheduledOrders].loading = false;
    },
    getOnGoingOrdersSuccess(state, { payload }) {
      state[SubState.OnGoingOrders].orders = payload.orders;
      state[SubState.OnGoingOrders].count = payload.count;
      state[SubState.OnGoingOrders].pages = payload.pages;
      state[SubState.OnGoingOrders].loading = false;
    },
    scheduledOrdersHasError(state, { payload }) {
      console.error(payload);
      state[SubState.ScheduledOrders].error = payload;
      state[SubState.ScheduledOrders].loading = false;
    },
    onGoingOrdersHasError(state, { payload }) {
      console.error(payload);
      state[SubState.OnGoingOrders].error = payload;
      state[SubState.OnGoingOrders].loading = false;
    },
    selectScheduledOrder(state, { payload }) {
      state.selectedOrder = payload;
    },
    setRamadanOrdersPage(state, { payload }) {
      state[SubState.ScheduledOrders].page = payload;
    },
    handleOrderChanged(state, { payload }) {
      const order = payload.order;
      const scheduledOrders = state[SubState.ScheduledOrders].orders;
      const onGoingOrders = state[SubState.OnGoingOrders].orders;
      const suspendedStatuses = [
        'EXPIRED',
        'REJECTED_BY_BRANCH',
        'CANCELLED_BY_CUSTOMER',
        'CANCELLED_BY_OPERATOR',
        'FINISHED'
      ];

      if (!order || !order.scheduled) {
        console.info(
          `Skipping non-scheduled order event (order id: ${order?._id})`
        );
        return;
      }

      const scheduledOrderIndex = scheduledOrders.findIndex(
        (o) => o._id === order?._id
      );
      const onGoingOrderIndex = onGoingOrders.findIndex(
        (o) => o._id === order._id
      );

      if (scheduledOrderIndex !== -1) {
        state[SubState.ScheduledOrders].orders.splice(scheduledOrderIndex, 1);
        const status = get(last(order.status), 'key');

        order.__highlight = true;

        if (suspendedStatuses.includes(status)) {
          state[SubState.OnGoingOrders].orders.unshift(order);
          return;
        }

        state[
          order.driver ? SubState.OnGoingOrders : SubState.ScheduledOrders
        ].orders.unshift(order);
      }

      if (onGoingOrderIndex !== -1) {
        state[SubState.OnGoingOrders].orders.splice(
          onGoingOrderIndex,
          1,
          order
        );
      }
    }
  }
});

eventsManager.on(Events.Orders.ScheduledOrderChanged, (order) => {
  store.dispatch(slice.actions.handleOrderChanged({ order }));
});

export default slice.reducer;
export const selectScheduledOrdersState = (state) =>
  state[SLICE_NAME][SubState.ScheduledOrders];
export const selectOnGoingOrdersState = (state) =>
  state[SLICE_NAME][SubState.OnGoingOrders];

export function addScheduledOrder(order) {
  return async (dispatch, getState) => {
    if (getState()[SLICE_NAME][SubState.ScheduledOrders].page === 1) {
      dispatch(slice.actions.addScheduledOrder(order));
    }
  };
}

export function getScheduledOrders(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.scheduledOrdersLoading(true));

      const response = await apiInstance.post('/orders', {
        ...body,
        orderType: ['PURCHASE', 'GENERAL_SHOP', 'VENDOR', 'PACKAGE'],
        scheduled: true
      });
      dispatch(slice.actions.getScheduledOrdersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.scheduledOrdersHasError(error));
    }
  };
}

export function getOnGoingOrders(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.onGoingOrdersLoading(true));
      const response = await apiInstance.post('/orders', {
        ...body,
        scheduled: true,
        status: ['SCHEDULED']
      });
      dispatch(slice.actions.getOnGoingOrdersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.onGoingOrdersHasError(error));
    }
  };
}

export function selectRamadanOrder(order) {
  return async (dispatch) => {
    dispatch(slice.actions.selectScheduledOrder(order));
  };
}

export function setRamadanOrdersPage(page) {
  return async (dispatch) => {
    dispatch(slice.actions.setRamadanOrdersPage(page));
  };
}
