import { Box, Drawer } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page';
import { HeaderDashboard } from 'src/layouts/Common';
import { PATH_APP } from 'src/routes/paths';
import NewOrdersTable from './NewOrdersTable';
import OnGoingOrdersTable from './OnGoingOrdersTable';
import { useVendorsOrdersStyles } from './constants';
import { useCallback, useState } from 'react';
import VendorOrderDetails from './VendorOrderDetails';
import { selectVendorOrder } from 'src/store/slices/vendorsOrders';
import { useDispatch, useSelector } from 'react-redux';
import { OrderCommentsDrawer } from '../orders/OrderListView/OrderCommentsDialog';
import { Events, useEvent } from 'src/utils/eventManager';

const useDrawerState = () => {
  const [open, setOpen] = useState(false);

  const handleCloseDrawer = useCallback(() => setOpen(false), []);
  const handleOpenDrawer = useCallback(() => setOpen(true), []);

  return { open, handleCloseDrawer, handleOpenDrawer };
};

export default function VendorsOrdersView() {
  const { t } = useTranslation();
  const classes = useVendorsOrdersStyles();
  const dispatch = useDispatch();
  const [showOrderComments, setShowOrderComments] = useState(false);
  const { open, handleCloseDrawer, handleOpenDrawer } = useDrawerState();

  const { selectedOrder } = useSelector((state) => state.vendorsOrders);

  useEvent(Events.Orders.OrderStatusUpdated, () => {
    handleCloseDrawer();
  });

  useEvent(Events.Orders.OrderDriverUpdated, () => {
    hideOrderDetails();
  });

  const showOrderDetails = (order) => {
    dispatch(selectVendorOrder(order));
    handleOpenDrawer();
  };

  const hideOrderDetails = () => {
    selectVendorOrder(null);
    handleCloseDrawer();
  };

  const showComments = (order) => {
    dispatch(selectVendorOrder(order));
    setShowOrderComments(true);
  };

  const hideComments = (order) => {
    dispatch(selectVendorOrder(null));
    setShowOrderComments(false);
  };

  return (
    <Page
      sx={{ width: '100%' }}
      title="Vendors Orders"
      className={classes.root}
    >
      <HeaderDashboard
        heading="Vendors Orders"
        links={[
          { name: t('navitems.dashboard'), href: PATH_APP.root },
          {
            name: 'Vendors Orders'
          }
        ]}
      />

      <Box>
        <NewOrdersTable
          onInfoClick={showOrderDetails}
          onCommentClick={showComments}
        />
      </Box>

      <Box>
        <OnGoingOrdersTable
          onInfoClick={showOrderDetails}
          onCommentClick={showComments}
        />
      </Box>

      <OrderCommentsDrawer
        open={showOrderComments}
        onClose={hideComments}
        order={selectedOrder}
      />

      <Drawer
        open={open}
        anchor="right"
        onClose={hideOrderDetails}
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper
        }}
      >
        <VendorOrderDetails />
      </Drawer>
    </Page>
  );
}
