import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';
import FormData from 'form-data';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify-icons/eva/search-fill';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  TextField,
  Autocomplete,
  IconButton,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { localize } from 'src/utils/localize';
import _, { debounce } from 'lodash';
import apiInstance, { v2ApiInstance } from 'src/utils/api';
import { toast } from 'react-toastify';
import { ToastifyManager } from 'src/utils/toastifyManager';
import { DownloadExcelButton } from 'src/components/Buttons/DownloadExcelButton';
import { SearchTextFilter } from 'src/components/Filters/SearchTextFilter';
import {
  ShopTypeFilter,
  ShopTypeOptions
} from 'src/components/Filters/ShopTypeFilter';
import { TimeSelect } from 'src/components/TimeSelect';
import { Pages } from 'src/constants';
import { GenericFilter } from 'src/components/Filters/GenericFilter';
import { BrandsFilter } from 'src/components/Filters/BrandsFilter';
import { DriverFilter } from 'src/components/Filters/DriverFilter';
import { CustomerFilter } from 'src/components/Filters/CustomerFilter';
import { PromoCodeFilter } from 'src/components/Filters/PromoCodeFilter';
import { ZonesFilter } from 'src/components/Filters/ZonesFilter';

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === 'light';
  return {
    root: {
      // height: 96,
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 3)
    },
    search: {
      width: '100%',
      transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
      }),
      '&.Mui-focused': {
        // width: 320,
        boxShadow: theme.shadows[25].z8
      },
      '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
      }
    },
    highlight: isLight
      ? {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.lighter
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        },
    datePicker: {
      marginTop: 0,
      marginBottom: 0,
      // marginLeft: '10px',
      width: '100%'
    },
    searchIcon: {
      display: 'flex',
      alignItems: 'center'
    },
    filterBtn: {
      whiteSpace: 'nowrap'
    }
  };
});

// ----------------------------------------------------------------------

ToolbarTable.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

function ToolbarTable({
  typeFilter,
  onSearchTextChange,
  shopType,
  onShopTypeChange,
  selectedToDate,
  selectedFromDate,
  handleFromDateChange,
  handleToDateChange,
  addFilterStatus,
  filteredStatus = [],
  handleRemoveStatus,
  handleSelectedPromoChange,
  handleTypeChange,
  selectedPromo,
  getOrdersList,
  handleRemoveZone,
  addFilterZone,
  filteredZones,
  clearFilters,
  getOrderFilters,
  onCustomerChange,
  onDriverChange,
  onBrandChange,
  customer,
  driver,
  brand,
  searchRef
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [fromTime, setFromTime] = useState({ hour: '00', minute: '00' });
  const [toTime, setToTime] = useState({ hour: '00', minute: '00' });

  const [filtersValues, setFiltersValues] = useState({});
  const { statusList, isOrderStatusListLoading } = useSelector(
    (state) => state.orderStatus
  );
  const { categoriesList, isLoading } = useSelector((state) => state.category);
  // merge categories along with service types (type = category, id = categoryId, name = categoryName)

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    if (params.status) {
      const status = statusList.find(
        (s) => s.key.toLowerCase() === params.status.toLowerCase()
      );

      if (status) {
        addFilterStatus([status]);
      }
    }
  }, [statusList]);

  const types = useMemo(() => {
    const serviceTypes = [
      { type: 'service', id: 'PURCHASE', name: 'Buy Anything' },
      { type: 'service', id: 'PACKAGE', name: 'Package Delivery' }
    ];

    const categories = categoriesList.map((c) => {
      return {
        id: c._id,
        type: 'category',
        name: c.name?.en
      };
    });

    return [...serviceTypes, ...categories];
  }, [categoriesList]);
  const { promoCodesList, isPromoLoading } = useSelector(
    (state) => state.promoCode
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleFilter = (event) => {
    setFiltersValues({
      ...filtersValues,
      [event.target.name]: event.target.value
    });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetch = useCallback(
    (fn, args) => {
      dispatch(fn(...args));
    },
    [dispatch]
  );
  const debouncedFetch = useMemo(() => {
    return debounce(fetch, 500);
  }, [fetch]);

  const PureFromTimeSelect = useCallback(
    () => <TimeSelect initial={selectedFromDate} onChange={setFromTime} />,
    [selectedFromDate]
  );
  const PureToTimeSelect = useCallback(
    () => <TimeSelect initial={selectedToDate} onChange={setToTime} />,
    [selectedToDate]
  );
  const onFromDateChange = useCallback(
    (date) => {
      const hour = parseInt(fromTime.hour || '00');
      const minute = parseInt(fromTime.minute || '00');

      date.setHours(hour);
      date.setMinutes(minute);

      handleFromDateChange(date);
    },
    [fromTime]
  );
  const onToDateChange = useCallback(
    (date) => {
      const hour = parseInt(toTime.hour || '00');
      const minute = parseInt(toTime.minute || '00');

      date.setHours(hour);
      date.setMinutes(minute);

      handleToDateChange(date);
    },
    [toTime]
  );

  return (
    <Toolbar className={classes.root}>
      <Grid container spacing={1}>
        {/* <Grid item xs={12} md={4}>
          
        </Grid> */}

        {/* <Grid item xs={12} md={3}>

        </Grid> */}

        <Grid container spacing={1}>
          <Grid item xs={6} md={2}>
            <SearchTextFilter
              inputRef={searchRef}
              onChange={onSearchTextChange}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <CustomerFilter
              singleValue
              preload={false}
              value={customer}
              defaultValue={customer}
              onChange={onCustomerChange}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <DriverFilter
              singleValue
              preload={false}
              value={driver}
              defaultValue={driver}
              onChange={onDriverChange}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <BrandsFilter
              singleValue
              preload={false}
              value={brand}
              defaultValue={brand}
              onChange={onBrandChange}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <Autocomplete
              multiple
              disablePortal
              filterSelectedOptions
              name="status"
              value={filteredStatus}
              onChange={(_, v) => addFilterStatus(v)}
              renderInput={(params) => <TextField {...params} label="Status" />}
              getOptionLabel={(option) => option.name?.en}
              options={statusList}
              loading={isOrderStatusListLoading}
              defaultValue={filteredStatus}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <PromoCodeFilter
              preload={false}
              singleValue
              onChange={handleSelectedPromoChange}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <Autocomplete
              disablePortal
              filterSelectedOptions
              name="type"
              value={typeFilter}
              onChange={(_, v) => handleTypeChange(v)}
              renderInput={(params) => (
                <TextField {...params} label={t(`orders.filters.type`)} />
              )}
              getOptionLabel={(option) => option.name}
              options={types}
              loading={isLoading}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <ShopTypeFilter
              onChange={onShopTypeChange}
              defaultValue={ShopTypeOptions.All}
              value={shopType}
            />
          </Grid>

          <Grid item xs={6} md={2}>
            <ZonesFilter
              preload={false}
              label="Pickup Zone"
              onChange={addFilterZone}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <DateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="From"
                    value={selectedFromDate}
                    onChange={onFromDateChange}
                    ToolbarComponent={PureFromTimeSelect}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    className={classes.datePicker}
                    views={['month', 'year', 'date']}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <DateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="To"
                    value={selectedToDate}
                    minDate={selectedFromDate}
                    onChange={onToDateChange}
                    ToolbarComponent={PureToTimeSelect}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    className={classes.datePicker}
                    views={['month', 'year', 'date']}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={3} sm={2}>
                <DownloadExcelButton
                  id="export-orders"
                  page={Pages.Orders}
                  onClick={() => {
                    const filters = getOrderFilters();
                    const {
                      searchText,
                      orderType,
                      promoCode,
                      filteredStatus,
                      filteredZones
                    } = filters;

                    if (orderType) {
                      filters.orderTypes = [orderType];
                    }
                    if (promoCode) {
                      filters.promocodes = [promoCode];
                    }
                    if (filteredStatus) {
                      filters.status = filteredStatus;
                    }
                    if (filteredZones) {
                      filters.areaIds = filteredZones;
                    }

                    if (!searchText) {
                      delete filters.searchText;
                    }

                    delete filters.orderType;
                    delete filters.promoCode;
                    delete filters.filteredStatus;
                    delete filters.filteredZones;
                    delete filters.pageNumber;
                    delete filters.pageSize;

                    return v2ApiInstance
                      .get(`/v1/admin/orders/export`, { params: filters })
                      .then((r) => {
                        // refreshExports();
                        return r.data.exportId;
                      });

                    // apiInstance
                    //   .post(`/orders/export`, getOrderFilters())
                    //   .then((r) => r.data.exportId)
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={8}></Grid>
              <Grid item xs={3} sm={2}>
                <Box display="flex" gap="5px">
                  <Button
                    color="secondary"
                    type="submit"
                    variant="contained"
                    onClick={() => getOrdersList()}
                  >
                    {localize('Search', 'بحث', i18n.language)}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={clearFilters}
                    className={classes.filterBtn}
                  >
                    {localize('Clear all', 'مسح الكل', i18n.language)}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <div></div> */}
      {/* <div>
        <IconButton
          className={classes.searchIcon}
          // color="primary"
          aria-label="upload picture"
          component="span"
          onClick={getOrdersList}
        >
          <FindReplaceIcon />
        </IconButton>
      </div> */}
    </Toolbar>
  );
}

export default ToolbarTable;
