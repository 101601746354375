import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  areasList: [],
  areasListAutoComplete: [],
  areaSuccessMessage: '',
  count: 0,
  area: {}
};

const slice = createSlice({
  name: 'CoverageArea',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAreasSuccess(state, action) {
      state.areasList = action.payload.areasList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    areaSuccess(state, action) {
      state.areaSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
      // state.isLoading = false;
    },
    getAreasAutoCompleteSuccess(state, action) {
      state.areasListAutoComplete = action.payload.areas;
      state.isLoading = false;
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status != undefined) {
        state.areasList = state.areasList.map((t) => {
          if (t._id == action.payload?.id) {
            t.active = action.payload?.status;
          }
          return t;
        });
      }
    },

    startDeleteLoading(state, action) {
      state.areasList = state.areasList.filter((t) => t._id != action.payload);
      state.count -= 1;
    },
    getAreaSuccess(state, action) {
      state.area = action.payload;
      state.isLoading = false;
    },
    resetAreasAutoComplete(state) {
      state.areasListAutoComplete = [];
    }
  }
});

export default slice.reducer;

export function getAreasList(pageNumber, pageSize, params = {}) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/areas/${pageNumber}/${pageSize}`,
        { params }
      );
      dispatch(slice.actions.getAreasSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addArea(body) {
  return async (dispatch) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.post('/areas', body, {
        toastMessage: 'Area is added successfully'
      });
      dispatch(slice.actions.areaSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editArea(id, body) {
  return async (dispatch) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.put(`/areas/${id}`, body, {
        toastMessage: 'Area is updated successfully'
      });
      dispatch(slice.actions.areaSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// TODO as per business requirement we should not edit the area status, i will leave it until we confirm
export function editAreaStatus(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(`/areas/${id}`, {
        active: status
      });
      dispatch(slice.actions.areaSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// as per business requirement, we can't delete an area, but i will leave it here for any change
export function deleteArea(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/areas/${id}`);
      dispatch(slice.actions.areaSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAreaById(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/areas/${id}`);
      dispatch(slice.actions.getAreaSuccess(response.data.data.area));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAreasListAutoComplete(name, active) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(`/areas/autoComplete`, {
        name,
        active
      });
      dispatch(slice.actions.getAreasAutoCompleteSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetAreasAutoComplete() {
  return async (dispatch) => {
    dispatch(slice.actions.resetAreasAutoComplete());
  };
}
