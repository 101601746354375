import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP } from 'src/routes/paths';
import SaveIcon from '@material-ui/icons/Save';
import SettingsIcon from '@material-ui/icons/Settings';
import CategoryIcon from '@material-ui/icons/Category';
import TagIcon from '@material-ui/icons/Tag';
import { resources } from 'src/utils/isAuthorized';
import { LocalOffer } from '@material-ui/icons';
const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_dashboard')} />,
  user: <MIcon src={path('ic_user')} />,
  settings: <SettingsIcon />,
  save: <SaveIcon />,
  notifications: <MIcon src={path('ic_mail')} />,
  categories: <CategoryIcon />,
  promoCode: <MIcon src={path('ic_carousel')} />,
  orders: <MIcon src={path('ic_elements')} />,
  unhandledOrders: <MIcon src={path('ic_error')} />,
  coverageArea: <MIcon src={path('ic_map')} />,
  transactions: <MIcon src={path('ic_page')} />,
  problems: <MIcon src={path('ic_error')} />,
  contactUsMessages: <MIcon src={path('ic_chat')} />,
  driverRequests: <MIcon src={path('ic_blog')} />,
  driverSalary: <MIcon src={path('ic_user')} />,
  outOfCoverageAreas: <MIcon src={path('ic_authenticator')} />,
  tags: <TagIcon />,
  offer: <LocalOffer />
};

const navConfig = [
  {
    subheader: 'general',
    items: [
      // {
      //   title: 'dashboard',
      //   icon: ICONS.dashboard,
      //   href: PATH_APP.general.root,
      //   items: [
      //     {
      //       title: 'app',
      //       href: PATH_APP.general.root
      //     }
      //   ]
      // },
      {
        title: 'roles',
        icon: ICONS.settings,
        href: PATH_APP.admins.roles,
        resource: resources.roles
      },
      {
        title: 'vendorRoles',
        icon: ICONS.settings,
        href: PATH_APP.admins.vendorRoles,
        resource: resources.roles
      },
      {
        title: 'settings',
        icon: ICONS.settings,
        href: PATH_APP.settings.root,
        items: [
          {
            title: 'appSettings',
            href: PATH_APP.appSettings.root,
            resource: resources.appSettings
          },
          {
            title: 'problemTypes',
            href: PATH_APP.problemTypes.root,
            resource: resources.problemTypes
          },
          {
            title: 'vehicleTypes',
            href: PATH_APP.vehicleTypes.root,
            resource: resources.vehicleTypes
          },
          {
            title: 'driverTypes',
            href: PATH_APP.driverTypes.root,
            resource: resources.driverTypes
          },
          {
            title: 'orderCancellationReasons',
            href: PATH_APP.orderCancellationReasons.root,
            resource: resources.orderCancellationReasons
          },
          {
            title: 'orderRejectionReasons',
            href: PATH_APP.orderRejectionReasons.root,
            resource: resources.orderRejectionReasons
          },
          {
            title: 'offersLimits',
            href: PATH_APP.offersLimit.root,
            resource: resources.offersLimits
          }
        ]
      },
      {
        title: 'users',
        icon: ICONS.user,
        href: '#',
        items: [
          {
            title: 'drivers',
            href: PATH_APP.drivers.root,
            resource: resources.drivers
          },
          {
            title: 'customers',
            href: PATH_APP.customers.root,
            resource: resources.customers
          },
          {
            title: 'admins',
            href: PATH_APP.adminUsers.root,
            resource: resources.admins
          },
          {
            title: 'vendors',
            href: PATH_APP.vendors.root,
            resource: resources.vendors
          }
        ]
      },
      {
        title: 'reports',
        icon: ICONS.dashboard,
        href: '#',
        items: [
          {
            title: 'orders',
            icon: ICONS.orders,
            href: PATH_APP.reports.orders,
            resource: resources.orders
          },
          {
            title: 'offers',
            icon: ICONS.offer,
            href: PATH_APP.reports.offers,
            resource: resources.orders
          }
        ]
      },
      {
        title: 'smsHistory',
        icon: ICONS.notifications,
        href: PATH_APP.smsHistory.root,
        resource: resources.smsHistory
      },
      {
        title: 'categories',
        icon: ICONS.categories,
        href: PATH_APP.categories.root,
        resource: resources.categories
      },
      {
        title: 'brands',
        icon: ICONS.user,
        href: PATH_APP.brands.root,
        resource: resources.brands
      },
      {
        title: 'tags',
        icon: ICONS.tags,
        href: PATH_APP.tags.root,
        resource: resources.tags
      },
      {
        title: 'notifications',
        icon: ICONS.notifications,
        href: PATH_APP.notifications.root,
        resource: resources.notifications
      },
      {
        title: 'deeplinks',
        icon: ICONS.tags,
        href: PATH_APP.deeplinks.root,
        resource: resources.deeplinks
      },
      {
        title: 'promoCode',
        icon: ICONS.promoCode,
        href: PATH_APP.promoCode.root,
        resource: resources.promocodes
      },
      {
        title: 'orders',
        icon: ICONS.orders,
        href: PATH_APP.orders.root,
        resource: resources.orders
      },
      {
        title: 'vendorsOrders',
        icon: ICONS.orders,
        href: PATH_APP.vendorsOrders.root,
        resource: resources.orders
      },
      {
        title: 'ramadanOrders',
        icon: ICONS.orders,
        href: PATH_APP.ramadanOrders.root,
        resource: resources.orders
      },
      {
        title: 'unhandledOrders',
        icon: ICONS.unhandledOrders,
        resource: resources.unhandledOrders,
        href: PATH_APP.unhandledOrders.root
      },
      {
        title: 'premiumBrands',
        icon: ICONS.dashboard,
        href: PATH_APP.premiumBrands.root,
        resource: resources.premiumBrands
      },
      {
        title: 'coverageArea',
        icon: ICONS.coverageArea,
        href: PATH_APP.coverageArea.root,
        resource: resources.areas
      },
      {
        title: 'transactions',
        icon: ICONS.transactions,
        href: PATH_APP.transaction.root,
        resource: resources.transactions
      },
      {
        title: 'problems',
        icon: ICONS.problems,
        href: PATH_APP.problems.root,
        resource: resources.problems
      },
      {
        title: 'contactUsMessages',
        icon: ICONS.contactUsMessages,
        href: PATH_APP.contactUs.root,
        resource: resources.contactUs
      },
      {
        title: 'driverRequests',
        icon: ICONS.driverRequests,
        href: PATH_APP.dirverRequests.root,
        resource: resources.driverRequests
      },
      {
        title: 'driverSalary',
        icon: ICONS.driverSalary,
        href: PATH_APP.driverSalary.root,
        resource: resources.driverSalary
      },
      {
        title: 'Ads',
        icon: ICONS.transactions,
        href: PATH_APP.ads.root,
        resource: resources.ads
      },
      {
        title: 'outOfCoverageAreas',
        icon: ICONS.outOfCoverageAreas,
        href: PATH_APP.outOfCoverageArea.root,
        resource: resources.outOfCoverageAreas
      },
      {
        title: 'exportJobs',
        icon: ICONS.save,
        href: PATH_APP.exportJobs.root
        // resource: resources.exportJobs
      }
    ]
  }
];

export default navConfig;
