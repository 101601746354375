import { localize } from 'src/utils/localize';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export function calculateOrderTotalAmount(orderAmount, deliveryFees, discount) {
  orderAmount = orderAmount ?? 0;
  deliveryFees = deliveryFees ?? 0;
  discount = discount ?? 0;
  return orderAmount + (deliveryFees - discount);
}

export function feesValue(amount) {
  return amount ?? 'N/A';
}

export function feesWithInvoice(order) {
  const orderFees = _.get(order, 'fees.orderFees', 0);
  const invoiceFees = _.get(order, 'orderDetails.invoiceFees', []);
  return orderFees + _.reduce(invoiceFees, (prev, curr) => prev + curr, 0);
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}
function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function getDistanceBetTwoPoints(point1, point2) {
  if (!point1?.coordinates || !point2?.coordinates) {
    return '';
  }
  const lat1 = point1.coordinates[1];
  const lon1 = point1.coordinates[0];
  const lat2 = point2.coordinates[1];
  const lon2 = point2.coordinates[0];
  return getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2)?.toFixed(3);
}

export function calculateOrderDurationFromStatus(status) {
  if (status?.length) {
    const accetedStatus = ['ACCEPTED', 'IN_PROGRESS', 'ON_DELIVERY'];
    const acceptedIndex = status?.findIndex((s) => s.key == 'ACCEPTED');
    const newDate = new Date();

    //pending
    if (status[status.length - 1].key == 'PENDING') {
      const startDate = new Date(status[status.length - 1].at);
      const diffMs = newDate - startDate; // milliseconds between now & Christmas
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return diffHrs + ' : ' + diffMins + ' min ago';
    }
    //accepted,
    else if (
      accetedStatus.includes(status[status.length - 1].key) &&
      acceptedIndex >= 0
    ) {
      const acceptedDate = new Date(status[acceptedIndex].at);
      const diffMs = newDate - acceptedDate; // milliseconds between now & Christmas

      const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return diffHrs + ' : ' + diffMins + ' min';
    }
    //in progress, on delivery
    else if (
      accetedStatus.includes(status[status.length - 1].key) &&
      !acceptedIndex < 0
    ) {
      const acceptedDate = new Date(status[status.length - 1].at);
      const diffMs = newDate - acceptedDate; // milliseconds between now & Christmas

      const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return diffHrs + ' : ' + diffMins + ' min';
    }
    //cancelled before accept
    else if (
      status[status.length - 1].key == 'CANCELLED' &&
      acceptedIndex < 0
    ) {
      const cancelledDate = new Date(status[status.length - 1].at);
      const diffMs = newDate - cancelledDate; // milliseconds between now & Christmas
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return diffHrs + ' : ' + diffMins + ' min';
    }
    //finished or cancelled
    else if (acceptedIndex >= 0) {
      const lastAcceptedStatus = status
        ?.filter((s) => s.key == 'ACCEPTED')
        .slice(-1)[0];
      const finishedDate = new Date(status[status.length - 1].at);
      const acceptedDate = new Date(lastAcceptedStatus.at);
      const diffMs = finishedDate - acceptedDate; // milliseconds between now & Christmas
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return diffHrs + ' : ' + diffMins + ' min';
    }
    // not accepted nor cancllled yet (no driver)
    else {
      const startDate = new Date(status[0].at);
      const diffMs = newDate - startDate; // milliseconds between now & Christmas
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return diffHrs + ' : ' + diffMins + ' min';
    }
  }
  return '';
}

export function calculateDurationToBeAccepted(status) {
  if (status?.length) {
    const acceptedIndex = status?.findIndex((s) => s.key == 'ACCEPTED');
    const startOrderDate = new Date(status[0].at);
    const newDate = new Date();

    //pending
    if (status[status.length - 1].key == 'PENDING') {
      const startDate = new Date(status[status.length - 1].at);
      const diffMs = newDate - startDate; // milliseconds between now & Christmas
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return diffHrs + ' : ' + diffMins + ' min ago';
    } else if (acceptedIndex >= 0) {
      const acceptedDate = new Date(status[acceptedIndex].at);
      const diffMs = acceptedDate - startOrderDate; // milliseconds between now & Christmas
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return diffHrs + ' : ' + diffMins + ' min';
    }
  }
}
