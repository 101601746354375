import { createSlice } from '@reduxjs/toolkit';
import { de } from 'faker/lib/locales';
import apiInstance from 'src/utils/api';

import { store } from '../store';
import { paginationUtils } from './utils/pagination';

const initialState = {
  isLoading: false,
  brandsList: [],
  ...paginationUtils.initialState
};

const slice = createSlice({
  name: 'premiumBrands',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    getBrandsSuccess(state, action) {
      state.isLoading = false;
      state.brandsList = action.payload.PremiumBrandsList;
      state.count = action.payload.count;
    },

    hasError(state, action) {
      state.isLoading = false;
    },

    ...paginationUtils.reducers
  }
});

export default slice.reducer;

export function getPremiumBrandsList(filters, page, perPage) {
  return (dispatch) => {
    if (isNaN(perPage) || perPage < 1 || isNaN(page) || page < 0) return;

    // const state = store.getState().ads;
    // const { page, perPage } = state;
    const { actions } = slice;
    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .post(`/premiumBrands/${page}/${perPage}`, filters)
      .then((response) =>
        dispatch(actions.getBrandsSuccess(response.data.data))
      )
      .catch((error) => actions.hasError(error));
  };
}

export function addPremiumBrand(payload, events) {
  return (dispatch) => {
    const { actions } = slice;

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .post(`/premiumBrands`, payload, {
        toastMessage: 'Brand created successfuly'
      })
      .then(() => dispatch(getPremiumBrandsList()))
      .then(() => {
        if (events?.onSuccess) events?.onSuccess();
      })
      .catch((error) => actions.hasError(error))
      .finally(() => {
        dispatch(slice.actions.setLoading(false));
      });
  };
}

export function updatePremiumBrand(id, update, events) {
  return (dispatch) => {
    const { actions } = slice;

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .put(`/premiumBrands/${id}`, update, {
        toastMessage: 'Brand updated successfuly'
      })
      .then(() => dispatch(getPremiumBrandsList()))
      .then(() => {
        if (events?.onSuccess) events?.onSuccess();
      })
      .catch((error) => actions.hasError(error))
      .finally(() => {
        dispatch(slice.actions.setLoading(false));
      });
  };
}

export function deletePremiumBrand(id, events) {
  return (dispatch) => {
    const { actions } = slice;

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .delete(`/premiumBrands/${id}`, {
        toastMessage: 'Brand updated successfuly'
      })
      .then(() => dispatch(getPremiumBrandsList()))
      .then(() => events?.onSuccess?.())
      .catch((error) => actions.hasError(error));
  };
}

export function setPremiumBrandPage(page) {
  return (dispatch) => {
    dispatch(slice.actions.setPage(page));
    dispatch(getPremiumBrandsList());
  };
}

export function setPremiumBrandPerPage(perPage) {
  return (dispatch) => {
    dispatch(slice.actions.setPerPage(perPage));
    dispatch(getPremiumBrandsList());
  };
}
