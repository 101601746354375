import {
  Box,
  TableHead as MTableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  withStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MCircularProgress } from 'src/theme';

export function TableLoading({ colSpan }) {
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={colSpan || 5}>
          <Box
            sx={{
              py: 3
            }}
          >
            <MCircularProgress color="info" />
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export function TableHead({
  order,
  classes,
  orderBy,
  headLabel,
  onRequestSort,
  i18nNamespace,
}) {
  const { t } = useTranslation();

  return (
    <MTableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onRequestSort?.bind(null, headCell.id)}
            >
              {headCell.label && t(`${i18nNamespace}.${headCell.label}`)}
              {orderBy === headCell.id ? (
                <span className={classes.sortSpan}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MTableHead>
  );
}

// Styled
export const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: '4px !important',
    border: '1px solid #e4e4e4',
    textAlign: 'center'
  },
  head: {
    backgroundColor: theme.palette.common.green,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);
