import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  notifications: [],
  count: 0,
  notificationSuccessMessage: '',
  error: false,
  isLoading: false
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload.notifications;
      state.count = action.payload.count;
    },

    startDeleteLoading(state, action) {
      state.notifications = state.notifications.filter(
        (n) => n._id !== action.payload
      );
      state.count -= 1;
    },

    notificationSuccess(state, action) {
      state.notificationSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
    },

    markAllAsRead(state) {
      const markAll = state.notifications.map((notification) => {
        return {
          ...notification,
          isUnRead: false
        };
      });

      state.notifications = markAll;
    }
  }
});

export default slice.reducer;

export const { markAllAsRead } = slice.actions;

export function getNotifications(pageNumber, pageSize) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(
        `/notifications/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getNotificationsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteNotificationById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startDeleteLoading(id));
    try {
      const response = await apiInstance.delete(`/notifications/${id}`);
      dispatch(slice.actions.notificationSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addNotification(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post(`/notifications`, body, {
        toastMessage: 'Notification is added successfully'
      });
      dispatch(slice.actions.notificationSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editNotification(id, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/notifications/${id}`, body, {
        toastMessage: 'Notification is updated successfully'
      });
      dispatch(slice.actions.notificationSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
