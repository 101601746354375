import React, { useEffect, useMemo } from 'react';
import { Autocomplete, Box, Grid, TextField } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { localize } from 'src/utils/localize';
import { getOrderDetails, updateOrderStatus } from 'src/store/slices/order';
import { useSelector } from 'react-redux';
import { getReasonsList } from 'src/store/slices/orderCancellationReasons';
import { OrderStatusFilter } from 'src/components/Filters/OrderStatusFilter';
import _ from 'lodash';
import { CancelReasonFilter } from 'src/components/Filters/CancelReasonFilter';

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    root: {},
    blockContainer: {
      minHeight: 160,
      marginLeft: 16,
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadiusSm,
      border: `solid 1px ${theme.palette.divider}`,
      backgroundColor: theme.palette.grey[isLight ? 100 : 800],
      marginTop: 16,
      textAlign: 'center'
    },
    rolesTitle: {
      textAlign: 'left'
    },
    rolesInputsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    addRoleBtn: {
      width: 300,
      margin: '23px auto',
      padding: '5px 57.2px 7px 58px',
      borderRadius: '8px',
      border: 'solid 1px rgba(98, 137, 176, 0.32)',
      backgroundColor: '#ebf5fe'
    },
    addAdminBtn: {
      width: '100%'
    },
    errorTxt: {
      fontSize: 11,
      color: 'red'
    }
  };
});

function EditOrderStatus({ scheduledOrders, order, orderFormVisiblity }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { statusList } = useSelector((state) => state.orderStatus);
  const { reasonsList } = useSelector(
    (state) => state.orderCancellationReasons
  );
  const editStatusSchema = Yup.mixed().oneOf([
    Yup.object().shape({
      status: Yup.string().required(t('formValidation.orders.statusRequired')),
      cancelReasonId: Yup.string().required(
        t('formValidation.orders.cancelReasonRequired')
      )
    }),
    Yup.object().shape({
      status: Yup.string().required(t('formValidation.orders.statusRequired')),
      other: Yup.string().required(
        t('formValidation.orders.cancelReasonRequired')
      )
    })
  ]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: _.last(order?.status)?.key || '',
      cancelReasonId: '',
      other: ''
    },
    validationSchema: editStatusSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      const updateOrderEvents = {
        onSuccess: () => {
          setSubmitting(false);
          dispatch(getOrderDetails(order?._id));
          orderFormVisiblity(false);
        },
        onFail: (error) => {}
      };

      // What a better way to handle this :')
      if (values.cancelReasonId?._id) {
        values.cancelReasonId = values.cancelReasonId._id;
      }

      dispatch(updateOrderStatus(order?._id, values, updateOrderEvents));
    }
  });

  let {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue
  } = formik;

  useEffect(() => {
    dispatch(getReasonsList(0, 100));
  }, []);

  const reasonListGroups = useMemo(() => {
    return _.groupBy(reasonsList, 'type');
  }, [reasonsList]);

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box sx={{ my: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <OrderStatusFilter
                      scheduledOrders={scheduledOrders}
                      vendorOrders={order.orderType === 'VENDOR'}
                      defaultValue={statusList.find(
                        (v) => v.key === values.status
                      )}
                      onChange={(status) =>
                        setFieldValue('status', status?.key || '')
                      }
                    />
                  </Grid>
                  {(values.status === 'CANCELLED_BY_OPERATOR' ||
                    values.status === 'CANCELLED_BY_CUSTOMER') && (
                    <Grid item xs={12} sm={12}>
                      <CancelReasonFilter
                        error={Boolean(
                          touched.cancelReasonId && errors.cancelReasonId
                        )}
                        helperText={
                          touched.cancelReasonId && errors.cancelReasonId
                        }
                        statusKey={values.status}
                        onChange={(value) => {
                          const isObjectId = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i.test(
                            value?._id || ''
                          );

                          if (isObjectId) {
                            setFieldValue('cancelReasonId', value);
                            setFieldValue('other', '');
                            return;
                          }

                          setFieldValue('cancelReasonId', '');
                          setFieldValue('other', value);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  pending={isSubmitting}
                  className={classes.addAdminBtn}
                >
                  {localize('save', 'حفظ', i18n.language)}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </div>
  );
}

export default EditOrderStatus;
