import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  hours = hours.toString().length == 1 ? `0${hours}` : hours;
  minutes = minutes.toString().length == 1 ? `0${minutes}` : minutes;
  var strTime = hours + ':' + minutes;
  return strTime;
}

export function formatDateYYYY_MM_DD(date) {
  var d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function formateYYYY_MM_DDToDate(date) {
  return new Date(date);
}

const defaultOptions = {
  delimiter: ' : ',
  dateDelimiter: '/'
};
export function formatDateTimeAMPM(date, options = defaultOptions) {
  var d = new Date(date);

  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return (
    [day, month, year].join(options.dateDelimiter) +
    `${options.delimiter}${strTime}`
  );
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`;
}

export function formatTime(dateString) {
  const date = new Date(dateString);

  let hours = date.getHours();
  const minutes = date.getMinutes();

  const segment = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // 12hrs fmt

  const paddedHours = hours < 10 ? '0' + hours : hours;
  const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${paddedHours}:${paddedMinutes} ${segment}`;
}
