import { PATH_APP } from './paths';
import { lazy } from 'react';

import DashboardLayout from 'src/layouts/DashboardLayout';
import AuthProtect from 'src/components/Auth/AuthProtect';
import { resources } from 'src/utils/isAuthorized';
import VendorsOrdersView from 'src/views/vendorsOrders/VendorsOrdersView';

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP.general.root,
      component: lazy(() => import('src/views/general/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.general.home,
      component: lazy(() => import('src/views/general/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.admins.root,
      component: lazy(() => import('src/views/admins/AdminsListView'))
    },
    {
      exact: true,
      path: PATH_APP.admins.roles,
      component: lazy(() => import('src/views/admins/Roles')),
      resource: resources.roles
    },
    {
      exact: true,
      path: PATH_APP.admins.addRole,
      component: lazy(() => import('src/views/admins/Roles/AddRole'))
    },
    {
      exact: true,
      path: PATH_APP.admins.currentRole,
      component: lazy(() => import('src/views/admins/Roles/EditRole'))
    },
    {
      exact: true,
      path: PATH_APP.admins.vendorRoles,
      component: lazy(() => import('src/views/admins/VendorRoles')),
      resource: resources.roles
    },
    {
      exact: true,
      path: PATH_APP.admins.vendorAddRole,
      component: lazy(() => import('src/views/admins/VendorRoles/AddRole'))
    },
    {
      exact: true,
      path: PATH_APP.admins.vendorCurrentRole,
      component: lazy(() => import('src/views/admins/VendorRoles/EditRole'))
    },
    {
      exact: true,
      path: PATH_APP.profile.root,
      component: lazy(() => import('src/views/general/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.profile.account,
      component: lazy(() => import('src/views/user/AccountView'))
    },
    {
      exact: true,
      path: PATH_APP.profile.userAccount,
      component: lazy(() => import('src/views/user/UserAccountView'))
    },
    {
      exact: true,
      path: PATH_APP.reservations.hotels,
      component: lazy(() => import('src/views/reservations/hotels'))
    },
    {
      exact: true,
      path: PATH_APP.reservations.flights,
      component: lazy(() => import('src/views/reservations/flights'))
    },
    {
      exact: true,
      path: PATH_APP.settings.root,
      component: lazy(() => import('src/views/settings')),
      resource: resources.settings
    },
    {
      exact: true,
      path: PATH_APP.settings.offices,
      component: lazy(() => import('src/views/settings/offices'))
    },
    {
      exact: true,
      path: PATH_APP.settings.departments,
      component: lazy(() => import('src/views/settings/departments'))
    },
    {
      exact: true,
      path: PATH_APP.settings.reservedCountries,
      component: lazy(() => import('src/views/settings/reservedCountries'))
    },
    {
      exact: true,
      path: PATH_APP.orders.root,
      component: lazy(() => import('src/views/orders/OrderListView')),
      resource: resources.orders
    },
    {
      exact: true,
      path: PATH_APP.vendorsOrders.root,
      component: lazy(() =>
        import('src/views/vendorsOrders/VendorsOrdersView')
      ),
      resource: resources.orders
    },
    {
      exact: true,
      path: PATH_APP.ramadanOrders.root,
      component: lazy(() =>
        import('src/views/ramadanOrders/RamadanOrdersView')
      ),
      resource: resources.orders
    },
    {
      exact: true,
      path: PATH_APP.orders.orderView,
      component: lazy(() => import('src/views/orders/OrderListView/OrderView')),
      resource: resources.orders
    },
    {
      exact: true,
      path: PATH_APP.unhandledOrders.root,
      component: lazy(() =>
        import('src/views/unhandledOrders/UnhandledOrderListView')
      ),
      resource: resources.orders
    },
    {
      exact: true,
      path: PATH_APP.premiumBrands.root,
      component: lazy(() => import('src/views/PremiumBrands')),
      resource: resources.orders
    },
    {
      exact: true,
      path: PATH_APP.vehicleTypes.root,
      component: lazy(() => import('src/views/vehicleTypes')),
      resource: resources.vehicleTypes
    },
    {
      exact: true,
      path: PATH_APP.problemTypes.root,
      component: lazy(() => import('src/views/problemTypes')),
      resource: resources.problemTypes
    },
    {
      exact: true,
      path: PATH_APP.problems.root,
      component: lazy(() => import('src/views/problems')),
      resource: resources.problems
    },
    {
      exact: true,
      path: PATH_APP.problems.info,
      component: lazy(() => import('src/views/problems/ProblemInfo')),
      resource: resources.problems
    },
    {
      exact: true,
      path: PATH_APP.notifications.root,
      component: lazy(() => import('src/views/notifications')),
      resource: resources.notifications
    },
    {
      exact: true,
      path: PATH_APP.deeplinks.root,
      component: lazy(() => import('src/views/deeplinks')),
      resource: resources.deeplinks
    },
    {
      exact: true,
      path: PATH_APP.contactUs.root,
      component: lazy(() => import('src/views/contactUs')),
      resource: resources.contactUs
    },
    {
      exact: true,
      path: PATH_APP.contactUs.info,
      component: lazy(() => import('src/views/contactUs/ContactUsInfo')),
      resource: resources.contactUs
    },
    {
      exact: true,
      path: PATH_APP.dirverRequests.root,
      component: lazy(() => import('src/views/dirverRequests')),
      resource: resources.driverRequests
    },
    {
      exact: true,
      path: PATH_APP.driverSalary.root,
      component: lazy(() => import('src/views/driverSalary')),
      resource: resources.driverSalary
    },
    {
      exact: true,
      path: PATH_APP.orderCancellationReasons.root,
      component: lazy(() => import('src/views/orderCancellationReasons')),
      resource: resources.orderCancellationReasons
    },
    {
      exact: true,
      path: PATH_APP.orderRejectionReasons.root,
      component: lazy(() => import('src/views/orderRejectionReasons')),
      resource: resources.orderRejectionReasons
    },
    {
      exact: true,
      path: PATH_APP.orders.test,
      component: lazy(() =>
        import('src/views/orders/OrderListView/OrderView/test')
      )
    },
    {
      exact: true,
      path: PATH_APP.categories.root,
      component: lazy(() => import('src/views/categories')),
      resource: resources.categories
    },
    {
      exact: true,
      path: PATH_APP.brands.root,
      component: lazy(() => import('src/views/brands')),
      resource: resources.brands
    },
    {
      exact: true,
      path: PATH_APP.brands.brandInfo,
      component: lazy(() => import('src/views/brands/brandInfo')),
      resource: resources.brands
    },
    {
      exact: true,
      path: PATH_APP.item.itemInfo,
      component: lazy(() => import('src/views/brands/brandInfo/items/itemInfo'))
    },
    {
      exact: true,
      path: PATH_APP.tags.root,
      component: lazy(() => import('src/views/tags')),
      resource: resources.tags
    },
    {
      exact: true,
      path: PATH_APP.coverageArea.root,
      component: lazy(() => import('src/views/coverageAreas')),
      resource: resources.areas
    },
    {
      exact: true,
      path: PATH_APP.coverageArea.info,
      component: lazy(() => import('src/views/coverageAreas/areaInfo')),
      resource: resources.areas
    },
    {
      exact: true,
      path: PATH_APP.promoCode.root,
      component: lazy(() => import('src/views/promoCodes')),
      resource: resources.promocodes
    },
    {
      exact: true,
      path: PATH_APP.promoCode.create,
      component: lazy(() => import('src/views/promoCodes/PromoCodeForm')),
      resource: resources.promocodes
    },
    {
      exact: true,
      path: PATH_APP.promoCode.update,
      component: lazy(() => import('src/views/promoCodes/PromoCodeForm')),
      resource: resources.promocodes
    },
    {
      exact: true,
      path: PATH_APP.offersLimit.root,
      component: lazy(() => import('src/views/offersLimits')),
      resource: resources.offersLimits
    },
    {
      exact: true,
      path: PATH_APP.transaction.root,
      component: lazy(() => import('src/views/transactions')),
      resource: resources.transactions
    },
    {
      exact: true,
      path: PATH_APP.appSettings.root,
      component: lazy(() => import('src/views/appSettings')),
      resource: resources.appSettings
    },
    {
      exact: true,
      path: PATH_APP.drivers.root,
      component: lazy(() => import('src/views/users/drivers')),
      resource: resources.drivers
    },
    {
      exact: true,
      path: PATH_APP.drivers.info,
      component: lazy(() => import('src/views/users/drivers/driverInfo')),
      resource: resources.drivers
    },
    {
      exact: true,
      path: PATH_APP.customers.root,
      component: lazy(() => import('src/views/users/customers'))
    },
    {
      exact: true,
      path: PATH_APP.customers.info,
      component: lazy(() => import('src/views/users/customers/customerInfo'))
    },
    {
      exact: true,
      path: PATH_APP.adminUsers.root,
      component: lazy(() => import('src/views/users/admins'))
    },
    {
      exact: true,
      path: PATH_APP.adminUsers.info,
      component: lazy(() => import('src/views/users/admins/adminInfo'))
    },
    {
      exact: true,
      path: PATH_APP.vendors.root,
      component: lazy(() => import('src/views/users/vendors'))
    },
    {
      exact: true,
      path: PATH_APP.vendors.info,
      component: lazy(() => import('src/views/users/vendors/vendorInfo'))
    },
    {
      exact: true,
      path: PATH_APP.smsHistory.root,
      component: lazy(() => import('src/views/smsHistory'))
    },
    {
      exact: true,
      path: PATH_APP.reports.orders,
      component: lazy(() => import('src/views/reports/orders'))
    },
    {
      exact: true,
      path: PATH_APP.reports.offers,
      component: lazy(() => import('src/views/reports/offers'))
    },
    {
      exact: true,
      path: PATH_APP.onlineDrivers.root,
      component: lazy(() => import('src/views/onlineDrivers/index'))
    },
    {
      exact: true,
      path: PATH_APP.onlineDrivers.map,
      component: lazy(() => import('src/views/onlineDrivers/driverOnMap'))
    },
    {
      exact: true,
      path: PATH_APP.outOfCoverageArea.root,
      component: lazy(() => import('src/views/OutOfCoverageAreas'))
    },
    {
      exact: true,
      path: PATH_APP.outOfCoverageArea.byId,
      component: lazy(() =>
        import('src/views/OutOfCoverageAreas/OutOfCoverageAreaInfo')
      )
    },
    {
      exact: true,
      path: PATH_APP.exportJobs.root,
      component: lazy(() => import('src/views/exportJobs/ExportJobs'))
    },
    {
      exact: true,
      path: PATH_APP.ads.root,
      component: lazy(() => import('src/views/Ads'))
    },
    {
      exact: true,
      path: PATH_APP.driverTypes.root,
      component: lazy(() => import('src/views/DriverTypes'))
    }
  ]
};

export default AppRoutes;
