import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export function useSearchText(callback, deps = []) {
  const [searchText, setSearchText] = useState('');
  const onSearchTextChange = useCallback((value) => {
    setSearchText(value);
  }, []);

  const debouncedCallback = useMemo(() => {
    return _.debounce(callback, 500);
  }, deps);

  useEffect(() => {
    debouncedCallback(searchText);
  }, [searchText]);

  return {
    searchText,
    onSearchTextChange
  };
}
