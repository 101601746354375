import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  error: false,
  userHistory: [],
  userHistoryCount: 0,
  isLoading: false,
  count: 0,
  smsHistory: []
};

const slice = createSlice({
  name: 'smsHistory',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    getHasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSmsHistorySucess(state, action) {
      state.smsHistory = action.payload.smsHistory;
      state.count = action.payload.count;
      state.isLoading = false;
    },
    startUserHistoryLoading(state) {
      state.isLoading = true;
      state.userHistory = [];
    },
    userHistorySuccess(state, action) {
      state.isLoading = false;
      state.userHistory = action.payload.smsHistory;
      state.userHistoryCount = action.payload.count;
    }
  }
});

export default slice.reducer;

export function getSmsHistory(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/smsHistory/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getSmsHistorySucess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.getHasError(error));
    }
  };
}

export function getUserSmsHistory(userId, pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startUserHistoryLoading());
      const response = await apiInstance.get(
        `/smsHistory/users/${userId}/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.userHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.getHasError(error));
    }
  };
}
