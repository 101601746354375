import { makeStyles } from '@material-ui/core';

export const useZoneDriversStyle = makeStyles({
  root: {
    flex: '32.5% 0 1',
    minWidth: 400,
    minHeight: 100,
    height: 'fit-content',
    borderRadius: 12,
    overflow: 'hidden',
    boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)'
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#00645C',
    gap: 8,
    paddingBlock: 12
  },
  driverRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    gap: 10
  },
  chipsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10
  },
  chip: {
    width: 'fit-content',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    paddingInline: 12,
    borderRadius: 12,
    color: '#00645C',
    backgroundColor: '#FEF445'
  },
  noDrivers: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    // change below color to gray dark
    color: '#c0c0c0'
  }
});
