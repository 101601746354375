import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  error: false,
  devices: [],
  isLoading: false,
  count: 0
};

const slice = createSlice({
  name: 'userDevices',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    getHasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getUserDevicesSuccess(state, action) {
      state.devices = action.payload.devices;
      // state.count = action.payload.count;
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function getUserDevices({ type, id }) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/${type}/${id}/devices`);
      dispatch(slice.actions.getUserDevicesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.getHasError(error));
    }
  };
}
