import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { paginationUtils } from './utils/pagination';
import { store } from '../store';

const initialState = {
  isLoading: false,
  exportJobsList: [],
  ...paginationUtils.initialState
};

const slice = createSlice({
  name: 'exportJobs',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    getExportJobsSuccess(state, action) {
      state.isLoading = false;
      state.exportJobsList = action.payload.list;
      state.count = action.payload.count;
    },

    hasError(state, action) {
      state.isLoading = false;
    },

    ...paginationUtils.reducers
  }
});

export default slice.reducer;

export function getExportJobs(filters) {
  return (dispatch) => {
    const { actions } = slice;
    const state = store.getState().exportJobs;
    const { page, perPage } = state;

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .get(
        `/export-jobs`,
        { params: filters }
        // { params: { page, perPage } }
      )
      .then((response) =>
        dispatch(actions.getExportJobsSuccess(response.data.data))
      )
      .catch((error) => actions.hasError(error));
  };
}

export function setExportJobsPage(page) {
  return (dispatch) => {
    dispatch(slice.actions.setPage(page));
    dispatch(getExportJobs());
  };
}

export function setExportJobsPerPage(perPage) {
  return (dispatch) => {
    dispatch(slice.actions.setPerPage(perPage));
    dispatch(getExportJobs());
  };
}
