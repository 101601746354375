import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  driverRequestsList: [],
  count: 0,
  requestSuccessMessage: ''
};

const slice = createSlice({
  name: 'driverRequests',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDriverRequestsSuccess(state, action) {
      state.driverRequestsList = action.payload.driverRequests;
      state.count = action.payload.count;
      state.isLoading = false;
    },
    requestSuccess(state, action) {
      state.requestSuccessMessage = action.payload.message;
      state.isLoading = false;
    },
    resetErrorSuccessMessage(state) {
      state.error = false;
      state.requestSuccessMessage = '';
    }
  }
});

export default slice.reducer;

export function getDriverRequestsList(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/driverRequests/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getDriverRequestsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function rejectDriverRequest(requestId, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.put(
        `/driverRequests/reject/${requestId}`,
        body,
        {
          toastMessage: 'Driver request is rejected successfully'
        }
      );
      dispatch(slice.actions.requestSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error?.response?.data?.message));
    }
  };
}

export function acceptDriverRequest(requestId, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const response = await apiInstance.put(
        `/driverRequests/accept/${requestId}`,
        body,
        {
          toastMessage: 'Driver request is accepted successfully'
        }
      );

      dispatch(slice.actions.requestSuccess(response?.data?.data));
    } catch (error) {
      console.log('error?.response ', error);
      dispatch(slice.actions.hasError(error?.response?.data?.message));
    }
  };
}

export function reset() {
  return (dispatch) => {
    dispatch(slice.actions.resetErrorSuccessMessage());
  };
}
