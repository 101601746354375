import { Autocomplete, TextField } from '@material-ui/core';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPromoCodesList } from 'src/store/slices/promoCodes';

export function PromoCodeFilter({
  defaultValue,
  value,
  onChange,
  singleValue = false,
  preload = true
}) {
  const dispatch = useDispatch();
  const { promoCodesList, isPromoLoading } = useSelector(
    (state) => state.promoCode
  );

  useEffect(() => {
    if (preload) {
      dispatch(getPromoCodesList(0, 1000));
    }
  }, [dispatch]);

  const fetch = useCallback(
    (input) => {
      if (!input) {
        return;
      }

      dispatch(getPromoCodesList(0, 1000, { code: input }));
    },
    [dispatch]
  );

  const debouncedFetch = useMemo(() => {
    return debounce(fetch, 500);
  }, [fetch]);

  return (
    <Autocomplete
      multiple={!singleValue}
      disablePortal
      autoComplete
      filterSelectedOptions
      name="promoCode"
      onChange={(_, v) => onChange(v)}
      onInputChange={(event, newInputValue) => {
        debouncedFetch(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="Promo Code" />}
      getOptionLabel={(option) => option?.code || ''}
      options={promoCodesList}
      value={value}
      defaultValue={defaultValue}
      loading={isPromoLoading}
    />
  );
}
