import { Autocomplete, TextField } from '@material-ui/core';

/**
 *
 * @param {{ label: string; options: {name: string; value: any;}[]; onChange: (value: any | null) => void; defaultValue: { name: string; value: any; } }} param0
 * @returns
 */
export function GenericFilter({
  label,
  options,
  onChange,
  defaultValue,
  ...props
}) {
  return (
    <Autocomplete
      {...props}
      disablePortal
      filterSelectedOptions
      // name="active"
      onChange={(_, v) => onChange(v?.value ?? null)}
      renderInput={(params) => <TextField {...params} label={label} />}
      getOptionLabel={(option) => option.name}
      options={options}
      defaultValue={defaultValue}
    />
  );
}
