import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  error: false,
  driverWorkingHoursList: [],
  isLoading: false,
  count: 0
};

const workingHours = [
  {
    driver: '12332423423',
    createdAt: new Date(),
    status: 'online'
  },
  {
    driver: '12332423423',
    createdAt: new Date(),
    status: 'offline'
  },
  {
    driver: '12332423423',
    createdAt: new Date(),
    status: 'online'
  },
  {
    driver: '12332423423',
    createdAt: new Date(),
    status: 'offline'
  }
];

const slice = createSlice({
  name: 'driverWorkingHours',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    getHasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDriverWorkingHoursSuccess(state, action) {
      state.driverWorkingHoursList = action.payload.list;
      state.count = action.payload.count;
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function getDriverWorkingHours(driverId, pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      //   const response = await apiInstance.post(`/drivers/autoComplete`, {
      //     name: userName
      //   });
      dispatch(
        slice.actions.getDriverWorkingHoursSuccess({
          list: workingHours,
          count: workingHours.length
        })
      );
    } catch (error) {
      dispatch(slice.actions.getHasError(error));
    }
  };
}
