import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { OrderStatusKeyName } from 'src/constants';
import apiInstance from 'src/utils/api';

const initialState = {
  error: false,
  statusList: [],
  isOrderStatusListLoading: false
};

const slice = createSlice({
  name: 'orderStatus',
  initialState,
  reducers: {
    startLoading(state) {
      state.isOrderStatusListLoading = true;
    },

    getHasError(state, action) {
      state.isOrderStatusListLoading = false;
      state.error = action.payload;
    },

    getOrderStatusListSuccess(state, action) {
      state.statusList = action.payload.statusList
        .map((status) => {
          const statusName = OrderStatusKeyName[status.key];
          _.set(status, 'name.en', statusName);
          if (!statusName) {
            return null;
          }
          return status;
        })
        .filter((s) => s != null);
      state.isOrderStatusListLoading = false;
    }
  }
});

export default slice.reducer;

export function getOrderStatusList({
  vendorOrders = false,
  scheduledOrders
} = {}) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/orderStatus`, {
        params: { vendorOrders, scheduledOrders }
      });
      dispatch(slice.actions.getOrderStatusListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.getHasError(error));
    }
  };
}
