import { Autocomplete, TextField } from '@material-ui/core';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from 'src/hooks';
import {
  getCustomersListAutoComplete,
  getcustomersList,
  resetCustomersAutoComplete,
  resetCustomersState
} from 'src/store/slices/customers';
import { Utils } from 'src/utils/utils';

export function CustomerFilter({
  defaultValue,
  onChange,
  singleValue = false,
  preload = true,
  ...rest
}) {
  const dispatch = useDispatch();
  const currentList = useRef('customersList');
  const {
    isLoading,
    customersList,
    customersListAutoComplete,
    isCustomerAutoCompleteLoading
  } = useSelector((state) => state.customer);

  useEffect(() => {
    if (preload) {
      getInitialList();
    }
    return () => dispatch(resetCustomersAutoComplete());
  }, [dispatch]);

  const getInitialList = useCallback(() => {
    currentList.current = 'customersList';
    dispatch(getcustomersList(0, 50));
  }, [dispatch]);

  const fetch = useCallback(
    (input) => {
      if (!input) {
        return;
      }

      currentList.current = 'customersListAutoComplete';
      dispatch(getCustomersListAutoComplete(0, 50, { searchText: input }));
    },
    [dispatch]
  );

  const debouncedFetch = useMemo(() => {
    return debounce(fetch, 500);
  }, [fetch]);

  const options = { customersList, customersListAutoComplete }[
    currentList.current
  ];

  return (
    <Autocomplete
      multiple={!singleValue}
      disablePortal
      autoComplete
      filterSelectedOptions
      name="Customer"
      onChange={(_, v) => {
        if (v) {
          onChange(v);
          return;
        }

        onChange(null);
        getInitialList();
      }}
      onInputChange={(event, newInputValue) => {
        // if (singleValue) {
        //   return;
        // }
        debouncedFetch(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="Customer" />}
      filterOptions={(x) => x}
      getOptionLabel={(option) => Utils.username(option?.user)}
      options={options}
      defaultValue={defaultValue}
      loading={isLoading || isCustomerAutoCompleteLoading}
      {...rest}
    />
  );
}
