import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { store } from '../store';
import { paginationUtils } from './utils/pagination';

const initialState = {
  isLoading: false,
  adsList: [],
  ...paginationUtils.initialState
};

const slice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    getAdsSuccess(state, action) {
      state.isLoading = false;
      state.adsList = action.payload.ads;
      state.count = action.payload.count;
    },

    hasError(state, action) {
      state.isLoading = false;
    },

    ...paginationUtils.reducers
  }
});

export default slice.reducer;

export function getAdsList(filters) {
  return (dispatch) => {
    const state = store.getState().ads;
    const { page, perPage } = state;
    const { actions } = slice;
    const config = { params: filters };

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .get(`/ads/${page}/${perPage}`, config)
      .then((response) => dispatch(actions.getAdsSuccess(response.data.data)))
      .catch((error) => actions.hasError(error));
  };
}

export function createAd(payload, events) {
  return (dispatch) => {
    const { actions } = slice;
    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .post(`/ads`, payload, { toastMessage: 'Ad created successfuly' })
      .then(() => dispatch(getAdsList()))
      .then(() => events?.onSuccess?.())
      .catch((error) => actions.hasError(error))
      .finally(() => dispatch(slice.actions.setLoading(false)));
  };
}

export function updateAd(id, update, events) {
  return (dispatch) => {
    const { actions } = slice;
    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .put(`/ads/${id}`, update, { toastMessage: 'Ad updated successfuly' })
      .then(() => dispatch(getAdsList()))
      .then(() => events?.onSuccess?.())
      .catch((error) => actions.hasError(error))
      .finally(() => dispatch(slice.actions.setLoading(false)));
  };
}

export function deleteAd(id, events) {
  return (dispatch) => {
    const { actions } = slice;
    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .delete(`/ads/${id}`, { toastMessage: 'Ad deleted successfuly' })
      .then(() => dispatch(getAdsList()))
      .then(() => events?.onSuccess?.())
      .catch((error) => actions.hasError(error));
  };
}

export function setAdsPage(page) {
  return (dispatch) => {
    dispatch(slice.actions.setPage(page));
    dispatch(getAdsList());
  };
}

export function setAdsPerPage(perPage) {
  return (dispatch) => {
    dispatch(slice.actions.setPerPage(perPage));
    dispatch(getAdsList());
  };
}
