import { Autocomplete, Box, Grid, TextField } from '@material-ui/core';
import { debounce, get } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getdriversList,
  resetDriversAutoComplete
} from 'src/store/slices/drivers';
import { Utils } from 'src/utils/utils';

// cache invalidate :/

export function DriverFilter({
  defaultValue,
  onChange,
  areas,
  order,
  ordersType,
  active,
  singleValue = false,
  preload = true,
  ...rest
}) {
  const dispatch = useDispatch();
  const { driversList, isLoading } = useSelector((state) => state.driver);

  // useEffect(() => {
  //   if (preload) {
  //     getInitialList();
  //   }
  //   return () => dispatch(resetDriversAutoComplete());
  // }, [dispatch]);

  const getOrdersType = useCallback(() => {
    if (ordersType) {
      return ordersType;
    }

    if (typeof order?.orderType === 'string') {
      return order.orderType === 'VENDOR' ? 'vendor' : 'general_shop';
    }

    return undefined;
  }, [order, ordersType]);

  const getInitialList = useCallback(() => {
    dispatch(
      getdriversList(0, 50, {
        areas,
        ordersType: getOrdersType(),
        active
      })
    );
  }, [dispatch, areas, getOrdersType, active]);

  useEffect(() => {
    if (preload) {
      getInitialList();
    }
  }, []);

  const fetch = useCallback(
    (input) => {
      if (!input) {
        return;
      }

      dispatch(
        getdriversList(0, 50, {
          areas,
          ordersType: getOrdersType(),
          searchText: input,
          active
        })
      );
    },
    [dispatch, areas, getOrdersType]
  );

  const debouncedFetch = useMemo(() => {
    return debounce(fetch, 500);
  }, [fetch]);

  return (
    <Autocomplete
      style={{ backgroundColor: 'white' }}
      multiple={!singleValue}
      disablePortal
      autoComplete
      filterSelectedOptions
      name="Driver"
      onChange={(_, v) => {
        onChange(v);
        // if (v) {
        //   return;
        // }

        // onChange(null);
        // getInitialList();
      }}
      onInputChange={(event, newInputValue) => {
        // if (singleValue) {
        //   return;
        // }
        debouncedFetch(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="Driver" />}
      renderOption={(params, options) => (
        <div {...params}>
          <DriverOption options={options} />
        </div>
      )}
      filterOptions={(x) => x}
      getOptionLabel={(option) => Utils.username(option.user)}
      options={driversList}
      defaultValue={defaultValue}
      loading={isLoading}
      {...rest}
    />
  );
}

function DriverOption({ options }) {
  let orders = get(options, 'orders', 0);

  if (typeof orders !== 'number') {
    orders = get(options, 'orders.active', 0);
  }

  return (
    <>
      <div
        style={{
          width: '50px',
          height: '50px',
          marginRight: '8px',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
      >
        <img
          loading="lazy"
          style={{
            width: '100%'
          }}
          src={options.user?.avatar}
          alt=""
        />
      </div>
      <Grid container flexDirection={'row'} justifyContent={'space-between'}>
        <Grid item flexDirection={'row'}>
          <p>{Utils.username(options.user)}</p>
          <p>{options.phone}</p>
        </Grid>
        <Grid item>({orders} Order)</Grid>
      </Grid>
    </>
  );
}
