export const baseURL = {
  apiEndpoint: process.env.REACT_APP_BASE_URL_API_END_POINT,
  v2ApiEndpoint: process.env.REACT_APP_V2_BASE_URL_API_END_POINT,
  socketBaseUrl: process.env.REACT_APP_BASE_SOCKET_URL
};

export const sentry = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV
};
