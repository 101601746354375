import { get, set } from 'lodash';

export class Utils {
  static downloadFromUrl(url, name) {
    if (/^(https:\/\/|http:\/\/)/.test(url) === false) {
      url = `https://${url}`;
    }
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  static username(user) {
    if (user == null || typeof user !== 'object') {
      return '';
    }

    const { firstName = '', lastName = '', name } = user;
    let value = [firstName, lastName].join(' ');
    return name ? name : value.trim(' ') || '';
  }

  static capitalizeInitials(str) {
    return str.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  }

  static onPageChange(dispatch, action) {
    return (_, page) => dispatch(action(page));
  }

  static onPerPageChange(dispatch, action) {
    return (e) => {
      dispatch(action(e.target.value));
    };
  }

  static objectDiff(original, target, rootPathArray = [], diffArray = []) {
    const originalValue = rootPathArray.length
      ? get(original, rootPathArray.join('.'))
      : original;

    for (const key in originalValue) {
      const pathArray = [...rootPathArray, key];

      const value = originalValue[key];
      const isPrimitive = this.isPrimitive(value);

      if (key in originalValue === false) {
        diffArray.push(pathArray);
      }

      if (isPrimitive) {
        const path = pathArray.join('.');
        if (get(original, path) !== get(target, path)) {
          diffArray.push(path);
        }
      } else {
        this.objectDiff(original, target, pathArray, diffArray);
      }
    }

    const result = {};
    for (const path of diffArray) {
      set(result, path, true);
    }

    console.log({ diffArray, result });

    return result;
  }

  static isPrimitive(value) {
    return (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      typeof value === 'undefined' ||
      value === null
    );
  }

  static match(value, conditions) {
    if (typeof value === 'boolean') {
      return conditions[value ? 'true' : 'false'];
    }

    throw new Error('Not implemented');
  }
}
