import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  isEditLoading: false,
  error: false,
  appSettingsList: [],
  appSettingsSuccessMessage: '',
  count: 0
};

const slice = createSlice({
  name: 'appSetting',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    setIsEditLoading(state, action) {
      state.isEditLoading = action.payload;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAppSettingsSuccess(state, action) {
      state.appSettingsList = action.payload.appSettingsList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    appSettingsSuccess(state, action) {
      state.appSettingsSuccessMessage = action.payload;
      // state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function getAppSettingsList(pageNumber, pageSize, params) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/appSettings/${pageNumber}/${pageSize}`,
        { params }
      );
      dispatch(slice.actions.getAppSettingsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAppSetting(id, body, events) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setIsEditLoading(true));
      const response = await apiInstance.put(`/appSettings/${id}`, body, {
        toastMessage: 'App settings updated successfuly'
      });
      dispatch(slice.actions.appSettingsSuccess(response.data.data.message));
      if (events?.onSuccess) {
        events.onSuccess(response);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.response?.data?.message));
    } finally {
      dispatch(slice.actions.setIsEditLoading(false));
    }
  };
}
