import { PATH_APP } from 'src/routes/paths';

export function DriverNameLink({ driver }) {
  return (
    <a
      href={`${PATH_APP.drivers.root}/${driver?._id}`}
      target="_blank"
      rel="noreferrer"
    >
      {driver?.user?.name}
    </a>
  );
}
