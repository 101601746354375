import io, { Socket } from 'socket.io-client';
import { baseURL } from '../config';
import { debug } from '../utils/debug';

class SocketClient {
  /**
   * @type {Socket} socket
   */
  socket;

  initialize = () => {
    if (!baseURL.socketBaseUrl) {
      debug.warn('Socket configuration was not provided');
      return;
    }

    if (this.socket && this.socket.connected) {
      return;
    }

    this.socket = io(baseURL.socketBaseUrl, {
      // reconnection: true,
      // reconnectionDelay: 500,
      transports: ['websocket']
    });
  };

  connect = () => {
    this.socket?.connect?.();
  };

  disconnect = () => {
    this.socket?.disconnect?.();
  };

  close = () => {
    this.socket?.close();
  };

  /**
   *
   * @param {string} event
   * @param {(...args: any[]) => {}} listener
   */
  on = async (event, listener) => {
    debug.log('Socket listen for:', event);
    this.socket?.on?.(event, listener);
  };

  /**
   *
   * @param {string} event
   * @param {(...args: any[]) => {}} listener
   */
  off = async (event, listener) => {
    debug.log('Socket unlisten on:', event);
    this.socket?.off?.(event, listener);
  };

  /**
   *
   * @param {string} event
   * @param {(...args: any[]) => {}} listener
   */
  once = async (event, listener) => {
    debug.log('Socket listen once for:', event);
    this.socket?.once?.(event, listener);
  };

  /**
   *
   * @param {string} event
   * @param {...args: any[]} listener
   */
  emit = async (event, ...args) => {
    debug.log('Socket emit:', event);
    this.socket?.emit?.(event, ...args);
  };
}

export const socket = new SocketClient();
