import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  makeStyles
} from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  addOrderComments,
  getOrderComments
} from 'src/store/slices/orderComments';
import { MCircularProgress } from 'src/theme';
import { localize } from 'src/utils/localize';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300
  },
  inline: {
    display: 'inline'
  },
  chatCell: {
    background: 'white',
    borderRadius: '5px',
    paddingTop: '12px',
    paddingBottom: '25px',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  timeCell: {
    float: 'right'
  },
  card: {
    position: 'relative',
    padding: theme.spacing(3),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3)
    },
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  addCommentField: {
    width: '100%'
  },
  addCommentBtn: {
    marginTop: '8px',
    marginBottom: '8px'
  },
  errorTxt: {
    fontSize: 11,
    color: 'red'
  },
  commentsTitle: {
    marginBottom: '8px'
  },
  alignCenter: {
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  drawer: {
    zIndex: '1999 !important'
  },
  drawerPaper: {
    width: 800
  }
}));

const useCreateComment = (order) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comment: ''
    },
    // validationSchema: AddCommentSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      if (!values.comment) {
        return;
      }

      const body = {
        comment: values.comment
      };
      formik.setFieldValue('comment', '');

      dispatch(addOrderComments(order._id, body));
    }
  });

  return { formik };
};

export const OrderCommentsDrawer = ({ open, onClose, order }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { formik } = useCreateComment(order);
  const { user } = useSelector((state) => state.auth);
  const { isLoading, orderComments } = useSelector(
    (state) => state.orderComments
  );

  useEffect(() => {
    if (!order || !open) {
      return;
    }

    dispatch(getOrderComments(order._id));
  }, [dispatch, open]);

  if (!order) {
    return null;
  }

  let { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <Drawer
      classes={{
        root: classes.drawer,
        paper: classes.drawerPaper
      }}
      open={open}
      anchor="right"
      onClose={onClose}
    >
      <Box>
        <Card className={classes.card}>
          <div>
            <h4 className={classes.commentsTitle}>
              {localize('Comments:', 'التعليقات:', i18n.language)}
            </h4>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                  id="filled-multiline-flexible"
                  multiline
                  label={t(`users.comments.newComment`)}
                  {...getFieldProps('comment')}
                  className={classes.addCommentField}
                  rows={3}
                />
                {errors.comment && touched.comment ? (
                  <div className={classes.errorTxt}>{errors.comment}</div>
                ) : null}

                <Button
                  type="submit"
                  className={classes.addCommentBtn}
                  variant="contained"
                >
                  {localize('save', 'حفظ', i18n.language)}
                </Button>
              </Form>
            </FormikProvider>
          </div>
          <List sx={{ bgcolor: 'background.paper' }}>
            {isLoading ? (
              <div className={classes.alignCenter}>
                <MCircularProgress color="info" />
              </div>
            ) : null}

            {!isLoading &&
              orderComments &&
              orderComments.map((comment) => {
                const author = comment?.admin?.user || user;
                return (
                  <div>
                    <ListItem key={comment._id} sx={{ width: 'fit-content' }}>
                      <ListItemAvatar sx={{ marginLeft: '15px' }}>
                        <Avatar alt="admin avatar" src={author?.avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${author?.firstName} ${
                          author?.lastName || ''
                        }`}
                        secondary={
                          <div className={classes.chatCell}>
                            {comment.comment}
                          </div>
                        }
                      />
                    </ListItem>
                    <Divider
                      variant="inset"
                      sx={{ marginLeft: 'unset' }}
                      component="li"
                    />
                  </div>
                );
              })}
          </List>
        </Card>
      </Box>
    </Drawer>
  );
};
