import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isExtrasLoading: false,
  error: false,
  extrasList: [],
  choicesList: [],
  isAddExtraLoading: false,
  addExtraError: false,
  addExtraSuccessMessage: '',
  isUpdateExtraLoading: false,
  updateExtraSuccessMessage: '',
  updateExtraError: false
};

const slice = createSlice({
  name: 'itemExtras',
  initialState,
  reducers: {
    startLoading(state) {
      state.isExtrasLoading = true;
    },

    extrasHasError(state, action) {
      state.isExtrasLoading = false;
      state.error = action.payload;
    },
    getExtrasSuccess(state, action) {
      state.extrasList = action.payload.extras;
      state.choicesList = action.payload.choices;
      state.isExtrasLoading = false;
    },
    startAddLoading(state) {
      state.isAddExtraLoading = true;
    },

    addHasError(state, action) {
      state.isAddExtraLoading = false;
      state.addExtraError = action.payload;
    },
    addExtrasSuccess(state, action) {
      state.addExtraSuccessMessage = action.payload;
      state.isAddExtraLoading = false;
    },
    startUpdateLoading(state) {
      state.isUpdateExtraLoading = true;
    },

    updateHasError(state, action) {
      state.isUpdateExtraLoading = false;
      state.updateExtraError = action.payload;
    },
    updateExtrasSuccess(state, action) {
      state.updateExtraSuccessMessage = action.payload;
      state.isUpdateExtraLoading = false;
    }
  }
});

export default slice.reducer;

export function getBrandExtras(brandId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/itemExtras/${brandId}`);
      dispatch(slice.actions.getExtrasSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.extrasHasError(error));
    }
  };
}

export function addItemExtras(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startAddLoading());
      const response = await apiInstance.post(`/itemExtras`, body, {
        toastMessage: 'Item extra is added successfully'
      });
      dispatch(slice.actions.addExtrasSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.addHasError(error));
    }
  };
}

export function updateExtra(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startUpdateLoading());
      const response = await apiInstance.put(`/itemExtras/${id}`, body, {
        toastMessage: 'Item extra is updated successfully'
      });
      dispatch(slice.actions.updateExtrasSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.updateHasError(error));
    }
  };
}
