import { Autocomplete, TextField } from '@material-ui/core';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBrandsList,
  resetBrandsAutoComplete
} from 'src/store/slices/brands';

export function BrandsFilter({
  defaultValue,
  onChange,
  filterOptions,
  category,
  filterByCategory,
  singleValue = false,
  preload = true,
  ...rest
}) {
  const dispatch = useDispatch();
  const { brandsList, isLoading } = useSelector((state) => state.brand);

  useEffect(() => {
    if (preload) {
      dispatch(
        getBrandsList({
          pageNumber: 0,
          pageSize: 10000,
          categories: category ? [category._id] : undefined
        })
      );
    }
    return () => dispatch(resetBrandsAutoComplete());
  }, [category, dispatch, preload]);

  const fetch = useCallback(
    (input) => {
      if (!input) {
        return;
      }

      dispatch(
        getBrandsList({
          pageNumber: 0,
          pageSize: 10000,
          categories: category ? [category._id] : undefined,
          searchText: input
        })
      );
    },
    [category, dispatch]
  );

  const debouncedFetch = useMemo(() => {
    return debounce(fetch, 500);
  }, [fetch]);

  const options = useMemo(() => {
    let options = brandsList;
    if (filterOptions) {
      options = brandsList.filter((brand) => filterOptions(brand));
    }
    return options;
  }, [brandsList, filterOptions]);

  return (
    <Autocomplete
      multiple={!singleValue}
      disablePortal
      autoComplete
      filterSelectedOptions
      name="brands"
      onChange={(_, v) => onChange(v)}
      onInputChange={(event, newInputValue) => {
        // if (singleValue) {
        //   return;
        // }
        debouncedFetch(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="Brands" />}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.name?.en}
      options={options}
      defaultValue={defaultValue}
      loading={isLoading}
      {...rest}
    />
  );
}
