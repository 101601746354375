import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  isBranchUserLoading: false,
  error: false,
  branchesList: [],
  branchSuccessMessage: '',
  count: 0,
  isAutoCompleteLoading: false,
  branchesListAutoComplete: []
};

const slice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setBranchUserLoading(state, action) {
      state.isBranchUserLoading = action.payload;
    },
    startAutoCompleteLoading(state) {
      state.isAutoCompleteLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.isBranchUserLoading = false;
      state.error = action.payload;
    },
    getBranchesSuccess(state, action) {
      state.branchesList = action.payload.branchesList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    branchSuccess(state, action) {
      state.branchSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
      // state.isLoading = false;
    },

    startEditLoading(state, action) {
      if (action.payload?.status !== undefined) {
        state.branchesList = state.branchesList.map((t) => {
          if (t._id === action.payload?.id) {
            t.active = action.payload?.status;
          }
          return t;
        });
      }
    },
    startDeleteLoading(state, action) {
      state.branchesList = state.branchesList.filter(
        (t) => t._id !== action.payload
      );
      state.count -= 1;
    },
    getBranchesAutoCompleteSuccess(state, action) {
      state.branchesListAutoComplete = action.payload.branches;
      state.isAutoCompleteLoading = false;
    },
    resetBranchesAutoComplete(state) {
      state.branchesListAutoComplete = [];
    }
  }
});

export default slice.reducer;

function noop() {}

export function getBranchesByBrandList(brandId, pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(
        `/branches/${brandId}/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getBranchesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBranchesList({
  searchText = null,
  brandIds,
  pageNumber,
  pageSize
}) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(
        `/branches/${pageNumber}/${pageSize}`,
        { searchText: searchText, brandIds }
      );
      dispatch(slice.actions.getBranchesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addBranch(body) {
  return async (dispatch) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.post('/branches', body, {
        toastMessage: 'Branch created successfuly'
      });
      dispatch(slice.actions.branchSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editBranch(id, body) {
  return async (dispatch) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.put(`/branches/${id}`, body, {
        toastMessage: 'Branch updated successfuly'
      });
      dispatch(slice.actions.branchSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editBranchStatus(id, status, cb = noop) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditLoading({ id, status }));
      const response = await apiInstance.put(`/branches/${id}`, {
        active: status
      });
      console.log(
        '[[[Branchs Tab]]] - edit status result:',
        response.data.data
      );
      dispatch(slice.actions.branchSuccess(response.data.data));
      cb(null);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      cb(error);
    }
  };
}

export function editBranchIsVendor(id, isVendor, cb = noop) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditLoading({ id, isVendor }));
      const response = await apiInstance.put(`/branches/${id}`, {
        isVendor
      });
      console.log(
        '[[[Branchs Tab]]] - edit status result:',
        response.data.data
      );
      dispatch(slice.actions.branchSuccess(response.data.data));
      cb(null);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      cb(error);
    }
  };
}

export function deleteBranch(id, brandId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/branches/${brandId}/${id}`, {
        toastMessage: 'Branch deleted successfuly'
      });
      dispatch(slice.actions.branchSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createBranchUser(branchId, payload, events) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBranchUserLoading(true));
      await apiInstance.post(`/branches/${branchId}/users`, payload, {
        toastMessage: 'Branch user is created successfuly'
      });
      if (events?.onSuccess) {
        events.onSuccess();
      }
    } catch (error) {
      dispatch(slice.actions.hasError);
      if (events?.onFail) {
        events.onFail(error);
      }
    } finally {
      dispatch(slice.actions.setBranchUserLoading(false));
    }
  };
}
export function updateBranchUser(userId, payload, events) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setBranchUserLoading(true));
      console.log(
        '[[[Branches Store]]] - update user payload:',
        userId,
        payload
      );
      await apiInstance.put(`/branches/users/${userId}`, payload, {
        toastMessage: 'Branch user is updated successfuly'
      });
      if (events?.onSuccess) {
        events.onSuccess();
      }
    } catch (error) {
      dispatch(slice.actions.hasError);
      if (events?.onFail) {
        events.onFail(error);
      }
    } finally {
      dispatch(slice.actions.setBranchUserLoading(false));
    }
  };
}

//[TODO] - get branches list autocomplete
export function getBranchesListAutoComplete(brandId, filter = { name: '' }) {
  return async (dispatch) => {
    try {
      console.log('[[[Branches APIs]]] - auto list:', brandId, filter);
      dispatch(resetBranchesAutoComplete());
      dispatch(slice.actions.startAutoCompleteLoading);
      const response = await apiInstance.post(`/branches/autoComplete`, {
        brandId,
        ...filter
      });
      console.log(
        '[[[Branches APIs]]] - Fetch branches autocomplete list:',
        response.data.data
      );
      dispatch(
        slice.actions.getBranchesAutoCompleteSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetBranchesAutoComplete() {
  return async (dispatch) => {
    dispatch(slice.actions.resetBranchesAutoComplete());
  };
}
