import { get } from 'lodash';
import { toast } from 'react-toastify';
import { useSocketListener } from 'src/hooks/useSocketListener';
import { PATH_APP } from 'src/routes/paths';
import { SubState, addNewOrder } from 'src/store/slices/vendorsOrders';
import { store } from 'src/store/store';
import { Events, eventsManager } from 'src/utils/eventManager';
import { ToastifyManager } from 'src/utils/toastifyManager';
import { Utils } from 'src/utils/utils';
import { Sounds } from './AudioPlayer';
import { addScheduledOrder } from 'src/store/slices/ramadanOrders';

// This in memory variable is used to hold references for handling socket responses
// on a single tab (excel export using exportId)
export const memory = new Map();

export function SocketListeners() {
  useSocketListener({
    room: 'dashboard',
    event: 'NOTIFICATIONS',
    listener: (payload) => {
      if (!payload) {
        return;
      }

      const handler = socketHandlers[payload.type];

      if (handler) {
        handler(payload);
      }
    }
  });

  return null;
}

function handleExportEvent(toastId, payload) {
  const url = payload.data?.result?.Location;
  const exportId =
    get(payload, 'data.exportId') || get(payload, 'order.exportId') || '';
  const error = payload.data?.error;

  if (memory.get(toastId) !== exportId) {
    return;
  }

  memory.delete(toastId);

  ToastifyManager.dismiss(toastId);

  if (error != null) {
    toast(error, { type: 'error' });
    return;
  }

  if (url == null) {
    const errorMessage = 'Exported file url not found';
    toast(errorMessage, { type: 'error' });
    return;
  }

  Utils.downloadFromUrl(url);
}

const socketHandlers = {
  'order-updated-by-vendor': (payload) => {
    eventsManager.emit(Events.Orders.OrderChanged, payload.order);
  },
  'order-updated': (payload) => {
    if (payload.order?.scheduled) {
      eventsManager.emit(Events.Orders.ScheduledOrderChanged, payload.order);
    } else {
      eventsManager.emit(Events.Orders.OrderChanged, payload.order);
    }
  },
  'new-vendor-order': (payload) => {
    if (payload.order?.scheduled) {
      store.dispatch(addScheduledOrder(payload.order));
      if (window.location.pathname === '/app/ramadan/orders') {
        eventsManager.emit(Events.Audio.Play, { sound: Sounds.Notification });
      }
    } else {
      store.dispatch(addNewOrder(payload.order));
      if (window.location.pathname === '/app/vendors/orders') {
        eventsManager.emit(Events.Audio.Play, { sound: Sounds.Notification });
      }
    }
  },
  'unhandled-order-notification': (payload) => {
    const { order } = payload;

    toast(`1 New unhandled order (${order.orderNo})`, {
      type: 'warning',
      style: { cursor: 'pointer' },
      autoClose: 1000,
      onClick: () => {
        if (window.location.pathname === PATH_APP.unhandledOrders.root) {
          window.location.reload();
          return;
        }

        window.open(`${PATH_APP.unhandledOrders.root}`, '_blank');
      }
    });
  },
  'export-brands': (payload) => handleExportEvent('export-brands', payload),
  'export-orders': (payload) => handleExportEvent('export-orders', payload),
  'export-drivers': (payload) => handleExportEvent('export-drivers', payload),
  'export-customers': (payload) =>
    handleExportEvent('export-customers', payload),
  'export-drivers-salary': (payload) =>
    handleExportEvent('export-drivers-salary', payload),
  'export-bills': (payload) => handleExportEvent('export-bills', payload)
};
