import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { paginationUtils } from './utils/pagination';

const initialState = {
  error: false,
  count: 0,
  adminsList: [],
  isLoading: false,
  adminSuccessMessage: '',
  admin: {},
  ...paginationUtils.initialState
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getAdminsSuccess(state, action) {
      state.adminsList = action.payload.admins;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    adminSuccess(state, action) {
      state.adminSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
      // state.isLoading = false;
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status != undefined) {
        state.adminsList = state.adminsList.map((t) => {
          if (t.user?._id == action.payload?.id) {
            t.user.status = action.payload?.status;
          }
          return t;
        });
      }
    },

    getAdminSuccess(state, action) {
      state.admin = action.payload.admin;
      state.isLoading = false;
    },

    startDeleteAddressLoading(state, action) {
      if (
        action.payload?.addressId != undefined &&
        state.admin?.user?.address?.length
      ) {
        state.admin.user.address = state.admin.user.address.filter(
          (a) => a._id != action.payload.addressId
        );
      }
      // state.isLoading = true;
    },
    ...paginationUtils.reducers
  }
});

export default slice.reducer;

export function getadminsList(pageNumber, pageSize, filters) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/admins/${pageNumber}/${pageSize}`,
        { params: filters }
      );
      dispatch(slice.actions.getAdminsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAdmin(body, options) {
  return async (dispatch, getState) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(`/admins`, body, {
        toastMessage: 'Admin is added successfully'
      });
      options?.onSuccess?.();
      dispatch(slice.actions.adminSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function blockUnBlockAdmin(id, status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(`/users/status/${id}`, { status });
      dispatch(slice.actions.adminSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAdmin(adminId, body, options) {
  return async (dispatch) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.put(`/admins/${adminId}`, body, {
        toastMessage: 'Admin is updated successfully'
      });
      options?.onSuccess?.();
      dispatch(slice.actions.adminSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdminById(adminId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/admins/${adminId}`);
      dispatch(slice.actions.getAdminSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAdminAddress(userId, body) {
  return async (dispatch) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(
        `/users/address/${userId}`,
        body,
        {
          toastMessage: 'Address is added successfully'
        }
      );
      dispatch(slice.actions.adminSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteAdminAddress(userId, addressId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteAddressLoading({ addressId }));
      const response = await apiInstance.put(
        `/users/address/delete/${userId}`,
        { addressId: addressId }
      );
      dispatch(slice.actions.adminSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAdminAddress(userId, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.put(
        `/users/address/update/${userId}`,
        body,
        {
          toastMessage: 'Address is updated successfully'
        }
      );
      dispatch(slice.actions.adminSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function authorizeAdminToVendorDashboard(userId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      await apiInstance.get(`/admins/createVendorUser/${userId}`, {
        toastMessage: 'Authorized to vendor dashboard'
      });
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setAdminPage(page) {
  return (dispatch) => dispatch(slice.actions.setPage(page));
}

export function setAdminPerPage(perPage) {
  return (dispatch) => dispatch(slice.actions.setPerPage(perPage));
}
