import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  problemTypesList: [],
  count: 0,
  problemTypeSuccessMessage: ''
};

const slice = createSlice({
  name: 'problemTypes',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProblemTypesSuccess(state, action) {
      state.problemTypesList = action.payload.problemTypes;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    problemTypeSuccess(state, action) {
      state.problemTypeSuccessMessage = action.payload;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
    },

    startEditStatusLoading(state, action) {
      state.problemTypesList = state.problemTypesList.map((t) => {
        if (t._id == action.payload?.id) {
          t.status = action.payload?.body?.status;
        }
        return t;
      });
    },

    startDeleteLoading(state, action) {
      state.problemTypesList = state.problemTypesList.filter(
        (t) => t._id != action.payload
      );
      state.count -= 1;
    }
  }
});

export default slice.reducer;

export function getProblemTypesList(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/problemTypes/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getProblemTypesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addProblemType(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post('/problemTypes', body, {
        toastMessage: 'Problem type is added successfully'
      });
      dispatch(slice.actions.problemTypeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editProblemType(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, body }));
      const response = await apiInstance.put(`/problemTypes/${id}`, body, {
        toastMessage: 'Problem type is updated successfully'
      });
      dispatch(slice.actions.problemTypeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProblemType(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/problemTypes/${id}`, {
        toastMessage: 'Problem type is deleted successfully'
      });
      dispatch(slice.actions.problemTypeSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
