import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  transactionsList: [],
  transactionSuccessMessage: '',
  count: 0,
  isDriverTrxLoading: false,
  driverTrxError: false,
  driverTrxList: [],
  driverTrxCount: 0
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getTransactionsSuccess(state, action) {
      state.transactionsList = action.payload.transactionsList;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    transactionSuccess(state, action) {
      state.transactionSuccessMessage = action.payload;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
    },
    startDriverTrxLoading(state) {
      state.isDriverTrxLoading = true;
    },

    driverTrxHasError(state, action) {
      state.isDriverTrxLoading = false;
      state.driverTrxError = action.payload;
    },
    getDriverTransactionsSuccess(state, action) {
      state.driverTrxList = action.payload.driverTransctionsList;
      state.driverTrxCount = action.payload.count;
      state.isDriverTrxLoading = false;
    }
  }
});

export default slice.reducer;

export function getTransactionsList(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/transactions/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getTransactionsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addTransaction(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post('/transactions', body, {
        toastMessage: 'Transaction is added successfully'
      });
      dispatch(slice.actions.transactionSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDrvierTransactionsList(driverId, pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDriverTrxLoading());
      const response = await apiInstance.get(
        `/transactions/driver/${driverId}/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getDriverTransactionsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.driverTrxHasError(error));
    }
  };
}
