import { PATH_APP } from 'src/routes/paths';

export function CustomerNameLink({ customer }) {
  return (
    <a
      href={`${PATH_APP.customers.root}/${customer?._id}`}
      target="_blank"
      rel="noreferrer"
    >
      {customer?.user?.name}
    </a>
  );
}
