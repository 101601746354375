import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  error: false,
  count: 0,
  customersList: [],
  customersListAutoComplete: [],
  customerSuccessMessage: '',
  isLoading: false,
  isCustomerAutoCompleteLoading: false
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startCustomerAutoCompleteLoading(state) {
      state.isCustomerAutoCompleteLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCustomersSuccess(state, action) {
      state.customersList = action.payload.customers;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    getCustomersAutoCompleteSuccess(state, action) {
      state.customersListAutoComplete = action.payload.customers;
      state.isCustomerAutoCompleteLoading = false;
    },

    customerSuccess(state, action) {
      state.customerSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
        state.isLoading = false;
      }
    },

    startEditStatusLoading(state, action) {
      if (action.payload?.status != undefined) {
        state.customersList = state.customersList.map((t) => {
          if (t.user?._id == action.payload?.id) {
            t.user.status = action.payload?.status;
          }
          return t;
        });
      }
    },

    getCustomerSuccess(state, action) {
      state.customer = action.payload.customer;
      state.isLoading = false;
    },
    getUserSuccess(state, action) {
      state.customer = action.payload.user;
      state.isLoading = false;
    },

    startDeleteAddressLoading(state, action) {
      if (
        action.payload?.addressId != undefined &&
        state.customer?.user?.address?.length
      ) {
        state.customer.user.address = state.customer.user.address.filter(
          (a) => a._id != action.payload.addressId
        );
      }
    },
    resetErrorSuccessMessage(state) {
      state.error = false;
      state.customerSuccessMessage = '';
    },
    resetCustomersState(state) {
      for (const key in initialState) {
        if (Object.hasOwnProperty.call(initialState, key)) {
          state[key] = initialState[key];
        }
      }
    },
    resetCustomersAutoComplete(state) {
      state.customersListAutoComplete = [];
    }
  }
});

export default slice.reducer;

export function getcustomersList(pageNumber, pageSize, filters) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(
        `/customers/${pageNumber}/${pageSize}`,
        filters
      );
      dispatch(slice.actions.getCustomersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomersListAutoComplete(
  page,
  perPage,
  filter = { searchText: '' }
) {
  return async (dispatch) => {
    try {
      dispatch(resetCustomersAutoComplete());
      dispatch(slice.actions.startCustomerAutoCompleteLoading());
      const response = await apiInstance.post(
        `/customers/autocomplete`,
        filter,
        { params: { page, perPage } }
      );
      dispatch(
        slice.actions.getCustomersAutoCompleteSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addCustomer(body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.post(`/customers`, body);
      dispatch(slice.actions.customerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error?.response?.data?.message));
    }
  };
}

export function updateCustomer(id, body) {
  return async (dispatch) => {
    try {
      // dispatch(slice.actions.startLoading());
      const response = await apiInstance.put(`/customers/${id}`, body, {
        toastMessage: 'Customer is updated successfully'
      });
      dispatch(slice.actions.customerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function blockUnBlockCustomer(id, status, blockReason) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, status }));
      const response = await apiInstance.put(
        `/users/status/${id}`,
        {
          status,
          blockReason
        },
        {
          toastMessage: `Customer ${
            status === true ? 'unblocked' : 'blocked'
          } successfully`
        }
      );
      dispatch(slice.actions.customerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerByUserId(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/users/customer/${id}`);
      dispatch(slice.actions.getUserSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCustomerById(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(`/customers/${id}`);
      dispatch(slice.actions.getCustomerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addCustomerAddress(id, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/customers/address/${id}`, body, {
        toastMessage: 'Address is added successfully'
      });
      dispatch(slice.actions.customerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteCustomerAddress(userId, addressId) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteAddressLoading({ addressId }));
      const response = await apiInstance.put(
        `/users/address/delete/${userId}`,
        { addressId: addressId },
        {
          toastMessage: 'Address is deleted successfully'
        }
      );
      dispatch(slice.actions.customerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editCustomerAddress(userId, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(
        `/users/address/update/${userId}`,
        body,
        {
          toastMessage: 'Address is updated successfully'
        }
      );
      dispatch(slice.actions.customerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function reset() {
  return (dispatch) => {
    dispatch(slice.actions.resetErrorSuccessMessage());
  };
}

export function resetCustomersState() {
  return (dispatch) => {
    dispatch(slice.actions.resetCustomersState());
  };
}

export function resetCustomersAutoComplete() {
  return async (dispatch) => {
    dispatch(slice.actions.resetCustomersAutoComplete());
  };
}
