import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  vechicleTypesList: [],
  count: 0,
  vehicleTypeSuccessMessage: ''
};

const slice = createSlice({
  name: 'vehicleTypes',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getVehiclesListSuccess(state, action) {
      state.vechicleTypesList = action.payload.vehicles;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    vehicleTypeSuccess(state, action) {
      state.vehicleTypeSuccessMessage = action.payload;
    },

    startDeleteLoading(state, action) {
      state.vechicleTypesList = state.vechicleTypesList.filter(
        (v) => v._id != action.payload
      );
    }
  }
});

export default slice.reducer;

export function getVehiclesList(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/vehicleTypes/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getVehiclesListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteVehicle(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/vehicleTypes/${id}`);
      dispatch(slice.actions.vehicleTypeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editVehicle(id, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/vehicleTypes/${id}`, body, {
        toastMessage: 'Vehicle is updated successfully'
      });
      dispatch(slice.actions.vehicleTypeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addVehicle(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post(`/vehicleTypes`, body, {
        toastMessage: 'Vehicle is added successfully'
      });
      dispatch(slice.actions.vehicleTypeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
