import React from 'react';
import { ThemeConfig } from './theme';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import { store, persistor } from './store/store';
import routes, { renderRoutes } from 'src/routes';
import ScrollToTop from 'src/components/ScrollToTop';
import LoadingScreen from 'src/components/LoadingScreen';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { PersistGate } from 'redux-persist/lib/integration/react';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import history from './utils/history';
import JwtProvider from 'src/components/Auth/JwtProvider';
import { SocketListeners } from './components/SocketListeners';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { AudioPlayer } from './components/AudioPlayer';
// const history = createBrowserHistory();

function App() {
  const toastifyOptions = {
    limit: 1
  };
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <ThemeConfig>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ToastContainer {...toastifyOptions} />
            <SocketListeners />
            <AudioPlayer />
            <Router history={history}>
              <JwtProvider>
                <ScrollToTop />
                {renderRoutes(routes)}
              </JwtProvider>
            </Router>
          </LocalizationProvider>
        </ThemeConfig>
      </PersistGate>
    </Provider>
  );
}

export default App;
