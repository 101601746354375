import React from 'react';
import ReactDOM from 'react-dom';
import 'lazysizes';
// import 'src/api';
import 'src/utils/i18n';
import 'src/utils/highlight';
import './global.css';
import App from './App';
import { SentryUtils } from './utils/sentry-utils';

window.React = React;

SentryUtils.initialize();

ReactDOM.render(<App />, document.getElementById('root'));
