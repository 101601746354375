import { Autocomplete, TextField } from '@material-ui/core';

export const ShopTypeOptions = {
  All: { name: 'All', value: 'all' },
  Vendor: {
    name: 'Vendor',
    value: 'VENDOR'
  },
  GeneralShop: {
    name: 'General Shop',
    value: 'GENERAL_SHOP'
  },
  Package: {
    name: 'Package delivery',
    value: 'PACKAGE'
  },
  Purchase: {
    name: 'Purchase',
    value: 'PURCHASE'
  }
};

const options = [
  ShopTypeOptions.All,
  ShopTypeOptions.Vendor,
  ShopTypeOptions.GeneralShop
  // ShopTypeOptions.Package,
  // ShopTypeOptions.Purchase
];

export function ShopTypeFilter({ onChange, defaultValue, ...rest }) {
  return (
    <Autocomplete
      disablePortal
      filterSelectedOptions
      name="shopType"
      onChange={(_, v) => onChange(v)}
      renderInput={(params) => <TextField {...params} label="Shop Type" />}
      getOptionLabel={(option) => option.name}
      options={options}
      {...rest}
    />
  );
}
