import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import apiInstance, { unProtectedApiInstance } from 'src/utils/api';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  resetTokenSent: false,
  isSuperAdmin: false,
  user: {},
  errors: {},
  isSuccess: false,
  currentRolePermissions: {},
  permissionList: {}
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.errors = action.payload;
    },

    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;

      if (action.payload?.user?.user?._id) {
        let userObj = action.payload.user?.user;
        delete userObj['_id'];
        delete userObj['createdAt'];
        delete userObj['updatedAt'];
        state.user = {
          ...state.user,
          ...userObj
        };
      }

      state.currentRolePermissions = action.payload.permissions;
      state.isSuccess = true;
      state.permissionList = action.payload.permissions;
    },

    loginSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;

      if (action.payload.user?.user?._id) {
        let userObj = action.payload.user?.user;
        delete userObj['_id'];
        delete userObj['createdAt'];
        delete userObj['updatedAt'];
        state.user = {
          ...state.user,
          ...userObj
        };
      }
      state.currentRolePermissions = action.payload.permissions;
      state.isSuccess = true;
      state.permissionList = action.payload.permissions;
    },

    updatedSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    userUpdatedSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
    },

    markAsSuperAdmin(state, action) {
      state.isSuperAdmin = true;
    },

    currentRolePermissionsSuccess(state, action) {
      state.currentRolePermissions = action.payload.permissions;
    },

    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.currentRolePermissions = {};
      state.permissionList = [];
      state.user = null;
    },

    resetPasswordTokenSentSuccess(state) {
      state.resetTokenSent = true;
    }
  }
});

export default slice.reducer;

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export function login({ email, password }) {
  return async (dispatch) => {
    try {
      const response = await unProtectedApiInstance.post(
        '/login',
        {
          email,
          password
        },
        {
          toastMessage: 'Logged in successfully'
        }
      );

      const { user, token, permissions } = response.data.data;

      setSession(token);
      // const permissions = await getCurrentUserPermissions(user.roleId);
      console.log('[[[Admin Login]]] - Permissions List:', permissions);
      if (
        user &&
        token &&
        (email === 'superadmin@travelyalla.com' || email === 'areej@haader.com')
      ) {
        console.log('here');
        dispatch(slice.actions.markAsSuperAdmin());
        dispatch(
          slice.actions.loginSuccess({
            user,
            permissions
          })
        );
      } else if (
        user &&
        token &&
        email !== 'superadmin@travelyalla.com' &&
        email !== 'areej@haader.com'
      ) {
        dispatch(slice.actions.loginSuccess({ user, permissions }));
      } else {
        const error = response.errors.name;
        dispatch(slice.actions.hasError(error));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.name));
    }
  };
}

export function logout() {
  return async (dispatch) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

export function forgetPassword(email) {
  return async (dispatch) => {
    const response = await unProtectedApiInstance.post('/password/forget', {
      email
    });
    console.log('reset response', response);
    const { status } = response.data;
    if (status) {
      dispatch(slice.actions.resetPasswordTokenSentSuccess());
    }
  };
}

export function resetPassword(token, { password, confirmPassword }) {
  return async (dispatch) => {
    console.log('reset password', token, password, confirmPassword);
    const response = await unProtectedApiInstance.put(
      `/password/reset/${token}`,
      {
        password,
        confirmPassword
      }
    );
    console.log('reset response', response);
    const { status } = response.data;
    if (status) {
      dispatch(slice.actions.resetPasswordTokenSentSuccess());
    }
  };
}

const decodeToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  return decoded.id;
};

export function updateProfile(id, body) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`/admins/${id}`, body, {
        toastMessage: 'Profile is updated successfully'
      });
      if (response.data.status) {
        dispatch(getInitialize());
        // dispatch(slice.actions.updatedSuccess(response.data.user));
        dispatch(slice.actions.userUpdatedSuccess());
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateUserRoles(values) {
  console.log('[[[[Update User Roles]]]] - values:', values);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.patch('/profile/me/update', values, {
        toastMessage: 'Password is changed successfully'
      });
      if (response.data.status) {
        dispatch(getInitialize());
        // dispatch(slice.actions.updatedSuccess(response.data.user));
        dispatch(slice.actions.userUpdatedSuccess());
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function getInitialize() {
  console.log('initializing.......');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const token = await localStorage.getItem('accessToken');
      const id = decodeToken(token);

      const response = await apiInstance.get(`/profile/me/${id}`);
      let user = response.data.data.user;
      const permissions = response.data.data.permissions;
      // const permissions = await getCurrentUserPermissions(user.roleId);
      console.log('[[[Get Initialized]]] - user role:', user.roleId);
      console.log('[[[Get Initialized]]] - user permissions:', permissions);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: true,
          user,
          permissions
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}

const getCurrentUserPermissions = async (userRoleState) => {
  let output = {};
  if (userRoleState.length > 1) {
    console.log('first');
    let userRoleState1 = userRoleState && userRoleState[0]?.role?.permissions,
      userRoleState2 = userRoleState && userRoleState[1]?.role?.permissions;

    for (let key in userRoleState1) {
      if (!userRoleState2[key]) {
        userRoleState2[key] = userRoleState1[key];
      } else {
        userRoleState2[key] = [
          ...new Set([...userRoleState2[key], ...userRoleState1[key]])
        ];
      }
    }
    console.log('second ');
    for (let key in userRoleState2) {
      if (userRoleState2[key].length > 0) {
        output[key] = userRoleState2[key];
      }
    }
  } else {
    console.log('third');
    let userRoleState2 = userRoleState && userRoleState[0]?.role?.permissions;
    for (let key in userRoleState2) {
      console.log('one');
      if (userRoleState2[key].length > 0) {
        output[key] = userRoleState2[key];
      }
    }
  }

  console.log('outpppppput@', output);

  return await output;
};
