import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { store } from '../store';

const initialState = {
  isLoading: false,
  outOfCoverageList: [],
  outOfCoverageArea: null,
  count: 0,
  page: 0,
  perPage: 10
};

const slice = createSlice({
  name: 'outOfCoverageAreas',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    setPage(state, action) {
      state.page = action.payload;
    },

    setPerPage(state, action) {
      state.perPage = action.payload;
    },

    outOfCoverageAreasSuccess(state, action) {
      state.isLoading = false;
      state.outOfCoverageList = action.payload.outOfCoverageList;
      state.count = action.payload.count;
    },

    hasError(state, action) {
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function getOutOfCoverageAreas() {
  return (dispatch) => {
    const state = store.getState().outOfCoverageAreas;
    const { page, perPage } = state;
    const { actions } = slice;

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .get(`/outOfCoverage/${page}/${perPage}`)
      .then((response) =>
        dispatch(actions.outOfCoverageAreasSuccess(response.data.data))
      )
      .catch((error) => actions.hasError(error));
  };
}

export function getOutOfCoverageArea(id) {
  return (dispatch) => {
    const state = store.getState().outOfCoverageAreas;
    const { page, perPage } = state;
    const { actions } = slice;

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .get(`/outOfCoverage/${page}/${perPage}`)
      .then((response) =>
        dispatch(actions.outOfCoverageAreasSuccess(response.data.data))
      )
      .catch((error) => actions.hasError(error));
  };
}

export function setPage(page) {
  return (dispatch) => {
    dispatch(slice.actions.setPage(page));
    dispatch(getOutOfCoverageAreas());
  };
}

export function setPerPage(perPage) {
  return (dispatch) => {
    dispatch(slice.actions.setPerPage(perPage));
    dispatch(getOutOfCoverageAreas());
  };
}
