import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { MAvatar } from 'src/theme';
import createAvatar from 'src/utils/createAvatar';
import { Utils } from 'src/utils/utils';

MyAvatar.propTypes = {
  className: PropTypes.string
};

function MyAvatar({ className, ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={user.avatar}
      alt={Utils.username(user)}
      color={user.avatar ? 'default' : createAvatar(Utils.username(user)).color}
      className={className}
      {...other}
    >
      {createAvatar(Utils.username(user)).name}
    </MAvatar>
  );
}

export default MyAvatar;
