import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  isLoading: false,
  error: false,
  citiesList: [],
  count: 0
};

const slice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getCitiesSuccess(state, action) {
      state.citiesList = action.payload.citiesList;
      state.count = action.payload.count;
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

export function getCitiesList(pageNumber, pageSize) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/cities/${pageNumber}/${pageSize}`
      );
      dispatch(slice.actions.getCitiesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
