import {
  Box,
  Button,
  Chip,
  IconButton,
  TableRow,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { StyledTableCell } from 'src/components/common';
import { OrderStatusKeyName } from 'src/constants';
import { PATH_APP } from 'src/routes/paths';
import { formatDateTimeAMPM } from 'src/utils/formatTime';
import { localize } from 'src/utils/localize';
import {
  calculateOrderDurationFromStatus,
  feesValue,
  feesWithInvoice
} from 'src/utils/orderHelpers';
import {
  BreakableSentence,
  PickupNameColumn,
  VisaIndicator
} from '../../orders/OrderListView';
import { CancelRounded, CheckCircle, Comment, Info } from '@material-ui/icons';
import { Utils } from 'src/utils/utils';
import { VerticallyCentered } from 'src/components/Layout/VerticallyCentered';
import { CustomerNameLink } from 'src/components/User/CustomerNameLink';
import { CustomerPhoneLink } from 'src/components/User/CustomerPhoneLink';
import { DriverNameLink } from 'src/components/User/DriverNameLink';
import { DriverPhoneLink } from 'src/components/User/DriverPhoneLink';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

/**
 * @type {Record<string, import('react').CSSProperties>}
 */
const paymentMethodStyle = {
  VISA: {
    borderRadius: '12px',
    textAlign: 'center',
    backgroundColor: '#FF8C00',
    width: '100%'
  },
  CASH: {
    textAlign: 'center',
    width: '100%'
  }
};

export function OrderRow({ order, onInfoClick, onCommentClick }) {
  return (
    <motion.tr
      key={order._id}
      layout
      animate={
        order.__highlight
          ? {
              backgroundColor: [
                '#FFDF00',
                '#ffffff',
                '#FFDF00',
                '#ffffff',
                '#FFDF00',
                '#ffffff'
              ]
            }
          : undefined
      }
    >
      {/* ID */}
      <StyledTableCell>
        <Box
          display="flex"
          flexDirection="column"
          fontWeight="700"
          alignItems="center"
          gap="2px"
        >
          <a
            href={`${PATH_APP.orders.orderView.replace(':id', order._id)}`}
            target="_blank"
            rel="noreferrer"
          >
            #{order.orderNo}
          </a>
          {order.fees?.paymentMethod ? (
            <span style={paymentMethodStyle[order.fees?.paymentMethod] || {}}>
              {order.fees?.paymentMethod}
            </span>
          ) : null}
          {order.fees?.paymentMethod === 'VISA' ? (
            <VisaIndicator order={order} />
          ) : null}
        </Box>
      </StyledTableCell>

      {/* Shop */}
      <StyledTableCell>
        <PickupNameColumn order={order} />
      </StyledTableCell>

      {/* Service Type */}
      {/* <StyledTableCell>
          <ServiceTypeColumn order={order} categoryIdMap={categoryIdMap} />
        </StyledTableCell> */}

      {/* Pick Up - Area */}
      <StyledTableCell>
        <Tooltip placement="top" title="Pick Up Zone">
          <Typography fontSize="14px">
            <BreakableSentence
              target={localize(
                order.pickUpZone?.name?.en,
                order.pickUpZone?.name?.ar
              )}
              characters={['و', 'and']}
              surroundedBy=" "
              breaks={1}
            />
          </Typography>
        </Tooltip>
        <Box width="100%" height="1px" my={1} bgcolor={'#e4e4e4'} />
        <Tooltip placement="bottom" title="Drop Off Zone">
          <Typography fontSize="14px">
            <BreakableSentence
              target={localize(
                order.dropOffZone?.name?.en,
                order.dropOffZone?.name?.ar
              )}
              characters={['و', 'and']}
              surroundedBy=" "
              breaks={1}
            />
          </Typography>
        </Tooltip>
      </StyledTableCell>

      {/* Distance */}
      <StyledTableCell>
        <Box display="flex" flexDirection="column">
          <span>Order: {order.distance} km</span>
          {order.offers?.length ? (
            <span>Driver: {order.offers[0].driverDistance} km</span>
          ) : null}
        </Box>
      </StyledTableCell>

      {/* Promocode */}
      <StyledTableCell>
        {order.promoCode?.code || order.promoCode}
      </StyledTableCell>

      {/* Customer */}
      <StyledTableCell>
        <VerticallyCentered>
          <CustomerNameLink customer={order.customer} />
          <CustomerPhoneLink customer={order.customer} />
        </VerticallyCentered>
      </StyledTableCell>

      {/* Driver */}
      <StyledTableCell>
        <VerticallyCentered>
          <DriverNameLink driver={order.driver} />
          <DriverPhoneLink driver={order.driver} />
        </VerticallyCentered>
      </StyledTableCell>

      {/* Status */}
      <StyledTableCell>
        <span>{OrderStatusKeyName[_.get(_.last(order.status), 'key')]}</span>
      </StyledTableCell>

      {/* Duration */}
      <StyledTableCell>
        <span>Offers: {order.offers?.length}</span>
        <div role="img" title={formatDateTimeAMPM(order.createdAt)}>
          <Box display="flex" flexDirection="column">
            {calculateOrderDurationFromStatus(order.status)}
            <span>{formatDateTimeAMPM(order.createdAt)}</span>
          </Box>
        </div>
      </StyledTableCell>

      {/* Cost */}
      <StyledTableCell
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2px'
        }}
      >
        <Box>
          <Chip
            sx={{
              width: '100%',
              '.MuiChip-label': { marginTop: 'unset' }
            }}
            size="small"
            color="primary"
            label={'Buying: ' + feesWithInvoice(order) || 'N/A'}
            title={'Buying: ' + feesWithInvoice(order) || 'N/A'}
          />
        </Box>
        <Box>
          <Chip
            sx={{
              width: '100%',
              '.MuiChip-label': { marginTop: 'unset' }
            }}
            size="small"
            color="primary"
            label={'Delivery: ' + feesValue(order.fees?.delivery)}
          />
        </Box>
        <Box>
          <Chip
            sx={{
              width: '100%',
              '.MuiChip-label': { marginTop: 'unset' }
            }}
            size="small"
            color="primary"
            label={'Discount: ' + feesValue(order.fees?.discount)}
          />
        </Box>
        <Box>
          <Chip
            sx={{
              width: '100%',
              '.MuiChip-label': { marginTop: 'unset' }
            }}
            size="small"
            color="success"
            label={'Total: ' + feesValue(order.fees?.totalFees)}
          />
        </Box>
      </StyledTableCell>

      <StyledTableCell>
        <IconButton onClick={onInfoClick.bind(null, order)}>
          <Info />
        </IconButton>
        <IconButton onClick={onCommentClick.bind(null, order)}>
          <Comment />
        </IconButton>
      </StyledTableCell>

      {/* <StyledTableCell>
          <IconButton
            aria-label="view"
            component="span"
            onClick={() => {
              //   navigateToOrderView(order);
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <PermissionGuard page={Pages.Orders} action={OptionActionMap['add']}>
            <IconButton
              aria-label="comment"
              component="span"
              onClick={() => {
                // setSelectedOrder(order);
                // setCommentsDialogOpen(order);
              }}
            >
              <Comment />
            </IconButton>
          </PermissionGuard>
        </StyledTableCell> */}
    </motion.tr>
  );
}
