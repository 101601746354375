import { visuallyHidden } from '@material-ui/utils';

const {
  makeStyles,
  withStyles,
  TableCell,
  TableRow
} = require('@material-ui/core');

export const NEW_ORDERS_TABLE_HEAD = [
  { id: '_id', label: '#', alignRight: false },
  { id: 'shop', label: 'Shop', alignRight: false },
  { id: 'area', label: 'Area', alignRight: false },
  { id: 'distance', label: 'Distance', alignRight: false },
  { id: 'promo', label: 'Promo', alignRight: false },
  { id: 'customer.user.firstName', label: 'Customer', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'time', label: 'Time', alignRight: false },
  { id: 'payment', label: 'Payment', alignRight: false },
  { id: 'actions', label: '', alignRight: true }
];

export const ON_GOING_ORDERS_TABLE_HEAD = [
  { id: '_id', label: '#', alignRight: false },
  { id: 'shop', label: 'Shop', alignRight: false },
  { id: 'area', label: 'Area', alignRight: false },
  { id: 'distance', label: 'Distance', alignRight: false },
  { id: 'promo', label: 'Promo', alignRight: false },
  { id: 'customer.user.firstName', label: 'Customer', alignRight: false },
  { id: 'driver.user.firstName', label: 'Driver', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'cost', label: 'Cost', alignRight: false },
  { id: 'actions', label: '', alignRight: true }
];

export const useVendorsOrdersStyles = makeStyles((theme) => ({
  root: {},
  sortSpan: visuallyHidden,
  drawer: {
    zIndex: '1999 !important'
  },
  drawerPaper: {
    width: '60vw',
    minWidth: '1100px'
  },
  card: {
    position: 'relative',
    padding: theme.spacing(3),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3)
    },
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  pagination: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content'
  }
}));

export const StyledTableCell = withStyles((theme) => ({
  root: {
    '&:first-of-type': {
      boxShadow: 'none'
    },
    '&:last-of-type': {
      boxShadow: 'none'
    },
    padding: '10px 10px',
    maxWidth: '160px'
  },
  head: {
    backgroundImage: 'none',
    backgroundColor: theme.palette.common.green,
    color: theme.palette.common.white,
    boxShadow: 'none'
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);
