import _, { isEmpty } from 'lodash';
import Page from 'src/components/Page';
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from 'react';
import { visuallyHidden } from '@material-ui/utils';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Table as MuiTable,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  Pagination,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Chip,
  TablePagination,
  Grid,
  Button,
  TextField,
  Checkbox
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ToolbarTable from './ToolbarTable';
import { useDispatch, useSelector } from 'react-redux';
import HeadTable from './HeadTable';
import { getOrdersList } from '../../../store/slices/order';
import { MCircularProgress } from 'src/theme';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PATH_APP } from 'src/routes/paths';
import { localize } from 'src/utils/localize';
import {
  calculateOrderDurationFromStatus,
  feesValue,
  feesWithInvoice
} from 'src/utils/orderHelpers';
import { formatDateTimeAMPM } from 'src/utils/formatTime';
import {
  applySortFilterByPath,
  getComparatorByPath
} from 'src/utils/tableUtils';
import { getOrderStatusList } from 'src/store/slices/orderStatus';
import { getAreaZonesAutoComplete } from 'src/store/slices/zones';
import { getPromoCodesList } from 'src/store/slices/promoCodes';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  endOfDay,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths
} from 'date-fns';
import { getCategoriesList } from 'src/store/slices/categories';
import { OrderStatusKeyName, OrderStatusKeys, PATTERNS } from 'src/constants';
import { useDidMount } from 'src/hooks';
import { ShopTypeOptions } from 'src/components/Filters/ShopTypeFilter';
import { CancelRounded, CheckCircle, Comment, Send } from '@material-ui/icons';
import { OrderCommentsDrawer } from './OrderCommentsDialog';
import { OptionActionMap, Pages } from 'src/constants';
import { PermissionGuard } from 'src/components/Auth/PermissionGuard';
import { Utils } from 'src/utils/utils';
import { CustomerNameLink } from 'src/components/User/CustomerNameLink';
import { CustomerPhoneLink } from 'src/components/User/CustomerPhoneLink';
import { VerticallyCentered } from 'src/components/Layout/VerticallyCentered';
import { DriverNameLink } from 'src/components/User/DriverNameLink';
import { DriverPhoneLink } from 'src/components/User/DriverPhoneLink';
import { StyledTableCell } from 'src/components/common';
import { AnimatePresence, motion } from 'framer-motion';
import { DriverFilter } from 'src/components/Filters/DriverFilter';

const TABLE_HEAD = [
  { id: 'selection', label: '', alignRight: false },
  { id: '_id', label: 'id', alignRight: false },
  { id: 'type', label: 'type', alignRight: false },
  { id: 'pickUp', label: 'pickUp', alignRight: false },
  // { id: 'dropOff', label: 'dropOff', alignRight: false },
  { id: 'description', label: 'description', alignRight: false },
  { id: 'distance', label: 'distance', alignRight: false },
  { id: 'promo', label: 'promo', alignRight: false },
  { id: 'location', label: 'pickupName', alignRight: false },
  { id: 'customer.user.firstName', label: 'customer', alignRight: false },
  { id: 'driver.user.firstName', label: 'driver', alignRight: false },
  // { id: 'offers', label: 'offers', alignRight: false },
  // { id: 'status', label: 'status', alignRight: false },
  { id: 'duration', label: 'duration', alignRight: false },
  { id: 'cost', label: 'cost', alignRight: false },

  { id: 'actions', label: '', alignRight: false }
];

const useStyles = makeStyles((theme) => ({
  root: {},
  sortSpan: visuallyHidden,
  drawer: {
    zIndex: '1999 !important'
  },
  drawerPaper: {
    width: 800,
    padding: 30
  },
  card: {
    position: 'relative',
    padding: theme.spacing(3),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3)
    },
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  pagination: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content'
  }
}));

const StyledTableHeadCell = withStyles((theme) => ({
  root: {
    '&:first-of-type': {
      boxShadow: 'none'
    },
    '&:last-of-type': {
      boxShadow: 'none'
    },
    padding: '10px 10px',
    maxWidth: '160px'
    // border: '1px solid #e4e4e4'
  },
  head: {
    backgroundImage: 'none',
    backgroundColor: theme.palette.common.green,
    color: theme.palette.common.white,
    boxShadow: 'none'
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

/**
 * @type {Record<string, import('react').CSSProperties>}
 */
const paymentMethodStyle = {
  VISA: {
    borderRadius: '12px',
    textAlign: 'center',
    backgroundColor: '#FF8C00',
    width: '100%'
  },
  CASH: {
    textAlign: 'center',
    width: '100%'
  }
};

function EditBar() {
  return (
    <motion.div
      initial={{ y: '-100%' }}
      animate={{ y: '0%', boxShadow: '0px 0px 16px darkgray' }}
      exit={{ y: '-100%' }}
      transition={{ ease: 'easeInOut' }}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        borderBottom: '1px solid #e4e4e4',
        width: '100%',
        height: '64px',
        zIndex: 9999
      }}
    >
      <Box maxWidth="300px">
        <DriverFilter />
      </Box>
    </motion.div>
  );
}

function OrderListView({ location }) {
  const { t, i18n } = useTranslation();
  const OPTIONS_MENU = ['view', 'comment'];
  const classes = useStyles();
  const dispatch = useDispatch();
  const mounted = useDidMount();
  const searchRef = useRef(null);

  const [filterName, setFilterName] = useState('');
  const [commentsDialogOpen, setCommentsDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [shopType, setShopType] = useState(ShopTypeOptions.All);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const { categoriesList } = useSelector((state) => state.category);
  const { ordersList, isLoading, count, pagesCount } = useSelector(
    (state) => state.order
  );
  const [selectedRow, setSelectedRow] = useState([]);
  const [showEditBar, setShowEditBar] = useState(false);
  const [selectedOrdersMap, setSelectedOrdersMap] = useState({});
  const [verticalMenu, setVerticalMenu] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState({});
  const history = useHistory();
  const [selectedFromDate, setSelectedFromDate] = useState(
    startOfDay(subDays(new Date(), 1))
  );
  const [selectedToDate, setSelectedToDate] = useState(endOfDay(new Date()));
  const [editMode, setEditMode] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [driver, setDriver] = useState(null);
  const [brand, setBrand] = useState(null);
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);
  const [typeFilter, setTypeFilter] = useState(null);
  const [goTo, setGoTo] = useState(1);

  // Workaround trigger to cause getOrders to run in useEffect with resetted filter values
  const [_clearedFilter, _setClearedFilter] = useState(null);

  const categoryIdMap = useMemo(() => {
    const map = new Map();

    for (const c of categoriesList) {
      map.set(c._id, c);
    }

    return map;
  }, [categoriesList]);

  const selectOrder = useCallback((order) => {
    setSelectedOrdersMap((map) => ({ ...map, [order._id]: order }));
  }, []);

  const unselectOrder = useCallback((order) => {
    setSelectedOrdersMap((map) => {
      const nMap = { ...map };
      delete nMap[order._id];
      return nMap;
    });
  }, []);

  useEffect(() => {
    setShowEditBar(isEmpty(selectedOrdersMap) === false);
  }, [selectedOrdersMap]);

  const clearFilters = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setSearchText('');
    setFilteredZones([]);
    setFilteredStatus([]);
    setSelectedPromo('');
    setShopType(ShopTypeOptions.All);
    setSelectedToDate(null);
    setSelectedFromDate(null);
    setFilterName('');
    setTypeFilter(null);
    setCustomer(null);
    setDriver(null);
    setBrand(null);
    _setClearedFilter((prev) => (prev == null ? true : !prev));
  };
  const handleRemoveStatus = (key) => {
    const statusList = filteredStatus.filter((status) => status.key != key);
    setFilteredStatus(statusList);
  };

  const addFilterStatus = (statuses) => {
    setFilteredStatus(statuses);
  };

  const handleRemoveZone = (id) => {
    const zonesList = filteredZones.filter((zone) => zone._id != id);
    setFilteredZones(zonesList);
  };

  const addFilterZone = (zones) => {
    setFilteredZones(zones);
  };
  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
  };

  const handleToDateChange = (date) => {
    setSelectedToDate(date);
  };

  const handleSelectedZoneChange = (event) => {
    setSelectedZone(event.target.value);
  };

  const handleSelectedPromoChange = (promo) => {
    setSelectedPromo(promo);
  };

  const handleTypeChange = (type) => {
    setTypeFilter(type);
  };

  const handleGoTo = () => {
    let safeGoTo = Math.max(1, Math.min(goTo, pagesCount));

    if (safeGoTo !== goTo) {
      setGoTo(safeGoTo);
    }
    setPage(safeGoTo);
  };

  const handleGoToChange = (e) => {
    setGoTo(e.target.value);
  };

  const handleEnterGoTo = (e) => {
    if (e.key === 'Enter') {
      handleGoTo();
    }
  };

  const handleCustomerChange = useCallback((customer) => {
    setCustomer(customer);
  }, []);

  const handleDriverChange = useCallback((driver) => {
    setDriver(driver);
  }, []);

  const handleBrandChange = useCallback((brand) => {
    setBrand(brand);
  }, []);

  const getOrderFilters = () => {
    const filter = {
      searchText,
      customerId: customer?._id || undefined,
      driverId: driver?._id || undefined,
      brandId: brand?._id || undefined,
      pageNumber: page,
      pageSize: rowsPerPage,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      orderType: shopType.value !== 'all' ? shopType.value : undefined,
      filteredStatus: filteredStatus.map((s) => s.key),
      filteredZones: filteredZones.map((z) => z._id),
      promoCode: selectedPromo?._id
    };

    switch (typeFilter?.type) {
      case 'category':
        filter.categoryId = typeFilter.id;
        break;

      case 'service':
        filter.orderType = typeFilter.id;
        break;

      default:
        break;
    }

    return filter;
  };
  const getOrders = (filters) => {
    dispatch(getOrdersList(filters || getOrderFilters()));
  };
  useEffect(() => {
    const filters = {};
    const params = Object.fromEntries(new URLSearchParams(location.search));

    if (params.dateRange === 'last_month') {
      const fromDate = subMonths(startOfMonth(new Date()), 1);
      const toDate = endOfDay(subDays(startOfMonth(new Date()), 1));

      setSelectedFromDate(fromDate);
      setSelectedToDate(toDate);

      filters.fromDate = fromDate;
      filters.toDate = toDate;
    }

    if (params.status) {
      filters.filteredStatus = [params.status.toUpperCase()];
    }

    getOrders(_.isEmpty(filters) ? null : filters);
  }, [dispatch, rowsPerPage, page]);

  useEffect(() => {
    dispatch(getOrderStatusList({ vendorOrders: false }));
    // dispatch(getAreaZonesAutoComplete());
    // dispatch(getPromoCodesList(0, 1000));
    dispatch(getCategoriesList(0, 1000));
  }, [dispatch]);

  useEffect(() => {
    if (_clearedFilter == null) {
      return;
    }
    getOrders();
  }, [_clearedFilter]);
  useEffect(() => {
    if (mounted) {
      getOrders();
    }
  }, [searchText]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    const rowsPerPage = parseInt(e.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const closeActionsMenu = () => {
    setVerticalMenu(null);
  };

  const navigateToOrderView = (order) => {
    window.open(`${PATH_APP.orders.root}/${order._id}`, '_blank');
  };

  const onActionClick = (action, order) => {
    if (action.id === 'view') {
      navigateToOrderView(order);
    }
  };

  const handleOrderAction = (option) => {
    if (option === 'view') {
      const order =
        ordersList && ordersList.find((o) => o._id == selectedRow[0]);
      setSelectedOrder(order);
      closeActionsMenu();
      navigateToOrderView(order);
    } else {
      setEditMode(true);
    }
  };

  const [selectedHoverd, setSelectedHoverd] = useState('');
  const handleClickRow = (rowId) => {
    setSelectedHoverd(rowId);
  };

  return (
    <Page sx={{ width: '100%' }} title="Orders" className={classes.root}>
      <AnimatePresence exitBeforeEnter>
        {showEditBar ? <EditBar /> : null}
      </AnimatePresence>
      <Card className={classes.card} style={{ overflow: 'visible', zIndex: 2 }}>
        <ToolbarTable
          onSearchTextChange={setSearchText}
          shopType={shopType}
          onShopTypeChange={setShopType}
          selectedToDate={selectedToDate}
          selectedFromDate={selectedFromDate}
          onCustomerChange={handleCustomerChange}
          onDriverChange={handleDriverChange}
          onBrandChange={handleBrandChange}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          getOrdersList={getOrders}
          addFilterStatus={addFilterStatus}
          handleRemoveStatus={handleRemoveStatus}
          handleSelectedZoneChange={handleSelectedZoneChange}
          handleSelectedPromoChange={handleSelectedPromoChange}
          handleTypeChange={handleTypeChange}
          typeFilter={typeFilter}
          selectedPromo={selectedPromo}
          selectedZone={selectedZone}
          filteredStatus={filteredStatus}
          getOrderFilters={getOrderFilters}
          handleRemoveZone={handleRemoveZone}
          addFilterZone={addFilterZone}
          filteredZones={filteredZones}
          clearFilters={clearFilters}
          customer={customer}
          driver={driver}
          brand={brand}
          searchRef={searchRef}
        />
      </Card>
      <Card className={classes.card}>
        {/* <Table
          sx={{
            maxHeight: 'calc(100vh - 300px)',
            minWidth: 12,
            mb: 5,
            whiteSpace: 'nowrap'
          }}
          columns={[
            {
              name: '#',
              Component: ({ _id }) => {
                return `#${_id.substr(_id.length - 5, _id.length - 1)}`;
              }
            },
            {
              name: 'Type',
              Component: ({ type, orderDetails }) => {
                return (
                  <>
                    {t(`orders.tablesHead.${type}`)}{' '}
                    {type === 'SERVICE'
                      ? ' | ' + orderDetails?.brand?.name?.ar
                      : null}
                  </>
                );
              }
            },
            { name: 'Pick-up', key: 'pickUpZone.name', localize: true },
            { name: 'Drop-off', key: 'dropOffZone.name', localize: true },
            {
              name: 'Description',
              Component: ({ orderDetails }) => {
                return (
                  <Tooltip
                    placement="top-start"
                    title={orderDetails?.notes?.trim() || ''}
                  >
                    <Typography noWrap width={200}>
                      {orderDetails?.notes?.trim() || ''}
                    </Typography>
                  </Tooltip>
                );
              }
            },
            {
              name: 'Distance',
              key: 'distance'
            },
            { name: 'Promo', key: 'promoCode.code' },
            { name: 'Location', key: 'orderDetails.address.name' },
            {
              name: 'Customer',
              Component: ({ customer }) => {
                return (
                  <Tooltip placement="top-start" title={customer?.phone}>
                    <a
                      href={`${PATH_APP.customers.root}/${customer?._id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {customer?.user?.name}
                    </a>
                  </Tooltip>
                );
              }
            },
            {
              name: 'Driver',
              Component: ({ driver }) => {
                return (
                  <Tooltip placement="top-start" title={driver?.phone}>
                    <a
                      href={`${PATH_APP.drivers.root}/${driver?._id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {driver?.user?.name}
                    </a>
                  </Tooltip>
                );
              }
            },
            { name: 'Offers', key: 'offers.length' },
            {
              name: 'Status',
              Component: ({ status }) => {
                return status?.length
                  ? localize(
                      _.last(status)?.name?.en,
                      _.last(status)?.name?.ar,
                      i18n.language
                    )
                  : '';
              }
            },
            {
              name: 'Duration',
              Component: ({ createdAt, status }) => {
                return (
                  <div role="img" title={formatDateTimeAMPM(createdAt)}>
                    {calculateOrderDurationFromStatus(status)}
                  </div>
                );
              }
            },
            {
              name: 'Cost',
              Component: ({ fees }) => {
                return (
                  <>
                    <Box mb={1}>
                      <Chip
                        size="small"
                        color="primary"
                        label={'Buying: ' + feesValue(fees?.orderFees)}
                      />
                    </Box>
                    <Box mb={1}>
                      <Chip
                        size="small"
                        color="primary"
                        label={'Delivery: ' + feesValue(fees?.delivery)}
                      />
                    </Box>
                    <Box mb={1}>
                      <Chip
                        size="small"
                        color="primary"
                        label={'Discount: ' + feesValue(fees?.discount)}
                      />
                    </Box>
                    <Box>
                      <Chip
                        size="small"
                        color="success"
                        label={
                          'Total: ' +
                          calculateOrderTotalAmount(
                            fees?.orderFees,
                            fees?.delivery,
                            fees?.discount
                          )
                        }
                      />
                    </Box>
                  </>
                );
              }
            }
          ]}
          actions={[{ name: t('app.view'), id: 'view' }]}
          onActionClick={onActionClick}
          actionsType="icons"
          data={ordersList}
          loading={isLoading}
          variant="haader"
        /> */}
        <TableContainer
          sx={{
            whiteSpace: 'nowrap',
            textAlign: 'center'
          }}
          component={Paper}
        >
          <MuiTable
            aria-label="sticky table"
            className={classes.table}
            stickyHeader
          >
            <HeadTable
              StyledTableCell={StyledTableHeadCell}
              order={order}
              classes={classes}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={20}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {!isLoading &&
                ordersList &&
                ordersList.map((row) => (
                  // <Row
                  // key={row._id}
                  //   row={row}
                  //   navigateToOrderView={navigateToOrderView}
                  //   handleClickRow={handleClickRow}
                  //   selectedRow={selectedHoverd}
                  // />
                  <StyledTableRow
                    key={row._id}
                    onClick={() => handleClickRow(row._id)}
                    selected={row._id === selectedRow}
                    className={classes.root}
                  >
                    {/* Selection Checkbox */}
                    <StyledTableCell>
                      <Checkbox
                        checked={selectedOrdersMap[row._id] != null}
                        onChange={(e) => {
                          const checked = e.target.checked;

                          if (checked) {
                            selectOrder(row);
                          } else {
                            unselectOrder(row);
                          }
                        }}
                      />
                    </StyledTableCell>

                    {/* ID */}
                    <StyledTableCell>
                      <Box
                        display="flex"
                        flexDirection="column"
                        fontWeight="700"
                        alignItems="center"
                        gap="2px"
                      >
                        <span>{`#${row.orderNo}`}</span>
                        {row.fees?.paymentMethod ? (
                          <span
                            style={
                              paymentMethodStyle[row.fees?.paymentMethod] || {}
                            }
                          >
                            {row.fees?.paymentMethod}
                          </span>
                        ) : null}
                        {order.fees?.paymentMethod === 'VISA' ? (
                          <VisaIndicator order={order} />
                        ) : null}
                      </Box>
                    </StyledTableCell>

                    {/* Service Type */}
                    <StyledTableCell>
                      <ServiceTypeColumn
                        order={row}
                        categoryIdMap={categoryIdMap}
                      />
                    </StyledTableCell>

                    {/* Pick Up */}
                    <StyledTableCell>
                      <Tooltip placement="top" title="Pick Up Zone">
                        <Typography fontSize="14px">
                          <BreakableSentence
                            target={localize(
                              row.pickUpZone?.name?.en,
                              row.pickUpZone?.name?.ar
                            )}
                            characters={['و', 'and']}
                            surroundedBy=" "
                            breaks={1}
                          />
                        </Typography>
                      </Tooltip>
                      <Box width="100%" height="1px" my={1} bgcolor={'#e4e4e4'} />
                      <Tooltip placement="bottom" title="Drop Off Zone">
                        <Typography fontSize="14px">
                          <BreakableSentence
                            target={localize(
                              row.dropOffZone?.name?.en,
                              row.dropOffZone?.name?.ar
                            )}
                            characters={['و', 'and']}
                            surroundedBy=" "
                            breaks={1}
                          />
                        </Typography>
                      </Tooltip>
                    </StyledTableCell>

                    {/* Description */}
                    <StyledTableCell>
                      <OrderDetailsColumn order={row} />
                    </StyledTableCell>

                    {/* Distance */}
                    <StyledTableCell>
                      <Box display="flex" flexDirection="column">
                        <span>Order: {row.distance} km</span>
                        {row.offers?.length ? (
                          <span>Driver: {row.offers[0].driverDistance} km</span>
                        ) : null}
                      </Box>
                    </StyledTableCell>

                    {/* Promocode */}
                    <StyledTableCell>
                      {row.promoCode?.code || row.promoCode}
                    </StyledTableCell>

                    {/* Location/Pick Up Name */}
                    <StyledTableCell>
                      <PickupNameColumn order={row} />
                    </StyledTableCell>

                    {/* Customer */}
                    <StyledTableCell>
                      <VerticallyCentered>
                        <CustomerNameLink customer={row.customer} />
                        <CustomerPhoneLink customer={row.customer} />
                      </VerticallyCentered>
                    </StyledTableCell>

                    {/* Driver */}
                    <StyledTableCell>
                      <VerticallyCentered>
                        <DriverNameLink driver={row.driver} />
                        <DriverPhoneLink driver={row.driver} />
                      </VerticallyCentered>
                    </StyledTableCell>

                    {/* Duration */}
                    <StyledTableCell style={{ textAlign: 'start' }}>
                      <Box display="flex" flexDirection="column">
                        <span style={{ fontWeight: 'bold' }}>
                          {OrderStatusKeyName[_.get(_.last(row.status), 'key')]}
                        </span>
                        <span>Offers: {row.offers?.length}</span>
                        <div
                          role="img"
                          title={formatDateTimeAMPM(row.createdAt)}
                        >
                          <Box display="flex" flexDirection="column">
                            {calculateOrderDurationFromStatus(row.status)}
                            <span>{formatDateTimeAMPM(row.createdAt)}</span>
                          </Box>
                        </div>
                      </Box>
                    </StyledTableCell>

                    {/* Cost */}
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '2px',
                          height: '100%'
                        }}
                      >
                        <Box>
                          <Chip
                            className={classes.chip}
                            sx={{
                              width: '100%',
                              '.MuiChip-label': { marginTop: 'unset' }
                            }}
                            size="small"
                            color="primary"
                            label={'Buying: ' + feesWithInvoice(row) || 'N/A'}
                            title={'Buying: ' + feesWithInvoice(row) || 'N/A'}
                          />
                        </Box>
                        <Box>
                          <Chip
                            sx={{
                              width: '100%',
                              '.MuiChip-label': { marginTop: 'unset' }
                            }}
                            size="small"
                            color="primary"
                            label={'Delivery: ' + feesValue(row.fees?.delivery)}
                          />
                        </Box>
                        <Box>
                          <Chip
                            sx={{
                              width: '100%',
                              '.MuiChip-label': { marginTop: 'unset' }
                            }}
                            size="small"
                            color="primary"
                            label={'Discount: ' + feesValue(row.fees?.discount)}
                          />
                        </Box>
                        <Box>
                          <Chip
                            sx={{
                              width: '100%',
                              '.MuiChip-label': { marginTop: 'unset' }
                            }}
                            size="small"
                            color="success"
                            label={'Total: ' + feesValue(row.fees?.totalFees)}
                          />
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        aria-label="view"
                        component="span"
                        onClick={() => {
                          navigateToOrderView(row);
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <PermissionGuard
                        page={Pages.Orders}
                        action={OptionActionMap['add']}
                      >
                        <IconButton
                          aria-label="comment"
                          component="span"
                          onClick={() => {
                            setSelectedOrder(row);
                            setCommentsDialogOpen(row);
                          }}
                        >
                          <Comment />
                        </IconButton>
                      </PermissionGuard>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            {isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={TABLE_HEAD.length}>
                    <Box
                      sx={{
                        py: 3
                      }}
                    >
                      <MCircularProgress color="info" />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </MuiTable>
        </TableContainer>
        {/* <TablePagination
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
        /> */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="20px"
        >
          <span>{count} Orders</span>
          <Pagination
            count={pagesCount}
            color="primary"
            page={page || 1}
            onChange={handleChangePage}
            className={classes.pagination}
          />
          <TextField
            id="goto"
            label="Go to"
            value={goTo}
            onChange={handleGoToChange}
            onKeyDown={handleEnterGoTo}
            InputProps={{
              sx: {
                width: '125px'
              },
              endAdornment: (
                <IconButton onClick={handleGoTo}>
                  <Send />
                </IconButton>
              )
            }}
          />
        </Box>
      </Card>
      <OrderCommentsDrawer
        open={commentsDialogOpen}
        onClose={() => setCommentsDialogOpen(false)}
        order={selectedOrder}
      />
      <Menu
        keepMounted
        id="long-menu"
        anchorEl={verticalMenu}
        onClose={closeActionsMenu}
        open={Boolean(verticalMenu)}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch'
          }
        }}
      >
        {OPTIONS_MENU.map((option) => (
          <MenuItem
            key={option}
            selected={option === OPTIONS_MENU[1]}
            onClick={() => handleOrderAction(option)}
          >
            {t(`app.${option}`)}
          </MenuItem>
        ))}
      </Menu>
    </Page>
  );
}

export function VisaIndicator({ order }) {
  const { currentStatus, fees } = order;

  if (currentStatus === OrderStatusKeys.PENDING_PAYMENT) {
    return null;
  }

  if (fees?.paymentPaid) {
    return <CheckCircle style={{ color: '#8DD345' }} />;
  }

  return <CancelRounded style={{ color: '#DE0000' }} />;
}

export function ServiceTypeColumn({ order, categoryIdMap }) {
  const { t } = useTranslation();
  const { type, orderDetails } = order;

  if (type !== 'SERVICE') {
    return t(`orders.tablesHead.${type}`);
  }

  // let brandName = _.get(orderDetails, 'brand.name.en', '');
  let categoryName = _.get(orderDetails, 'brand.category.name.en');

  if (categoryName == null) {
    const categoryId = _.get(orderDetails, 'brand.category');
    const category = categoryIdMap.get(categoryId);
    categoryName = _.get(category, 'name.en', 'Categories');
  }

  return (
    <Box>
      <Typography variant="caption" fontWeight="bold">
        {categoryName}
      </Typography>
      {/* <Typography variant="body2">{brandName}</Typography> */}
    </Box>
  );
}

export function PickupNameColumn({ order, minimalView }) {
  const history = useHistory();

  if (!order) {
    return null;
  }

  const { orderType, orderDetails } = order || {};
  if (['GENERAL_SHOP', 'VENDOR'].includes(orderType)) {
    const brand = _.get(orderDetails, 'brand');
    const branch = _.get(orderDetails, 'branch');
    return (
      <Box
        display="flex"
        flexDirection={minimalView ? 'row' : 'column'}
        alignItems="center"
        gap="5px"
      >
        <Box display="flex" alignItems="center" gap="5px">
          <img
            src={brand?.logo}
            className="lazy"
            style={{ maxWidth: '50px', borderRadius: '5px' }}
            alt="brand logo"
          />
        </Box>
        <Box>
          <Button
            onClick={() => {
              window.open(
                `${PATH_APP.brands.brandInfo}?id=${brand?._id}`,
                '_blank'
              );
            }}
          >
            {localize(brand?.name?.en, brand?.name?.ar) || ''}
          </Button>
        </Box>
        {!minimalView ? (
          <Box>
            <Button disabled>
              {localize(branch?.name?.en, branch?.name?.ar) || ''}
            </Button>
          </Box>
        ) : null}
      </Box>
    );
  }

  const pickupName = order.pickUp?.title || '';
  let direction = 'ltr';

  if (PATTERNS.CONTAINS_ARABIC.test(pickupName)) {
    direction = 'rtl';
  }

  return (
    <Tooltip placement="top-start" title={pickupName}>
      <Typography noWrap maxWidth={250} sx={{ direction }}>
        {pickupName}
      </Typography>
    </Tooltip>
  );
}

export function OrderDetailsColumn({ order }) {
  const { orderType, orderDetails } = order;
  const content = [];
  let visibleContent = '';
  if (orderType !== 'VENDOR') {
    const notes = orderDetails?.notes?.trim() || '';
    visibleContent += notes;
    content.push(notes);
  } else {
    for (const item of orderDetails?.items || []) {
      const itemName = localize(item.name?.en, item.name?.ar) || '';

      visibleContent += itemName;
      content.push(itemName);

      for (const extra of item?.extras || []) {
        for (const choice of extra?.choices || []) {
          const choiceName = localize(choice?.name?.en, choice?.name?.ar) || '';

          visibleContent += choiceName;
          content.push(<p>{choiceName}</p>);
        }
      }
    }
  }

  let direction = 'ltr';

  if (PATTERNS.CONTAINS_ARABIC.test(visibleContent)) {
    direction = 'rtl';
  }

  return (
    <Tooltip
      placement="top-start"
      title={content.map((c) => (
        <>
          {c}
          <br />
        </>
      ))}
    >
      <Typography noWrap maxWidth={250} sx={{ direction }}>
        {visibleContent}
      </Typography>
    </Tooltip>
  );
}

export function BreakableSentence({
  target = '',
  characters,
  surroundedBy,
  breaks
}) {
  const sentences = [];

  for (const character of characters) {
    const pattern = new RegExp(`${surroundedBy}${character}${surroundedBy}`);

    if (pattern.test(target) === false) {
      continue;
    }

    const words = target.split(pattern);

    for (let index = 0; index < breaks; index++) {
      sentences.push(...words.splice(0, 1));
    }

    if (words.length > 0) {
      sentences.push(words.join(character));
    }
  }

  if (sentences.length === 0) {
    sentences.push(target);
  }

  return (
    <Box display="flex" flexDirection="column">
      {sentences.map((s) => (
        <span>{s}</span>
      ))}
    </Box>
  );
}

export default OrderListView;
