import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Slide,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  TextField
} from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import { debounce, get } from 'lodash';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableCell } from 'src/components/common';
import { PATH_APP } from 'src/routes/paths';
import { getOnlineDrivers, getdriversList } from 'src/store/slices/drivers';
import { getOrderChat, updateOrderDriver } from 'src/store/slices/order';
import { selectVendorOrder } from 'src/store/slices/vendorsOrders';
import { Events, eventsManager } from 'src/utils/eventManager';
import i18n from 'src/utils/i18n';
import { localize } from 'src/utils/localize';
import * as Yup from 'yup';
import { PickupNameColumn } from '../orders/OrderListView';
import ActivitiesView from '../orders/OrderListView/OrderView/Activities';
import EditOrderStatus from '../orders/OrderListView/OrderView/Info/EditStatus';
import { AddOfferDialog } from '../unhandledOrders/UnhandledOrderListView/AddOfferDialog';
import { StyledTableRow } from './constants';
import { DriverFilter } from 'src/components/Filters/DriverFilter';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useDialogState = () => {
  const [open, setOpen] = useState(false);

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpenDialog = useCallback(() => {
    setOpen(true);
  }, []);

  return { open, handleCloseDialog, handleOpenDialog };
};

export default function VendorOrderDetails() {
  const dispatch = useDispatch();
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [changeDriverOpen, setChangeDriverOpen] = useState(false);
  const { open, handleOpenDialog, handleCloseDialog } = useDialogState();
  const [currentTab, setCurrentTab] = useState('info');

  const { orderChat } = useSelector((state) => state.order);
  const { selectedOrder } = useSelector((state) => state.vendorsOrders);

  useEffect(() => {
    if (!selectedOrder) {
      return;
    }
    dispatch(getOrderChat(selectedOrder._id));
  }, [selectedOrder]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangeStatus = useCallback(() => {
    setChangeStatusOpen(true);
    handleOpenDialog();
  }, []);

  const handleCloseChangeStatus = useCallback(() => {
    setChangeStatusOpen(false);
    handleCloseDialog();
  }, []);

  const handleChangeDriver = useCallback(() => {
    setChangeDriverOpen(true);
  }, []);

  const handleCloseChangeDriver = useCallback(() => {
    setChangeDriverOpen(false);
  }, []);

  const handleDriverChanged = useCallback(() => {
    eventsManager.emit(Events.Orders.OrderChanged, selectedOrder);
  }, []);

  const TABS = [
    {
      title: 'Info',
      value: 'info',
      component: <InfoView order={selectedOrder} />
    },
    {
      title: 'History',
      value: 'history',
      component: <ActivitiesView order={selectedOrder} />
    }
  ];

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        px="20px"
        height={64}
        color="white"
        bgcolor="#01645C"
        fontWeight="bold"
      >
        Order: #{selectedOrder?.orderNo}
      </Box>

      <Box display="flex" padding="30px">
        <Button onClick={handleChangeStatus}>Change Status</Button>
        <Button onClick={handleChangeDriver}>Change Driver</Button>
      </Box>

      {/* Tabs */}
      <Box px="30px">
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
          // className={classes.tabBar}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.title}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Box>

      {TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return (
          isMatched && (
            <Box
              key={tab.value}
              padding="30px"
              sx={{ position: 'relative', zIndex: 1101 }}
            >
              {tab.component}
            </Box>
          )
        );
      })}

      {selectedOrder?.driver == null ? (
        <AddOfferDialog
          open={changeDriverOpen}
          order={selectedOrder}
          toggle={handleCloseChangeDriver}
          getOrders={handleDriverChanged}
          setOrder={() => {}}
        />
      ) : (
        <ChangeDriverDialog
          order={selectedOrder}
          open={changeDriverOpen}
          close={handleCloseChangeDriver}
          getOrders={handleDriverChanged}
        />
      )}
      <Dialog
        style={{ zIndex: 99999 }}
        open={open}
        TransitionComponent={Transition}
        PaperProps={{ style: { overflow: 'inherit' } }}
        keepMounted
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box minWidth={400} p={6} overflow="inherit">
          {changeStatusOpen ? (
            <ChangeStatusDialog
              order={selectedOrder}
              setChangeStatusOpen={handleCloseChangeStatus}
            />
          ) : null}
        </Box>
      </Dialog>
    </Box>
  );
}

function ChangeStatusDialog({ order, setChangeStatusOpen }) {
  return (
    <EditOrderStatus order={order} orderFormVisiblity={setChangeStatusOpen} />
  );
}

function ChangeDriverDialog({ order, open, close, getOrders }) {
  const dispatch = useDispatch();
  const searchText = useRef('');
  const {
    isLoading,
    onlineDrivers,
    driversList,
    isAutoCompleteLoading
  } = useSelector((state) => state.driver);

  useEffect(() => {
    // dispatch(getOnlineDrivers(order?.pickUpZone));
    // dispatch(getdriversList(0, 10, { areas: [], active: true }));
  }, [dispatch, order?.pickUpZone, order]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      driver: null
    },

    validationSchema: Yup.object().shape({
      driver: Yup.object().required('Driver is required')
    }),
    onSubmit: async (values) => {
      const payload = {
        driverId: values.driver?._id
      };
      dispatch(
        updateOrderDriver(order._id, payload, {
          onSuccess: () => {
            close();
            dispatch(selectVendorOrder(null));
            getOrders();
          },
          onFail: (error) => {}
        })
      );
    }
  });

  const fetch = useCallback(
    (input) => {
      if (!input) {
        return;
      }

      dispatch(
        getdriversList(0, 10, { searchText: input, areas: [], active: true })
      );
    },
    [dispatch]
  );

  const debouncedFetch = useMemo(() => {
    return debounce(fetch, 500);
  }, [fetch]);

  const drivers = searchText.current
    ? driversList
    : Object.values(onlineDrivers).flat();

  const { errors, touched, handleSubmit, setFieldValue } = formik;

  return (
    <Dialog
      fullWidth="lg"
      open={open}
      onClose={close}
      PaperProps={{ style: { overflow: 'inherit' } }}
      style={{ zIndex: 99999 }}
    >
      <DialogTitle>Update Driver</DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container flexDirection={'column'} spacing={2}>
              <Grid item>
                <DriverFilter
                  singleValue
                  active={true}
                  ordersType="vendor"
                  // areas={[]}
                  onChange={(driver) => setFieldValue('driver', driver)}
                  defaultValue={order.driver}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button type="submit" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function InfoView({ order }) {
  return (
    <Card>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="left">Shop</StyledTableCell>
              <StyledTableCell align="left">
                <PickupNameColumn order={order} minimalView />
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left">Shop contact phone</StyledTableCell>
              <StyledTableCell align="left">
                <Grid container>
                  {order?.orderDetails?.branch?.phoneNumbers?.map(
                    ({ name, phone }) => {
                      return (
                        <Grid item md={12}>
                          {name} | <a href={`tel:${phone}`}>{phone}</a>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left">Branch name</StyledTableCell>
              <StyledTableCell align="left">
                {order?.orderDetails?.branch?.name?.en}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left">Driver</StyledTableCell>
              <StyledTableCell align="left">
                <div>
                  <a
                    href={`${PATH_APP.drivers.root}/${order?.driver?._id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {order?.driver?.user?.name}
                  </a>
                </div>
                <div>
                  <a
                    href={`${PATH_APP.drivers.root}/${order?.driver?._id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {order?.driver?.phone}
                  </a>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

function DriverOption({ params, options }) {
  let orders = get(options, 'orders', 0);

  if (typeof orders !== 'number') {
    orders = get(options, 'orders.active', 0);
  }

  return (
    <Box
      component="li"
      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
      {...params}
    >
      <img loading="lazy" width="20" src={options.user?.avatar} alt="" />
      <Grid container flexDirection={'row'} justifyContent={'space-between'}>
        <Grid item flexDirection={'row'}>
          <p>{options.user?.firstName}</p>
          <p>{options.phone}</p>
        </Grid>
        <Grid item>({orders} Order)</Grid>
      </Grid>
    </Box>
  );
}
