class Debug {
  warn(...args) {
    this._log('warn', ...args);
  }

  error(...args) {
    this._log('warn', ...args);
  }

  log(...args) {
    this._log('warn', ...args);
  }

  _log(level, ...args) {
    if (process.env.NODE_ENV === 'production') {
      return;
    }

    console[level]?.(...args);
  }
}

export const debug = new Debug();
