import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { store } from '../store';
import { paginationUtils } from './utils/pagination';

const initialState = {
  isLoading: false,
  error: false,
  reasons: [],
  count: 0,
  reasonSuccessMessage: '',
  ...paginationUtils.initialState
};

const slice = createSlice({
  name: 'orderRejectionReasons',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getReasonsSuccess(state, action) {
      state.reasons = action.payload.reasons;
      state.count = action.payload.count;
      state.isLoading = false;
    },

    reasonSuccess(state, action) {
      state.reasonSuccessMessage = action.payload.message;
      state.count = action.payload.count;
    },

    startEditStatusLoading(state, action) {
      state.reasons = state.reasons.map((t) => {
        if (t._id === action.payload?.id) {
          t.status = action.payload?.body?.status;
        }
        return t;
      });
    },

    startDeleteLoading(state, action) {
      state.reasons = state.reasons.filter((t) => t._id !== action.payload);
      state.count -= 1;
    },

    ...paginationUtils.reducers
  }
});

export default slice.reducer;

export function getReasonsList() {
  return async (dispatch) => {
    try {
      const state = store.getState().orderRejectionReasons;
      const { page, perPage } = state;
      const { actions } = slice;

      dispatch(actions.startLoading());
      const response = await apiInstance.get(
        `/orderRejectionReasons/${page}/${perPage}`
      );
      dispatch(slice.actions.getReasonsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addReason(body) {
  return async (dispatch) => {
    try {
      const { page, perPage, reasons } = store.getState().orderRejectionReasons;
      const response = await apiInstance.post('/orderRejectionReasons', body, {
        toastMessage: 'Reason is added successfully'
      });
      dispatch(slice.actions.reasonSuccess(response.data.data));

      if (reasons.length !== 0 && reasons.length === perPage) {
        dispatch(setCancelReasonsPage(page + 1));
        return;
      }

      dispatch(getReasonsList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editReasonStatus(id, body) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startEditStatusLoading({ id, body }));
      const response = await apiInstance.put(
        `/orderRejectionReasons/${id}`,
        body
      );
      dispatch(slice.actions.reasonSuccess(response.data.data));
      dispatch(getReasonsList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteReason(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/orderRejectionReasons/${id}`);
      dispatch(slice.actions.reasonSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editReason(id, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(
        `/orderRejectionReasons/${id}`,
        body,
        {
          toastMessage: 'Reason is updated successfully'
        }
      );
      dispatch(slice.actions.reasonSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCancelReasonsPage(page) {
  return async (dispatch) => {
    dispatch(slice.actions.setPage(page));
    dispatch(getReasonsList());
  };
}

export function setCancelReasonsPerPage(perPage) {
  return async (dispatch) => {
    dispatch(slice.actions.setPerPage(perPage));
  };
}
