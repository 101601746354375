import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { store } from '../store';
import { paginationUtils } from './utils/pagination';

const initialState = {
  isLoading: false,
  types: [],
  ...paginationUtils.initialState
};

const slice = createSlice({
  name: 'driverTypes',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },

    getTypesSuccess(state, action) {
      state.isLoading = false;
      state.types = action.payload.types;
      state.count = action.payload.count;
    },

    hasError(state, action) {
      state.isLoading = false;
    },

    ...paginationUtils.reducers
  }
});

export default slice.reducer;

export function getDriverTypesList(page, perPage) {
  return (dispatch) => {
    const state = store.getState().ads;
    const { actions } = slice;

    if (page == null) {
      page = state.page;
    }

    if (perPage == null) {
      perPage = state.perPage;
    }

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .get(`/driversTypes/${page}/${perPage}`)
      .then((response) => dispatch(actions.getTypesSuccess(response.data.data)))
      .catch((error) => dispatch(actions.hasError(error)));
  };
}

export function createDriverType(payload, events) {
  return (dispatch) => {
    const { actions } = slice;

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .post(`/driversTypes`, payload, {
        toastMessage: 'Driver type created successfuly'
      })
      .then(() => dispatch(getDriverTypesList()))
      .then(() => events?.onSuccess?.())
      .catch((error) => dispatch(actions.hasError(error)));
  };
}

export function updateDriverType(id, update, events) {
  return (dispatch) => {
    const { actions } = slice;

    dispatch(slice.actions.setLoading(true));
    return apiInstance
      .post(`/driversTypes/${id}`, update, {
        toastMessage: 'Driver type updated successfuly'
      })
      .then(() => dispatch(getDriverTypesList()))
      .then(() => events?.onSuccess?.())
      .catch((error) => dispatch(actions.hasError(error)));
  };
}

export function deleteDriverType(id) {
  return (dispatch) => {
    const { actions } = slice;
    dispatch(actions.setLoading(true));
    return apiInstance
      .delete(`/driversTypes/${id}`, {
        toastMessage: 'Driver type deleted successfuly'
      })
      .then(() => dispatch(getDriverTypesList()))
      .catch((error) => dispatch(actions.hasError(error)));
  };
}

export function setDriverTypePage(page) {
  return (dispatch) => {
    dispatch(slice.actions.setPage(page));
    dispatch(getDriverTypesList());
  };
}

export function setDriverTypePerPage(perPage) {
  return (dispatch) => {
    dispatch(slice.actions.setPerPage(perPage));
    dispatch(getDriverTypesList());
  };
}
