import { useCallback, useReducer } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';

export function TimeSelect({ initial, onChange }) {
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      hour: _.padStart(initial.getHours(), 2, 0),
      minute: _.padStart(initial.getMinutes(), 2, 0)
    }
  );

  const setHour = useCallback((_, hour) => {
    setState({ hour });
    onChange({ ...state, hour });
  }, []);

  const setMinute = useCallback((_, minute) => {
    setState({ minute });
    onChange({ ...state, minute });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      gap="15px"
      p="15px"
    >
      <Autocomplete
        sx={{ maxWidth: 'fit-content' }}
        options={_.times(25, (n) => _.padStart(n, 2, 0))}
        getOptionLabel={(o) => o}
        renderInput={(params) => <TextField {...params} />}
        disableClearable
        defaultValue={state.hour}
        onChange={setHour}
      />
      <Typography component="span" variant="h3">
        :
      </Typography>
      <Autocomplete
        sx={{ maxWidth: 'fit-content' }}
        options={_.times(60, (n) => _.padStart(n, 2, 0))}
        getOptionLabel={(o) => o}
        renderInput={(params) => <TextField {...params} />}
        disableClearable
        defaultValue={state.minute}
        onChange={setMinute}
      />
    </Box>
  );
}
