import { Autocomplete, TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderStatusList } from 'src/store/slices/orderStatus';

export function OrderStatusFilter({
  multiple,
  scheduledOrders,
  vendorOrders,
  onChange,
  ...rest
}) {
  const dispatch = useDispatch();
  const { statusList, isOrderStatusListLoading } = useSelector(
    (state) => state.orderStatus
  );

  useEffect(() => {
    dispatch(getOrderStatusList({ vendorOrders, scheduledOrders }));
  }, [vendorOrders, scheduledOrders, dispatch]);

  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    if (params.status) {
      const status = statusList.find(
        (s) => s.key.toLowerCase() === params.status.toLowerCase()
      );

      if (status) {
        onChange(multiple ? [status] : status);
      }
    }
  }, [statusList]);

  return (
    <Autocomplete
      multiple={multiple}
      disablePortal
      filterSelectedOptions
      name="status"
      onChange={(_, v) => onChange(v)}
      renderInput={(params) => <TextField {...params} label="Status" />}
      getOptionLabel={(option) => option.name?.en}
      options={statusList}
      loading={isOrderStatusListLoading}
      {...rest}
    />
  );
}
