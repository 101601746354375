import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';
import { store } from '../store';

const initialState = {
  isPromoLoading: false,
  isPromoDetailsLoading: false,
  writeLoading: false,
  error: false,
  promoCodesList: [],
  promoCodeDetails: null,
  count: 0,
  promocodeSuccessMessage: '',
  showPromoCodeForm: false,
  isEditingForm: false,
  pagination: {
    page: 0,
    perPage: 10
  }
};

const slice = createSlice({
  name: 'promoCode',
  initialState,
  reducers: {
    startLoading(state, action) {
      switch (action.payload) {
        case 'details':
          state.isPromoDetailsLoading = true;
          break;
        default:
          state.isPromoLoading = true;
          break;
      }
    },

    setWriteLoading(state, action) {
      state.writeLoading = action.payload;
    },

    hasError(state, action) {
      state.isPromoLoading = false;
      state.isPromoDetailsLoading = false;
      state.error = action.payload;
    },
    getPromoCodesSuccess(state, action) {
      state.promoCodesList = action.payload.promoCodes;
      state.count = action.payload.count;
      state.isPromoLoading = false;
    },
    getPromoCodeDetailsSuccess(state, action) {
      state.promoCodeDetails = action.payload;
      state.isPromoDetailsLoading = false;
    },
    resetPromoCodeDetails(state, action) {
      state.promoCodeDetails = null;
    },

    promoCodeSuccess(state, action) {
      state.promocodeSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
    },

    startEditStatusLoading(state, action) {
      state.promoCodesList = state.promoCodesList.map((t) => {
        if (t._id == action.payload?.id) {
          t.active = action.payload?.body?.active;
        }
        return t;
      });
    },

    setFormVisibility(state, action) {
      state.showPromoCodeForm = action.payload;
    },

    setFormEditMode(state, action) {
      state.isEditingForm = action.payload;
    },

    startDeleteLoading(state, action) {
      state.promoCodesList = state.promoCodesList.filter(
        (t) => t._id !== action.payload
      );
      state.count -= 1;
    },

    setPagination(state, action) {
      state.pagination = action.payload;
    }
  }
});

export default slice.reducer;
export const { resetPromoCodeDetails } = slice.actions;

export function getPromoCodesList(pageNumber, pageSize, filter) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await apiInstance.get(
        `/promoCodes/list/${pageNumber}/${pageSize}`,
        { params: filter }
      );
      dispatch(slice.actions.getPromoCodesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromoCodeDetails(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading('details'));
      const response = await apiInstance.get(`/promoCodes/${id}/fetch`);
      dispatch(
        slice.actions.getPromoCodeDetailsSuccess(response.data.data.promoCode)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPromoCode(body, options) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.setWriteLoading(true));
      const response = await apiInstance.post('/promoCodes/add', body, {
        toastMessage: 'Promo Code is added successfully'
      });
      dispatch(slice.actions.promoCodeSuccess(response.data.data));
      if (options?.onSuccess) {
        options.onSuccess();
      }
      dispatch(slice.actions.setFormVisibility(false));
      dispatch(slice.actions.setFormEditMode(false));
      dispatch(slice.actions.setPagination({ page: 0, perPage: 10 }));
      dispatch(getPromoCodesList(0, 10));
    } catch (error) {
      if (options?.onFail) {
        options.onFail(error.response?.data?.message || error.message);
      }
    } finally {
      dispatch(slice.actions.setWriteLoading(false));
    }
  };
}

export function editPromoCode(id, body, options) {
  return async (dispatch) => {
    try {
      const { page, perPage } = store.getState().promoCode.pagination;
      dispatch(slice.actions.setWriteLoading(true));
      const response = await apiInstance.put(`/promoCodes/${id}/update`, body, {
        toastMessage: 'Promo Code is updated successfully'
      });
      dispatch(slice.actions.promoCodeSuccess(response.data.data));
      if (options?.onSuccess) {
        options.onSuccess();
      }
      dispatch(slice.actions.setFormVisibility(false));
      dispatch(slice.actions.setFormEditMode(false));
      dispatch(getPromoCodesList(page, perPage));
    } catch (error) {
      if (options?.onFail) {
        options.onFail(error.response?.data?.message || error.message);
      }
    } finally {
      dispatch(slice.actions.setWriteLoading(false));
    }
  };
}

export function deletePromoCode(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDeleteLoading(id));
      const response = await apiInstance.delete(`/promoCodes/${id}`, {
        toastMessage: 'Promo Code is deleted successfully'
      });
      dispatch(slice.actions.promoCodeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setPromoCodePagination(page, perPage) {
  return (dispatch) => {
    dispatch(slice.actions.setPagination({ page, perPage }));
  };
}

export function setFormVisibility(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.setFormVisibility(payload));
  };
}

export function setFormEditMode(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.setFormEditMode(payload));
  };
}

export function setWriteLoading(enabled) {
  return async (dispatch) => {
    dispatch(slice.actions.setWriteLoading(enabled));
  };
}
