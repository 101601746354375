import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'src/utils/api';

const initialState = {
  deeplinks: [],
  count: 0,
  deeplinksSuccessMessage: '',
  error: false,
  isLoading: false
};

const slice = createSlice({
  name: 'deeplinks',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDeeplinksSuccess(state, action) {
      state.isLoading = false;
      state.deeplinks = action.payload.deepLinkUrls;
      state.count = action.payload.count;
    },

    startDeleteLoading(state, action) {
      state.deeplinks = state.deeplinks.filter((n) => n._id !== action.payload);
      state.count -= 1;
    },

    deeplinksSuccess(state, action) {
      state.deeplinksSuccessMessage = action.payload.message;
      if (action.payload.count) {
        state.count = action.payload.count;
      }
    }
  }
});

export default slice.reducer;

export function getDeeplinks(page, pageSize, filters) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(
        `/deeplink-urls/${page}/${pageSize}`,
        { params: filters }
      );
      console.log('[[[Deeplinks APIs]]] - Fetch list:', response.data.data);
      dispatch(slice.actions.getDeeplinksSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDeeplinkById(id) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.get(`/deeplink-urls/${id}`);
      console.log(
        '[[[Deeplinks APIs]]] - Fetch deeplink by Id:',
        response.data.data
      );
      dispatch(slice.actions.deeplinksSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function deleteDeeplinkById(id) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startDeleteLoading(id));
//     try {
//       const response = await apiInstance.delete(`/notifications/${id}`);
//       dispatch(slice.actions.deeplinksSuccess(response.data.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function addNewDeeplink(body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.post(`/deeplink-urls`, body, {
        toastMessage: 'Deeplink is added successfully'
      });
      console.log(
        '[[[Deeplinks APIs]]] - Add new deeplink:',
        response.data.data
      );
      dispatch(slice.actions.deeplinksSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editDeeplink(id, body) {
  return async (dispatch) => {
    try {
      const response = await apiInstance.put(`/deeplink-urls/${id}`, body, {
        toastMessage: 'Deeplink is updated successfully'
      });
      console.log(
        '[[[Deeplinks APIs]]] - Edit new deeplink:',
        response.data.data
      );
      dispatch(slice.actions.deeplinksSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
