import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Drawer
} from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDriversGroupByZone } from 'src/store/slices/drivers';
import { Utils } from 'src/utils/utils';
import { useZoneDriversStyle } from './style';
import { Close } from '@material-ui/icons';

function DriversOnlineCouriers({ open, onClose }) {
  const dispatch = useDispatch();
  const { isLoading, driversGroupedByZone } = useSelector(
    (state) => state.driver
  );

  useEffect(() => {
    if (open === true) {
      dispatch(getDriversGroupByZone());
    }
  }, [dispatch, open]);

  return (
    <Drawer anchor="top" open={open} onClose={onClose}>
      <Box
        position="absolute"
        left="50%"
        top="50%"
        width="100%"
        height="100%"
        zIndex={10}
        borderRadius="10px"
        overflow="hidden"
        style={{
          transform: 'translate(-50%, -50%)',
          pointerEvents: 'none'
        }}
      >
        <Backdrop
          sx={{
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(0,0,0,0.51)'
          }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
      </Box>

      <Box display="flex" justifyContent="space-between" pt={3} px={3}>
        <Box>Drivers online couriers</Box>
        <Button variant="outlined" color="error" onClick={onClose}>
          <Close />
        </Button>
      </Box>

      <Box position="relative" display="flex" flexWrap="wrap" gap={2} p={3}>
        {driversGroupedByZone.map(({ zone, granteeOrders, drivers }) => (
          <ZoneDrivers
            zone={zone}
            granteeOrders={granteeOrders}
            drivers={drivers}
          />
        ))}
      </Box>
    </Drawer>
  );
}

function ZoneDrivers({ zone, drivers, granteeOrders }) {
  const classes = useZoneDriversStyle();
  return (
    <Box className={classes.root}>
      <Box className={classes.heading}>
        <Box flex={1} textAlign="end">
          {zone.name.en}
        </Box>
        <Box flex={1}>
          <div className={classes.chip}>{granteeOrders}</div>
        </Box>
      </Box>
      {drivers.map((driver, index) => {
        const { data, activeOrdersCount, finishedOrdersCount } = driver;

        const ordersStyle = Utils.match(activeOrdersCount > 0, {
          true: {
            backgroundColor: '#00645C',
            color: '#FEF445'
          },
          false: {}
        });

        const chipColor = Utils.match(finishedOrdersCount <= granteeOrders, {
          true: '#8FD14F',
          false: '#F24727'
        });

        return (
          <Box
            className={classes.driverRoot}
            backgroundColor={index % 2 === 0 ? '#F6F7F8' : 'white'}
          >
            <span>{data.name}</span>
            <Box className={classes.chipsRoot}>
              {data.locationWarnings > 0 ? (
                <Box className={classes.chip} style={{ width: '85px' }}>
                  GPS/w {data.locationWarnings}
                </Box>
              ) : null}
              <Box
                className={classes.chip}
                style={{ width: '85px', backgroundColor: chipColor }}
              >
                {finishedOrdersCount}/{granteeOrders}
              </Box>
              <Box
                className={classes.chip}
                style={{ width: '85px', ...ordersStyle }}
              >
                {activeOrdersCount} orders
              </Box>
            </Box>
          </Box>
        );
      })}
      {drivers.length === 0 && (
        <Box className={classes.noDrivers}>No Drivers</Box>
      )}
    </Box>
  );
}

export default DriversOnlineCouriers;
