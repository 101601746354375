import axios from 'axios';
import { toast } from 'react-toastify';
import localStorage from 'redux-persist/es/storage';
import { baseURL } from 'src/config';
import { PATH_APP } from 'src/routes/paths';
import history from 'src/utils/history';

const apiInstance = axios.create({
  baseURL: baseURL.apiEndpoint,
  headers: {
    accept: 'application/json',
    'Content-Type': `application/json`
  }
});

const v2ApiInstance = axios.create({
  baseURL: baseURL.v2ApiEndpoint,
  headers: {
    accept: 'application/json',
    'Content-Type': `application/json`
  }
});

export const unProtectedApiInstance = axios.create({
  baseURL: baseURL.apiEndpoint,
  headers: {
    accept: 'application/json',
    'Content-Type': `application/json`
  }
});

unProtectedApiInstance.interceptors.response.use(
  (res) => {
    onResponse(res);
    return res;
  },
  (error) => {
    onError(error);
    throw error;
  }
);

apiInstance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('accessToken');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    onError(error);
    return Promise.reject(error);
  }
);
apiInstance.interceptors.response.use(
  (res) => {
    onResponse(res);
    return res;
  },
  (error) => {
    if (error.response?.status === 403) {
      history.replace({ pathname: PATH_APP.general.root });
      const message = error.response?.data?.message || 'Unauthorized';
      toast(message, { type: 'error' });
    } else {
      onError(error);
    }
    return Promise.reject(error);
  }
);

v2ApiInstance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('accessToken');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    onError(error);
    return Promise.reject(error);
  }
);
v2ApiInstance.interceptors.response.use(
  (res) => {
    onResponse(res);
    return res;
  },
  (error) => {
    onError(error);
    return Promise.reject(error);
  }
);

function onResponse(response) {
  const { status } = response;
  const { toastMessage } = response.config;

  if (toastMessage && status >= 200 && status < 300) {
    toast(toastMessage, { type: 'success' });
  }
}

function onError(error) {
  const message =
    error.response?.data?.message?.message ||
    error.response?.data?.message ||
    error.message ||
    'Something went wrong';
  toast(message, { type: 'error' });
}

export default apiInstance;
export { v2ApiInstance };
