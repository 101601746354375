import { Autocomplete, TextField } from '@material-ui/core';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getReasonsList } from 'src/store/slices/orderCancellationReasons';
import { localize } from 'src/utils/localize';

export function CancelReasonFilter({
  onChange,
  statusKey,
  error,
  helperText,
  ...rest
}) {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { reasonsList } = useSelector(
    (state) => state.orderCancellationReasons
  );

  useEffect(() => {
    dispatch(getReasonsList(0, 100));
  }, []);

  const reasonListGroups = useMemo(() => {
    return _.groupBy(reasonsList, 'type');
  }, [reasonsList]);

  return (
    <Autocomplete
      disablePortal
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      options={
        reasonListGroups[
          statusKey === 'CANCELLED_BY_OPERATOR' ? 'Admin' : 'Customer'
        ]
      }
      getOptionLabel={(option) =>
        localize(option?.reason?.en, option?.reason?.ar, i18n.language) ||
        option
      }
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
          label={localize('Cancel Reason', 'سبب الإلغاء', i18n.language)}
          placeholder={localize('Cancel Reason', 'سبب الإلغاء', i18n.language)}
          error={error}
          helperText={helperText}
        />
      )}
      {...rest}
    />
  );
}
