function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  app: '/app',
  auth: '/auth'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    forgetPassword: path(ROOTS.auth, '/password/forget'),
    resetPassword: path(ROOTS.auth, '/passwod/reset/:token')
  }
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    home: path('/')
  },
  admins: {
    root: path(ROOTS.app, '/admins'),
    roles: path(ROOTS.app, '/admins/roles'),
    currentRole: path(ROOTS.app, '/admins/role/:id'),
    addRole: path(ROOTS.app, '/admins/role'),
    vendorRoles: path(ROOTS.app, '/admins/vendor-roles'),
    vendorCurrentRole: path(ROOTS.app, '/admins/vendor-role/:id'),
    vendorAddRole: path(ROOTS.app, '/admins/vendor-role')
  },
  profile: {
    root: path(ROOTS.app, '/profile'),
    account: path(ROOTS.app, '/profile/account'),
    userAccount: path(ROOTS.app, '/profile/account/:id')
  },
  reservations: {
    root: path(ROOTS.app, '/reservations'),
    hotels: path(ROOTS.app, '/reservations/hotels'),
    flights: path(ROOTS.app, '/reservations/flights')
  },
  settings: {
    root: path(ROOTS.app, '/settings'),
    offices: path(ROOTS.app, '/settings/offices'),
    departments: path(ROOTS.app, '/settings/departments'),
    reservedCountries: path(ROOTS.app, '/settings/reservedCountries')
  },
  reports: {
    orders: path(ROOTS.app, '/reports/orders'),
    offers: path(ROOTS.app, '/reports/offers')
  },
  orders: {
    root: path(ROOTS.app, '/orders'),
    dailyorders: path(ROOTS.app, '/dailyOrders'),
    orderView: path(ROOTS.app, '/orders/:id'),
    test: path(ROOTS.app, '/orders/map/test')
  },
  vendorsOrders: {
    root: path(ROOTS.app, '/vendors/orders')
  },
  ramadanOrders: {
    root: path(ROOTS.app, '/ramadan/orders')
  },
  unhandledOrders: {
    root: path(ROOTS.app, '/unhandled-orders')
  },
  premiumBrands: {
    root: path(ROOTS.app, '/premium-brands')
  },
  vehicleTypes: {
    root: path(ROOTS.app, '/vehicleTypes'),
    addVehicle: path(ROOTS.app, '/addVehicle')
  },
  problemTypes: {
    root: path(ROOTS.app, '/problemTypes')
  },
  problems: {
    root: path(ROOTS.app, '/problems'),
    info: path(ROOTS.app, '/problems/:id')
  },
  notifications: {
    root: path(ROOTS.app, '/notifications')
  },
  deeplinks: {
    root: path(ROOTS.app, '/deeplinks')
  },
  contactUs: {
    root: path(ROOTS.app, '/contactUs'),
    info: path(ROOTS.app, '/contactUs/:id')
  },
  dirverRequests: {
    root: path(ROOTS.app, '/dirverRequests')
  },
  driverSalary: {
    root: path(ROOTS.app, '/driver-salary')
  },
  orderCancellationReasons: {
    root: path(ROOTS.app, '/orderCancellationReasons')
  },
  orderRejectionReasons: {
    root: path(ROOTS.app, '/orderRejectionReasons')
  },
  categories: {
    root: path(ROOTS.app, '/categories')
  },
  brands: {
    root: path(ROOTS.app, '/brands'),
    brandInfo: path(ROOTS.app, '/brands/info')
  },
  tags: {
    root: path(ROOTS.app, '/tags')
  },
  // itemCategory: {
  //   root: path(ROOTS.app, '/resturants/itemCategories')
  // },
  item: {
    root: path(ROOTS.app, '/resturants/brands/itemInfo'),
    itemInfo: path(ROOTS.app, '/resturants/brands/itemInfo/:id')
  },
  coverageArea: {
    root: path(ROOTS.app, '/coverageAreas'),
    info: path(ROOTS.app, '/coverageAreas/:id')
  },
  promoCode: {
    root: path(ROOTS.app, '/promoCodes'),
    create: path(ROOTS.app, '/promoCodes/create'),
    update: path(ROOTS.app, '/promoCodes/:id')
  },
  offersLimit: {
    root: path(ROOTS.app, '/offersLimits')
  },
  transaction: {
    root: path(ROOTS.app, '/transactions')
  },
  appSettings: {
    root: path(ROOTS.app, '/appSettings')
  },
  drivers: {
    root: path(ROOTS.app, '/drivers'),
    info: path(ROOTS.app, '/drivers/:id')
  },
  customers: {
    root: path(ROOTS.app, '/customers'),
    info: path(ROOTS.app, '/customers/:id')
  },
  adminUsers: {
    root: path(ROOTS.app, '/users/admins'),
    info: path(ROOTS.app, '/users/admins/:id')
  },
  vendors: {
    root: path(ROOTS.app, '/users/vendors'),
    info: path(ROOTS.app, '/users/vendors/:id')
  },
  smsHistory: {
    root: path(ROOTS.app, '/smsHistory')
  },
  onlineDrivers: {
    root: path(ROOTS.app, '/onlineDrivers'),
    map: path(ROOTS.app, '/onlineDrivers/:id/location')
  },
  driversTv: {
    root: path(ROOTS.app, '/drivers-tv')
  },
  driversOnlineCouriers: {
    root: path(ROOTS.app, '/drivers/online-couriers')
  },
  outOfCoverageArea: {
    root: path(ROOTS.app, '/out-of-coverage'),
    byId: path(ROOTS.app, '/out-of-coverage/:id')
  },
  exportJobs: {
    root: path(ROOTS.app, '/export-jobs')
  },
  ads: {
    root: path(ROOTS.app, '/ads')
  },
  driverTypes: {
    root: path(ROOTS.app, '/driver-types')
  }
};
