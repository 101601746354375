import { useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Events, useEvent } from 'src/utils/eventManager';

export const Sounds = {
  Notification: 'notification'
};

export function AudioPlayer() {
  const audioRef = useRef(null);
  const [sound, setSound] = useState(`/sound/notification.mp3`);

  useEvent(Events.Audio.Play, ({ sound = Sounds.Notification }) => {
    setSound(`/sound/${sound}.mp3`);
    queueMicrotask(() => {
      audioRef.current?.audioEl?.current?.load?.();
      audioRef.current?.audioEl?.current?.play?.();
    });
  });

  useEvent(Events.Audio.Pause, () => {
    audioRef.current?.audioEl?.current?.pause?.();
  });

  return (
    <div
      style={{
        position: 'relative',
        background: '#fff',
        zIndex: -10000000,
        width: '100%',
        padding: '10px',
        display: 'none'
      }}
    >
      <ReactAudioPlayer
        preload="auto"
        style={{ position: 'absolute', background: '#fff', display: 'unset' }}
        src={sound}
        ref={audioRef}
        autoPlay={false}
        loop={false}
        controls
      />
    </div>
  );
}
