import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { PATH_APP } from 'src/routes/paths';
import apiInstance from 'src/utils/api';

import { store } from '../store';

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  userProfile: null,
  userList: [],
  usersRoleList: [],
  activityLogs: [],
  usersActivityLogs: [],
  notifications: null,
  userNotifications: null,
  roles: [],
  selectedRole: {},
  isSuccess: false,
  pages: [],
  permissions: [],
  userRoles: null,
  isSendSMSLoading: false,
  sendSMSSuccessMessage: '',
  sendSMSError: false,
  vendorPermissions: {},
  vendorDefaultPermissions: {}
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    getUserProfileSuccess(state, action) {
      state.isLoading = false;
      state.userProfile = action.payload;
    },

    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    getUsersRoleListSuccess(state, action) {
      state.isLoading = false;
      state.usersRoleList = action.payload;
    },

    getUserRolesSuccess(state, action) {
      state.isLoading = false;
      state.userRoles = action.payload;
    },

    getactivityLogsSuccess(state, action) {
      state.isLoading = false;
      state.activityLogs = action.payload;
    },

    getUsersActivityLogsSuccess(state, action) {
      state.isLoading = false;
      state.usersActivityLogs = action.payload;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    getUserNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.userNotifications = action.payload;
    },

    deleteRoleSuccess(state, action) {
      state.isLoading = false;
      state.isSuccess = action.payload;
    },

    deleteUserSuccess(state, action) {
      state.isLoading = false;
      state.isSuccess = action.payload;
    },

    addedRoleSuccess(state, action) {
      state.isLoading = false;
      state.selectedRole = {};
    },

    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },

    getVendorPermissionsSuccess(state, action) {
      state.isLoading = false;
      state.vendorPermissions = action.payload.permissions;
      state.vendorDefaultPermissions = action.payload.defaultPermissions;
    },

    getCurrentRoleSuccess(state, action) {
      state.isLoading = false;
      state.selectedRole = action.payload;
    },

    getPermissionsSuccess(state, action) {
      state.isLoading = false;
      state.pages = action.payload.pages;
      state.permissions = action.payload.permissions;
    },
    startSMSLoading(state) {
      state.isSendSMSLoading = true;
    },

    SendSMSHasError(state, action) {
      state.isSendSMSLoading = false;
      state.sendSMSError = action.payload;
    },

    sendSMSSuccess(state, action) {
      state.isSendSMSLoading = false;
      state.sendSMSSuccessMessage = action.payload;
    }
  }
});

export default slice.reducer;

export const { onToggleFollow, resetAddedRole } = slice.actions;

const decodeToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  return decoded.id;
};

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const token = await localStorage.getItem('accessToken');
      const id = decodeToken(token);
      const response = await apiInstance.post('/profile/me', {
        id
      });
      dispatch(slice.actions.getProfileSuccess(response.data.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserDetails(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/user/${id}`);
      dispatch(slice.actions.getUserProfileSuccess(response.data.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getactivityLogs(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/activity/logs/${id}`);
      const populatedLogs = response.data.data.logs.filter(Boolean);
      dispatch(slice.actions.getactivityLogsSuccess(populatedLogs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersActivityLogs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get('/activity/logs');
      console.log('from logs', response);
      dispatch(
        slice.actions.getUsersActivityLogsSuccess(response.data.data.logs)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserList() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get('/users');
      const token = await localStorage.getItem('accessToken');
      const id = decodeToken(token);
      const usersList = response.data.data.users.filter((u) => u._id !== id);
      dispatch(slice.actions.getUserListSuccess(usersList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserProfileRoles(id, values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.patch(`/user/${id}`, values, {
        toastMessage: 'Profile is updated successfully'
      });
      if (response.data.status) {
        dispatch(slice.actions.getUserProfileSuccess(response.data.data.user));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function deleteUser(id) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.delete(`/user/${id}`, {
        toastMessage: 'User deleted successfully'
      });
      if (response.data.status) {
        const filteredList = getState().user.userList.filter(
          (u) => u._id !== id
        );
        dispatch(slice.actions.getUserListSuccess(filteredList));
        dispatch(slice.actions.deleteUserSuccess(response.data.status));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createNewUser(values) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.post(`/user`, values, {
        toastMessage: 'User created successfully'
      });
      const usersList = [...getState().user.userList, response.data.data.user];
      dispatch(slice.actions.getUserListSuccess(usersList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserProfile(id, values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`/user/${id}`, values, {
        toastMessage: 'Profile is updated successfully'
      });
      dispatch(slice.actions.getUserProfileSuccess(response.data.data.user));
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateUser(id, values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`/user/${id}`, values, {
        toastMessage: 'User updated successfully'
      });
      const users = await apiInstance.get('/users');
      const token = await localStorage.getItem('accessToken');
      const id = decodeToken(token);
      const usersList = users.data.data.users.filter((u) => u._id !== id);
      dispatch(slice.actions.getUserListSuccess(usersList));
    } catch (error) {
      console.error(error);
    }
  };
}

export function deleteMultipleUsers(ids) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.delete(`/users`, {
        ids,
        toastMessage: 'Users deleted successfuly'
      });
      const filteredList = getState().user.userList.filter(
        (u) => !ids.includes(u._id)
      );
      dispatch(slice.actions.getUserListSuccess(filteredList));
      dispatch(slice.actions.deleteUserSuccess(response.data.status));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersRoleList(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/users/${id}/role`);
      dispatch(slice.actions.getUsersRoleListSuccess(response.data.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserNotifications(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/notifications/${id}`);
      dispatch(
        slice.actions.getUserNotificationsSuccess(
          response.data.data.notifications
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAllAsReadForUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.patch(
        `/notifications/${id}/markAllAsRead`
      );
      dispatch(
        slice.actions.getUserNotificationsSuccess(
          response.data.data.notifications
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotifications(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/notifications/${id}`);
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAllAsRead(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.patch(
        `/notifications/${id}/markAllAsRead`
      );
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVendorPermissions(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get('/vendor-permissions', params);
      dispatch(slice.actions.getVendorPermissionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoles(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get('/roles', { params });
      dispatch(slice.actions.getRolesSuccess(response.data.data.roles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoleUsers(roleId, params = {}) {
  params.page = params.page ?? 1;
  params.page = params.perPage ?? 10;

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/role/${roleId}/users`, {
        params
      });
      dispatch(slice.actions.getRolesSuccess(response.data.data.roles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoleDetails(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/role/${id}`);
      dispatch(slice.actions.getCurrentRoleSuccess(response.data.data.role));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteRole(roleId, options) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.delete(`/role/${roleId}`, {
        toastMessage: 'Role deleted successfuly'
      });
      const roles = getState().user.roles.filter((r) => r._id !== roleId);
      dispatch(slice.actions.deleteRoleSuccess(response.data.status));
      dispatch(slice.actions.getRolesSuccess(roles));
      if (options?.onSuccess) {
        options?.onSuccess();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (options?.onFail) {
        options?.onFail();
      }
    }
  };
}

export function addRole(roleData, options) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.post(`/role`, roleData, {
        toastMessage: 'Role added successfuly'
      });
      dispatch(slice.actions.addedRoleSuccess(response.data.data.role));
      if (options?.onSuccess) {
        options?.onSuccess();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateRole(id, roleData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await apiInstance.put(`/role/${id}`, roleData, {
        toastMessage: 'Role updated successfuly'
      });
      dispatch(getRoleDetails(id));

      // Reload window if we're updating current user role
      const currentRoleId = store.getState().auth.user.roleId;
      if (currentRoleId === id) {
        window.location.reload();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deactivateRole(id) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.patch(`/role/${id}/activate`, null, {
        toastMessage: 'Role updated successfuly'
      });
      const effectiveRoles = getState().user.roles.map((r) => {
        if (r._id === id) {
          return {
            ...r,
            status: !r.status
          };
        }
        return r;
      });

      dispatch(slice.actions.deleteRoleSuccess(response.data.status));
      dispatch(slice.actions.getRolesSuccess(effectiveRoles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unAssignRole(id) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.patch(`/user/${id}/unassign`, null, {
        toastMessage: 'Role updated successfuly'
      });
      const currentUsersRole = getState().user.usersRoleList.filter(
        (r) => r._id !== id
      );

      dispatch(slice.actions.deleteRoleSuccess(response.data.status));
      dispatch(slice.actions.getUsersRoleListSuccess(currentUsersRole));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPermissions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get('/pages-permissions');
      dispatch(slice.actions.getPermissionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendSMS(userId, body) {
  console.log('🚀 ~ file: user.js:538 ~ sendSMS ~ userId:', userId);
  const smsBody = {
    // id: userId,
    ...body
  };
  return async (dispatch) => {
    dispatch(slice.actions.startSMSLoading());
    try {
      const response = await apiInstance.post(`/users/sms/${userId}`, smsBody, {
        toastMessage: 'SMS is sent successfully'
      });
      dispatch(slice.actions.sendSMSSuccess(response.data.data.message));
    } catch (error) {
      dispatch(slice.actions.SendSMSHasError(error));
    }
  };
}
